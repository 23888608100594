import React from 'react';
import PropTypes from 'prop-types';
import {generalUiTexts} from 'data/ui-texts';
import {getText} from 'helpers/language-helper';
import './resources.scss';

const Resources = ({languageId, gameType, group, animatedCardEffects}) => {
	return (
		<div className={'Resources ' + gameType}>
			<div className="Resources-title"><span>{getText(generalUiTexts.resources, languageId)}</span></div>
			<div className="Resources-content">
				<div className="Resources-resources">
					{group.resources && group.resources.map((resource, index) => {
						let resourceClass = 'Resources-resource' + 
							(resource.isPlaced ? ' placed' : '') + 
							(!resource.isAvailable ? ' locked' : '');
						if (index === 0 && animatedCardEffects.indexOf('disable-resources') >= 0) {
							resourceClass += ' animate';
						}
						return <div key={index} className={resourceClass}/>;
					})}
				</div>
			</div>
		</div>
				
	);
};

Resources.propTypes = {
	languageId: PropTypes.string.isRequired,
	gameType: PropTypes.string.isRequired,
	group: PropTypes.object.isRequired,
	animatedCardEffects: PropTypes.array
};

export default Resources;