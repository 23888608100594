import React from 'react';
import PropTypes from 'prop-types';
import {adminUiTexts} from 'data/ui-texts';
import {renderMarkdown} from 'helpers/text-helper';
import './delete-facilitator-popup.scss';

const DeleteFacilitatorPopup = (props) => {
	let {
		isDeletingFacilitator, 
		authSessionData,
		errMsgFacilitator, 
		selectedFacilitator, 
		setShowDeleteFacilitatorPopup, 
		deleteFacilitator
	} = props;

	let canDeleteFacilitator = (authSessionData.userId !== selectedFacilitator.id);
	let deleteBtnClass = 'DeleteFacilitatorPopup-deleteBtn' + (isDeletingFacilitator ? ' deleting' : '');

	let popupText = JSON.parse(JSON.stringify(adminUiTexts.confirmDeleteFacilitatorPopup.text));
	popupText = popupText.replace(/%name%/g, selectedFacilitator.name);
	if (!canDeleteFacilitator) {
		popupText = JSON.parse(JSON.stringify(adminUiTexts.confirmDeleteFacilitatorPopup.text2));
	}
	

	return (
		<div className="DeleteFacilitatorPopup">
			<div className="DeleteFacilitatorPopup-content">
				<div className="DeleteFacilitatorPopup-header">
					<div className="DeleteFacilitatorPopup-title">
						{adminUiTexts.confirmDeleteFacilitatorPopup.title}
					</div>
					<div 
						className="DeleteFacilitatorPopup-closeBtn" 
						onClick={() => {setShowDeleteFacilitatorPopup(false);}} 
					/>
				</div>
				<div className="DeleteFacilitatorPopup-body">
					{renderMarkdown(popupText)}
					<div className="DeleteFacilitatorPopup-errMsg">{errMsgFacilitator}</div>

					{/* Delete button */}
					{canDeleteFacilitator && <div 
						className={deleteBtnClass} 
						onClick={() => {deleteFacilitator(selectedFacilitator.id);}}
					>{adminUiTexts.delete}</div>}
					{/* Cancel button */}
					{canDeleteFacilitator && <div 
						className="DeleteFacilitatorPopup-cancelBtn"
						onClick={() => {setShowDeleteFacilitatorPopup(false);}}
					>{adminUiTexts.cancel}</div>}	
					{/* Ok button */}
					{!canDeleteFacilitator && <div 
						className="DeleteFacilitatorPopup-okBtn"
						onClick={() => {setShowDeleteFacilitatorPopup(false);}}
					>{adminUiTexts.ok}</div>}	
				</div>

	
			</div>
		</div>
	);
};



DeleteFacilitatorPopup.propTypes = {
	isDeletingFacilitator: PropTypes.bool.isRequired,
	authSessionData: PropTypes.object.isRequired,
	errMsgFacilitator: PropTypes.any,
	selectedFacilitator: PropTypes.object.isRequired,
	setShowDeleteFacilitatorPopup: PropTypes.func.isRequired,
	deleteFacilitator: PropTypes.func.isRequired
};

export default DeleteFacilitatorPopup;