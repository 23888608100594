import React from 'react';
import PropTypes from 'prop-types';
import {adminUiTexts} from 'data/ui-texts';
import './players.scss';

const Players = ({players, deletePlayer}) => {
	return (
		<div className="Players">
			<div className="Players-header">
				<div className="Players-section id">{adminUiTexts.id}</div>
				<div className="Players-section name">{adminUiTexts.name}</div>	
				<div className="Players-section delete"></div>				
			</div>
			<div className="Players-body">
				{players.map((player, index) => {
					return (
						<div key={index} className="Players-player">
							<div className="Players-section id">{player.id}</div>
							<div className="Players-section name">{player.name}</div>
							<div className="Players-section delete" onClick={() => {deletePlayer(index);}}>
								<div className="Players-deleteBtn" />
							</div>
						</div>
					);
				})}
			</div>
		</div>
	);
};

Players.propTypes = {
	players: PropTypes.array.isRequired,
	deletePlayer: PropTypes.func.isRequired
};

export default Players;