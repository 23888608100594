import React from 'react';
import PropTypes from 'prop-types';
import {loginUiTexts} from 'data/ui-texts';
import {getText} from 'helpers/language-helper';
import Button from 'components/button/button';
import GroupPlayers from 'components/group-players/group-players';

const LoginGroup = (props) => {
	const {
		isLoading,
		languageId,
		handleInput,
		handleFindGame,
		handleJoinGame,
		code,
		groups,
		companyData,
		feedback
	} = props;

	/* Group login part 2: select group */
	if (groups) {
		return (
			<div className="LoginChooseGroup">
				<div className="LoginChooseGroupWrapper">
					{isLoading ?
						<div className="Login-loadingWrapper" /> :
						groups.map((group)=>{
							return (
								<div 
									key={group.id} 
									className={'Login-groupSelectBtn'}
									onClick={() => {handleJoinGame(group.id);}}
								>
									<div className="Login-groupSelectTitle">{group.title}</div>
									<div className="Login-groupSelectPlayers">
										<GroupPlayers group={group} companyData={companyData} type="login" />
									</div>
								</div>
							);
						})
					}
				</div>
			</div>
		);
	}

	/* Group login part 1: enter game code */

	return (
		<div className="LoginGroup">
			{isLoading ?
				<div className="Login-loadingWrapper"></div> :
				<form className="Login-form" onSubmit={handleFindGame}>
					<input
						name="code"
						placeholder={getText(loginUiTexts.gameCode, languageId)} 
						autoComplete="section-grouplogin code"
						value={code ? code : ''}
						onChange={(event)=>{handleInput(event);}}
					/>
					<div className="Login-errorMessage">{feedback}</div>
					<Button 
						text={getText(loginUiTexts.login, languageId)} 
						classes={['login']} onClick={handleFindGame} 
					/>
				</form>
			}
		</div>
	);

};

LoginGroup.propTypes = {
	isLoading: PropTypes.bool,
	languageId: PropTypes.string.isRequired,
	handleInput: PropTypes.func.isRequired,
	handleFindGame: PropTypes.func.isRequired,
	handleJoinGame: PropTypes.func.isRequired,
	code: PropTypes.any,
	groups: PropTypes.any,
	companyData: PropTypes.object,
	feedback: PropTypes.string
};

export default LoginGroup;