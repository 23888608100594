import { generalUiTexts } from 'data/ui-texts';
import { gamesData } from 'data/games-data';
import {getText} from 'helpers/language-helper';

/**
 * Move crew card to / from event card option
 * @param {number} cardId 
 * @param {string} containerId 
 * @param {string} gameStep 
 * @param {object} group 
 * @param {function} handleFindCard 
 */
function moveStaffCard(cardId, containerId, gameStep, group, handleFindCard) {
	let newGameStepCards = JSON.parse(JSON.stringify(group.gameStepCards));

	/* From 'available' to 'available': do nothing (should not happen?) */
	if (containerId === 'available' && handleFindCard(cardId) === 'available') return;

	if (containerId === 'available') {
		/* Remove crew member from option */
		newGameStepCards[gameStep].cards.forEach((card) => {
			if (card.selectedCrewMemberId === cardId) {
				card.selectedCrewMemberId = null;
				card.selectedOptionId = null;
			}
		});
	} else {
		let [, eventCardId, optionId] = containerId.split('-');
		let fromContainerId = handleFindCard(cardId);
		let fromEventCardId = fromContainerId.split('-')[1];

		if (fromContainerId === 'available' || eventCardId === fromEventCardId) {
		/* Move crew member to card option from 'available' or from different option on same card */
			newGameStepCards[gameStep].cards.forEach((card) => {
				if (card.id === parseInt(eventCardId)) {
					card.selectedCrewMemberId = cardId;
					card.selectedOptionId = optionId;
				}
			});
		} else {
			/* Move crew member to card option from different card */
			newGameStepCards[gameStep].cards.forEach((card) => {
				/* Remove from previous card option */
				if (card.id === parseInt(fromEventCardId)) {
					card.selectedCrewMemberId = null;
					card.selectedOptionId = null;
				}
				/* Move to this card option */
				if (card.id === parseInt(eventCardId)) {
					card.selectedCrewMemberId = cardId;
					card.selectedOptionId = optionId;
				}
			});
		}
	}

	return newGameStepCards;
};

/**
 * Check if selected options are are confirmed
 * @param {object} group 
 */
function checkIfSelectedOptionsConfirmed(group) {
	let selectedOptionsConfirmed = false;
	if (
		group.gameStepCards[group.gameStep] && 
		group.gameStepCards[group.gameStep].selectedOptionsConfirmed === true
	) {
		selectedOptionsConfirmed = true;
	}
	return selectedOptionsConfirmed;
}

/**
 * Check if all cards have a selected option
 * @param {object} group 
 */
function checkIfAllCardsHaveASelectedOption(group) {
	let allCardsHaveASelectedOption = false;
	if (
		group.gameStepCards[group.gameStep] && 
		group.gameStepCards[group.gameStep].cards && 
		group.gameStepCards[group.gameStep].cards.filter((c) => {
			return (c.selectedOptionId && c.selectedCrewMemberId);
		}).length === group.gameStepCards[group.gameStep].cards.length
	) {
		allCardsHaveASelectedOption = true;
	}

	return allCardsHaveASelectedOption;
};

/**
 * Get the confirm button status
 * @param {bool} gameIsPaused
 * @param {bool} animationFinished
 * @param {object} group 
 * @param {object} gamePhase
 * @param {bool} selectedOptionsConfirmed 
 * @param {bool} allCardEffectsApplied 
 * @param {function} handleConfirmResourcePlacements 
 * @param {function} handleApplyEventCardEffects 
 * @param {function} confirmAndContinue 
 * @param {array} gameSteps
 */
function getConfirmButtonData(
	gameIsPaused,
	animationFinished,
	languageId,
	group, 
	gamePhase,
	selectedOptionsConfirmed,
	allCardEffectsApplied,
	handleConfirmSelectedOptions, 
	handleApplyEventCardEffects, 
	confirmAndContinue,
	gameSteps
) {
	/* Default: confirm selected options */
	let confirmBtnData = {
		isDisabled: false,
		classes: ['next'],
		text: getText(generalUiTexts.confirm, languageId),
		action: handleConfirmSelectedOptions,
		params: []
	};

	/* Game is paused */
	if (gameIsPaused) {
		confirmBtnData.isDisabled = true;
		confirmBtnData.classes = ['next', 'paused'];
		confirmBtnData.text = getText(generalUiTexts.paused, languageId);
		return confirmBtnData;
	}

	/* Game is not paused */
	if (group.gameStepCards[group.gameStep]) {
		if (selectedOptionsConfirmed) {
			/* Selected options have been confirmed */
			if (!allCardEffectsApplied) {
				confirmBtnData.text = getText(generalUiTexts.turnCard, languageId);
				confirmBtnData.action = handleApplyEventCardEffects;
				/* Not all card effects have been applied: apply effect of next card */
			} else {
				/* All card effects have been applied: go to next step */
				let indexOfGameStep = gameSteps.findIndex((step) => {return step.id === group.gameStep;});
				if (indexOfGameStep >= 0 && indexOfGameStep + 1 < gameSteps.length) {
					if (gamePhase >= gameSteps[indexOfGameStep + 1].phase) {
						/* Next game step is open */
						confirmBtnData.text = getText(generalUiTexts.next, languageId);
						confirmBtnData.action = confirmAndContinue;
						confirmBtnData.params = [group.gameStep];
					} else {
						/* Next game step is locked */
						confirmBtnData.isDisabled = true;
						confirmBtnData.text = getText(generalUiTexts.waiting, languageId);
						confirmBtnData.classes = ['next', 'waiting'];
					}
				}
			}
		} else {
			/* Selected options have not been confirmed */
			let allCardsHaveASelectedOption = checkIfAllCardsHaveASelectedOption(group);
			if (!allCardsHaveASelectedOption) {
				confirmBtnData.isDisabled = true;
			}
		}
	}

	if (!animationFinished) confirmBtnData.isDisabled = true;

	return confirmBtnData;
}

/**
 * Get the index of the next unresolved cards (effects not applied yet)
 * @param {string} gameStep
 * @param {object} gameStepCards
 */
function getIndexOfNextUnresolvedCard(gameStep, gameStepCards) {
	let cardIndex = -1;
	if (
		gameStepCards && 
		gameStepCards[gameStep] && 
		gameStepCards[gameStep].selectedOptionsConfirmed === true && 
		gameStepCards[gameStep].cards
	) {
		gameStepCards[gameStep].cards.forEach((card, i) => {
			if (cardIndex >= 0) return;
			if (!card.hasOwnProperty('effectsApplied') || card.effectsApplied === false) cardIndex = i;
		});
	}
	return cardIndex;
}

/**
 * Calculate the effects of an event card
 * @param {bool} firstCard
 * @param {number} cardIndex
 * @param {object} group 
 * @param {string} gameScenario 
 */
function calculateCardEffects(firstCard, index, group, gameType, gameScenario) {
	/* Get event cards */
	const crewData = gamesData[gameType].crewData;
	const scenarioData = gamesData[gameType].scenarios.find((sc) => {return sc.id === gameScenario;});
	let dayData = scenarioData.daysData.find((d) => {return d.gameStep === group.gameStep;});
	let eventCards = (dayData && dayData.cards ? dayData.cards : []);

	/* Prepare group updates */
	let groupUpdates = {};

	/* Get group properties */
	const gameStep = group.gameStep;
	let gameStepCards = JSON.parse(JSON.stringify(group.gameStepCards));
	let selectedCrew = JSON.parse(JSON.stringify(group.selectedCrew));
	let timeValue = JSON.parse(JSON.stringify(group.timeValue));
	let riskValue = JSON.parse(JSON.stringify(group.riskValue));
	let costValue = JSON.parse(JSON.stringify(group.costValue));

	/* Find index of next card where effects have not been applied */
	let cardIndex = (index >= 0 
		? index 
		: getIndexOfNextUnresolvedCard(gameStep, gameStepCards)
	);

	/* Check if it is the first card to be resolved */
	let isFirstUnresolvedCard = gameStepCards[gameStep].cards.filter((c) => {
		return (c && c.effectsApplied === true && c.concequenceType !== 'move');
	}).length === 0;

	if (cardIndex >= 0 && (!firstCard || isFirstUnresolvedCard)) {
		/* Get card data and row */
		let cardData = eventCards.find((c) => {
			return c.id === gameStepCards[gameStep].cards[cardIndex].id;
		});

		/* Get selected option and crew member ids */
		let selectedOptionId = null;
		let selectedCrewMemberIndex = null;
		if (
			gameStepCards[gameStep].cards[cardIndex].hasOwnProperty('selectedOptionId') &&
			gameStepCards[gameStep].cards[cardIndex].selectedOptionId &&
			gameStepCards[gameStep].cards[cardIndex].hasOwnProperty('selectedCrewMemberId') &&
			gameStepCards[gameStep].cards[cardIndex].selectedCrewMemberId
		) {
			selectedOptionId = gameStepCards[gameStep].cards[cardIndex].selectedOptionId;
			let selectedCrewMemberId = gameStepCards[gameStep].cards[cardIndex].selectedCrewMemberId;
			selectedCrewMemberIndex = selectedCrew.findIndex((c) => {return c.id === selectedCrewMemberId;});
		}
		/* Get effects */
		let effects = [];
		let effectIndexes = [];
		let effectTypes = [];
	
		if (!selectedCrew[selectedCrewMemberIndex].hasOwnProperty('dirtyDozenIds')) {
			selectedCrew[selectedCrewMemberIndex].dirtyDozenIds = [];
		}
		let optionData = cardData.options.find((o) => {return o.id === selectedOptionId;});
		if (optionData && optionData.consequences && optionData.consequences.effects) {
			optionData.consequences.effects.forEach((effect, index) => {
				const conditionsAreMet = 
					checkIfConditionsAreMet(effect, selectedCrew[selectedCrewMemberIndex], crewData);
				if (conditionsAreMet) {
					effects.push(effect);
					effectIndexes.push(index);
				} 
			});
		}

		/* Apply effects */
		if (effects.length > 0) {
			effects.forEach((effect) => {
				/* Change time value */
				if (effect.type === 'time') {
					timeValue += effect.value;
					// if (effectTypes.indexOf(effect.type) < 0) effectTypes.push(effect.type);
				}

				/* Change risk value */
				if (effect.type === 'risk') {
					riskValue += effect.value; 
					// if (effectTypes.indexOf(effect.type) < 0) effectTypes.push(effect.type);
				}
				
				/* Change cost value */
				if (effect.type === 'cost') {
					costValue += effect.value;
					// if (effectTypes.indexOf(effect.type) < 0) effectTypes.push(effect.type);
				}

				/* Add dirty dozen */
				if (effect.type === 'dirtyDozen') {
					if (effectTypes.indexOf(effect.type) < 0) effectTypes.push(effect.type);

					let numberOfDirtyDozen = selectedCrew[selectedCrewMemberIndex].dirtyDozenIds.filter((ddId) => {
						return ddId === effect.dirtyDozenId;
					}).length;
					if (numberOfDirtyDozen > 1) {
						riskValue += 1; 
						if (effectTypes.indexOf('risk') < 0) effectTypes.push('risk');
						costValue += 1;
						if (effectTypes.indexOf('cost') < 0) effectTypes.push('cost');	
					}
					selectedCrew[selectedCrewMemberIndex].dirtyDozenIds.push(effect.dirtyDozenId);
				}
			});

			/* Make sure time, risk and costvalues are >= 0 */
			timeValue = Math.max(0, timeValue);
			riskValue = Math.max(0, riskValue);
			costValue = Math.max(0, costValue);

			/* Track total changes in time / risk / cost, only add to effect types if positive */
			let timeDiff = timeValue - group.timeValue;
			if (timeDiff > 0 || timeDiff < 0) effectTypes.push('time');
			let riskDiff = riskValue - group.riskValue;
			if (riskDiff > 0 || riskDiff < 0) effectTypes.push('risk');
			let costDiff = costValue - group.costValue;
			if (costDiff > 0 || costDiff < 0) effectTypes.push('cost');

			/* Update properties affected by effects */
			gameStepCards[gameStep].cards[cardIndex].effectTypes = effectTypes;
			gameStepCards[gameStep].cards[cardIndex].effectIndexes = effectIndexes;
			gameStepCards[gameStep].cards[cardIndex].statDiffs = {time: timeDiff, risk: riskDiff, cost: costDiff};
			groupUpdates.timeValue = timeValue;
			groupUpdates.riskValue = riskValue;
			groupUpdates.costValue = costValue;
			groupUpdates.selectedCrew = selectedCrew;
		}

		/* Update game step cards and group data */
		gameStepCards[gameStep].cards[cardIndex].effectsApplied = true;
		groupUpdates.gameStepCards = gameStepCards;
	} else {
		// Card not found / valid
	}

	return groupUpdates;
}

/**
 * Check if effection condition is met
 * @param {object} effect 
 * @param {object} selectedCrewMember 
 * @param {object} crewData
 */
function checkIfConditionsAreMet(effect, selectedCrewMember, crewData) {
	if (!effect.conditionType) return true;

	let conditionsAreMet = false;
	if (effect.conditionType === 'dirtyDozen') {
		conditionsAreMet = (
			selectedCrewMember.hasOwnProperty('dirtyDozenIds') && 
			selectedCrewMember.dirtyDozenIds &&
			selectedCrewMember.dirtyDozenIds.some((ddId) => {
				return (effect.conditionIds.indexOf(ddId) >= 0);
			})
		);
	}

	if (effect.conditionType === 'skill') {
		let crewMemberData = crewData.availableCrew.find((c) => {return c.id === selectedCrewMember.id;});
		conditionsAreMet = (
			crewMemberData.hasOwnProperty('skillIds') && 
			crewMemberData.skillIds &&
			crewMemberData.skillIds.some((sId) => {
				return (effect.conditionIds.indexOf(sId) >= 0);
			})
		);
	}
	return conditionsAreMet;
};

/**
 * Calculate Benefits
 */
const calculateBenefits = (item, group) => {
	const newValues = {
		costValue: group.costValue,
		riskValue: group.riskValue,
		timeValue: group.timeValue
	};
	item.effects.forEach((effect) => {
		switch (effect.type) {
		case 'time':
			newValues.timeValue = newValues.timeValue + effect.value;
			break;
		case 'cost':
			newValues.costValue = newValues.costValue + effect.value;
			break;
		case 'risk':
			newValues.riskValue = newValues.riskValue + effect.value;
			break;
		default:
			return null;
		}
	});
	return newValues;
};

export {
	moveStaffCard,
	checkIfSelectedOptionsConfirmed,
	getConfirmButtonData,
	getIndexOfNextUnresolvedCard,
	calculateCardEffects,
	calculateBenefits
};
