import React from 'react';
import PropTypes from 'prop-types';
import { gamesData } from 'data/games-data';
import EventCardCRMFront from './event-card-crm-front';
import EventCardCRMBack from './event-card-crm-back';
import './event-card-crm.scss';

const EventCardCRM = (props) => {
	let {
		index,
		resourcePlacementsConfirmed,
		isDisabled,
		effectsApplied,
		popupIsOpen,
		languageId,
		gameType,
		gameScenario,
		card,
		selectedCrew,
		selectedHospital,
		handleToggleOption,
		movedFromPrevious,
		animationFinished
	} = props;

	const scenarioData = gamesData[gameType].scenarios.find((sc) => {return sc.id === gameScenario;});
	const eventCards = scenarioData.eventCards;

	/* Card data */
	let cardData = eventCards.find((eCard) => {return eCard.id === card.id;});

	/* Animation / show back */
	let animateCardFlip = effectsApplied;

	return (
		<div className={'EventCardCRM' + (animateCardFlip ? ' animate' : '') + (popupIsOpen ? ' popup' : '')}>
			<div className={'EventCardCRM-container' + (animateCardFlip ? ' animate' : '') }>
				<div className="EventCardCRM-front">
					<EventCardCRMFront
						index={index}
						isDisabled={isDisabled}
						languageId={languageId}
						gameType={gameType}
						card={card}
						cardData={cardData}
						selectedCrew={selectedCrew} 
						selectedHospital={selectedHospital}
						resourcePlacementsConfirmed={resourcePlacementsConfirmed}
						selectedOptionId={card.selectedOptionId}
						handleToggleOption={handleToggleOption}
						movedFromPrevious={movedFromPrevious}
						animationFinished={animationFinished}
					/>
				</div>
				<div className="EventCardCRM-back">
					<EventCardCRMBack 
						isDisabled={isDisabled} 
						languageId={languageId}
						gameType={gameType}
						gameScenario={gameScenario}
						card={card} 
						cardData={cardData} 
						selectedCrew={selectedCrew} 
					/>
				</div>
			</div>
		</div>
	);
};

EventCardCRM.propTypes = {
	index: PropTypes.number.isRequired,
	resourcePlacementsConfirmed: PropTypes.bool.isRequired,
	isDisabled: PropTypes.bool.isRequired,
	effectsApplied: PropTypes.bool.isRequired,
	popupIsOpen: PropTypes.bool.isRequired,
	languageId: PropTypes.string.isRequired,
	gameType: PropTypes.string.isRequired,
	gameScenario: PropTypes.string.isRequired,
	card: PropTypes.object.isRequired,
	selectedCrew: PropTypes.array.isRequired,
	selectedHospital: PropTypes.number,
	handleToggleOption: PropTypes.func.isRequired,
	movedFromPrevious: PropTypes.bool.isRequired,
	animationFinished: PropTypes.bool.isRequired
};

export default EventCardCRM;
