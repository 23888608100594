import appConfig from 'config/app.config';
import {languagesData} from 'data/languages-data';

/**
 * Get browser language
 * @returns {string}
 */
function getBrowserLanguage() {
	let browserLanguage = window.navigator.userLanguage || window.navigator.language;
	if (browserLanguage) browserLanguage = browserLanguage.split('-');

	if (!browserLanguage || !languagesData.some((lng) => {return lng.id === browserLanguage;})) {
		browserLanguage = appConfig.defaultLanguage;
	}

	return browserLanguage;
}

/**
 * Translate text
 * @param {object} textObj 
 * @param {string} languageId 
 * @returns {string}
 */
function getText(textObj, languageId) {
	if (textObj === null) return null;

	if (typeof textObj === 'string') {
		console.error('Translation error, no translation, obj is string: ', textObj);
		return textObj;
	}
	
	if (textObj && textObj.hasOwnProperty(languageId)) {
		return textObj[languageId];
	}

	if (textObj && textObj.hasOwnProperty(appConfig.defaultLanguage)) {
		return textObj[appConfig.defaultLanguage];
	}

	console.error('Translation error: ', textObj, ' ', languageId);
	return '';
}

export {getBrowserLanguage, getText};