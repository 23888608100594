import React from 'react';
import PropTypes from 'prop-types';
import { generalUiTexts } from 'data/ui-texts';
import {renderMarkdown} from 'helpers/text-helper';
import {getText} from 'helpers/language-helper';
import './crew-card-front.scss';

const CrewCardFront = ({languageId, gameType, cardData, cardTitle, layout, onClick}) => {

	function renderInfo() {
		return (
			<div className="CrewCardFront-info">
				<div className="CrewCardFront-name"><span>{getText(cardData.name, languageId)}</span></div>
				<div className="CrewCardFront-title">
					{gameType === 'crm-aeroplanes' && <span>{getText(generalUiTexts.title, languageId)}: </span>}
					{cardTitle ? cardTitle : getText(cardData.title, languageId)}
				</div>
				{(layout !== 'crewFeedback' && layout !== 'crewFeedbackWhite') && 
					<div className="CrewCardFront-age">
						<span>{getText(generalUiTexts.age, languageId)}: </span>{cardData.age}
					</div>
				}
			</div>
		);
	}

	function renderDescription() {
		return (
			<div className="CrewCardFront-description">
				{renderMarkdown(getText(cardData.description, languageId))}
			</div>
		);
	}	


	return (
		<div className={'CrewCardFront ' + layout} onClick={onClick}>
			<div className={'CrewCardFront-top ' + cardData.type + (cardData.gender ? ' ' + cardData.gender : '')}>
				{(layout === 'crewPopup' || layout === 'crewFeedbackSelected') && renderInfo()}
			</div>
			<div className={'CrewCardFront-bottom ' + cardData.type}>
				{(layout === 'crewPopup' || layout === 'crewFeedbackSelected') && renderDescription()}
				{(
					layout === 'crewFeedback' || 
					layout === 'crewFeedbackWhite' ||
					layout === 'crewPopupSmall'
				) && renderInfo()}
			</div>
		</div>
	);
};

CrewCardFront.defaultProps = {
	layout: 'default',
	cardTitle: null,
	onClick: null
};

CrewCardFront.propTypes = {
	languageId: PropTypes.string.isRequired,
	gameType: PropTypes.string,
	layout: PropTypes.string,
	cardTitle: PropTypes.string,
	cardData: PropTypes.object.isRequired,
	onClick: PropTypes.func
};

export default CrewCardFront;