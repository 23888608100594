import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Logo from 'components/logo/logo';
import ProgressBar from 'components/progress-bar/progress-bar';
import Button from 'components/button/button';
import {generalUiTexts} from 'data/ui-texts';
import {gamesData} from 'data/games-data';
import {getText} from 'helpers/language-helper';
import {sortArrayByProperty} from 'helpers/array-helper';
import {getConfirmButtonData} from 'helpers/confirm-button-helper';
import DirtyDozenPopup from 'components/dirty-dozen-popup/dirty-dozen-popup';
import Medal from 'components/ui/medal';
import GroupPlayers from 'components/group-players/group-players';
import GroupsPopup from 'components/groups-popup/groups-popup';
import './game-result.scss';

const GameResult = (props) => {
	let {game, companyData, gameStep, groups, handleGoToPage, confirmAndContinue, toggleInfoPopup} = props;
	
	/* Get game step data */
	const gameSteps = gamesData[game.type].gameSteps;
	let resultStepData = gameSteps.find((step) => {return step.id === gameStep;});

	/* Dirty dozen popup */
	let [showDirtyDozenOfGroupIndex, setShowDirtyDozenOfGroupIndex] = useState(null);
	
	/* Show group popup */
	const [showGroupsPopup, setShowGroupsPopup] = useState(false);

	/* Sorted group results */
	let allGroups = groups.map((group, index) => {
		let dirtyDozenTotal = group.dirtyDozenValues.reduce((a, b) => {return a + b;}, 0);
		return {
			title: group.title, 
			dirtyDozenTotal: dirtyDozenTotal, 
			inconvenienceValue: group.inconvenienceValue,
			riskValue: group.riskValue,
			total: (dirtyDozenTotal + group.inconvenienceValue + 2 * group.riskValue),
			players: group.players
		};
	});
	
	/* Sorted group results */
	let sortedGroups = JSON.parse(JSON.stringify(allGroups));
	sortedGroups = sortArrayByProperty(sortedGroups, 'total', 'ASC');

	/* Auto-continue to next step if facilitator opens next phase */
	useEffect(() => {
		if (game.gamePhase === 8) {
			confirmAndContinue(gameStep);
		}
	}, [confirmAndContinue, game.gamePhase, gameStep]);

	/* Confirm button data */
	let confirmBtnData = getConfirmButtonData(true, game, gameStep, getText(generalUiTexts.next, game.languageId));

	return (
		<div className="GameResult">
			<Logo onClick={() => {handleGoToPage('welcome');}} />
			<ProgressBar 
				gameType={game.type}
				title={getText(resultStepData.title, game.languageId)} 
				subtitle={getText(resultStepData.title, game.languageId)} 
				linkText={(gameStep !== 'crew-feedback-result' 
					? getText(generalUiTexts.flightInfo, game.languageId) 
					: null
				)}
				toggleInfo={toggleInfoPopup}
			/>
			<Button 
				isDisabled={confirmBtnData.isDisabled}
				text={confirmBtnData.text}
				classes={confirmBtnData.classes}
				onClick={() => {confirmAndContinue(gameStep);}}
			/>
			<div className="GameResult-container">
				<div className="GameResult-header">
					<div className="GameResult-headerTitle" onClick={() => {setShowGroupsPopup(true);}}>
						{getText(generalUiTexts.group, game.languageId)}
					</div>
					<div className="GameResult-headerTitle">{getText(generalUiTexts.dirtyDozen2, game.languageId)}</div>
					<div className="GameResult-headerTitle">
						{getText(generalUiTexts.inconvenience, game.languageId)}</div>
					<div className="GameResult-headerTitle">{getText(generalUiTexts.doubleRisk, game.languageId)}</div>
					<div className="GameResult-headerTitle">{getText(generalUiTexts.total, game.languageId)}</div>
				</div>
				<div className="GameResult-groupsWrapper">
					{allGroups.map((group, gIndex) => {
						return (
							<div key={gIndex} className="GameResult-groupRow">
								<div className="GameResult-group">
									<div className="GameResult-groupTitle">{group.title}</div>
									<div className="GameResult-groupPlayers">
										<GroupPlayers group={group} companyData={companyData} type="hover" />
									</div>
								</div>
								<div 
									className="GameResult-statValue dd"
									onClick={() => {setShowDirtyDozenOfGroupIndex(gIndex);}}
								>{group.dirtyDozenTotal}</div>
								<div className="GameResult-statValue">{group.inconvenienceValue}</div>
								<div className="GameResult-statValue"><span>{(2 * group.riskValue)}</span></div>
								<div className="GameResult-statValue">{group.total}</div>
								<Medal 
									game={game}
									gameStep={gameStep}
									winner={group.total === sortedGroups[0]?.total } 
									groups={groups} 
								/>
							</div>
						);
					})}
				</div>
			</div>

			{/* Dirty Dozen popup */}
			{showDirtyDozenOfGroupIndex !== null && 
				<DirtyDozenPopup 
					languageId={game.languageId}
					group={groups[showDirtyDozenOfGroupIndex]} 
					setShowDirtyDozenPopup={setShowDirtyDozenOfGroupIndex} 
				/>
			}
			
			{/* Groups popup */}
			{showGroupsPopup &&
				<GroupsPopup 
					languageId={game.languageId}
					groups={groups}
					companyData={companyData}
					setShowGroupsPopup={setShowGroupsPopup}
				/>
			}
		</div>
	);
};

GameResult.propTypes = {
	game: PropTypes.object.isRequired,
	companyData: PropTypes.object,
	gameStep: PropTypes.string.isRequired,
	groups: PropTypes.array.isRequired,
	handleGoToPage: PropTypes.func.isRequired,
	confirmAndContinue: PropTypes.func.isRequired,
	toggleInfoPopup: PropTypes.func,
};

export default GameResult;