import React from 'react';
import {marked} from 'marked';

/**
 * Parse markdown text
 * @param {string} marked
 * @return {html}
 */
function renderMarkdown(markdown) {
	if (markdown && typeof markdown === 'string' && markdown.length > 0) {
		let lexed = marked.lexer(markdown);
		let parsed = marked.parser(lexed);
		return <div dangerouslySetInnerHTML={{__html: parsed}} />;
	}
	return <div />;
}

/**
 * Copy text to clipboard
 * @param {string} text 
 */
function copyToClipboard(text) {
	let textInput = document.createElement('input');
	textInput.style.position = 'fixed';
	textInput.style.opacity = 0; 
	textInput.value = text;
	document.body.appendChild(textInput);
	textInput.focus();
	textInput.select();
	try {
		let successful = document.execCommand('copy');
		console.log(successful ? 'ok' : 'error');
		document.body.removeChild(textInput);
	} catch (err) {
		document.body.removeChild(textInput);
		console.log('Oops, unable to copy');
	}
	
};

/**
 * Add 's or ' on the name's end
 *  * @param {string} name 
 */
const addSToName = (name) => {
	let NameWithS = '';
	if (name.slice(-1) === 's') {
		NameWithS = `${name}'`;
	} else if (name.slice(-1) !== 's') {
		NameWithS = `${name}'s`;
	}
	return NameWithS;
};


export {renderMarkdown, copyToClipboard, addSToName};