import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { generalUiTexts } from 'data/ui-texts';
import { communicationToolsData } from 'data/tools-data';
import { gamesData } from 'data/games-data';
import {getText} from 'helpers/language-helper';
import {getIndexOfGameStep} from 'helpers/group-game-helper';
import {renderMarkdown} from 'helpers/text-helper';
import Logo from 'components/logo/logo';
import Button from 'components/button/button';
import ProgressBar from 'components/progress-bar/progress-bar';
import GroupPlayers from 'components/group-players/group-players';
import GroupsPopup from 'components/groups-popup/groups-popup';
import './communication-result.scss';

const CommunicationResult = ({
	game,
	companyData,
	gameStep,
	groups,
	handleGoToPage,
	confirmAndContinue,
	goToPrevGameStep,
	toggleInfoPopup
}) => {

	const gameSteps = gamesData[game.type].gameSteps;

	/* Get data for this game's communication tool  */
	let communicationToolData = communicationToolsData.find((tool) => {return tool.id === game.communicationTool;});

	/* Show group popup */
	const [showGroupsPopup, setShowGroupsPopup] = useState(false);


	/* Confirm button status */
	let confirmBtnIsDisabled = true;
	let indexOfGameStep = gameSteps.findIndex((step) => {return step.id === gameStep;});
	if (indexOfGameStep >= 0 && indexOfGameStep + 1 < gameSteps.length) {
		if (game.gamePhase >= gameSteps[indexOfGameStep + 1].phase) confirmBtnIsDisabled = false;
	}

	return (
		<div className="CommunicationResult">
			<Logo
				onClick={() => {handleGoToPage('welcome');}}
			/>
			<ProgressBar
				gameType={game.type}
				gameStepId={gameStep}
				title={getText(communicationToolData.title, game.languageId)}
				subtitle={getText(generalUiTexts.results, game.languageId)}
				linkText={getText(generalUiTexts.flightInfo, game.languageId)}
				toggleInfo={toggleInfoPopup}
				backBtnLink={gameSteps[indexOfGameStep].backBtn ? goToPrevGameStep : null}
			/>
			<Button
				text={game.isPaused ? getText(generalUiTexts.paused, game.languageId) : (confirmBtnIsDisabled
					? getText(generalUiTexts.waiting, game.languageId)
					: getText(generalUiTexts.next, game.languageId)) }
				classes={game.isPaused ? ['paused'] : (confirmBtnIsDisabled ? ['next', 'waiting'] : ['next'])}
				isDisabled={game.isPaused ? true : confirmBtnIsDisabled}
				onClick={() => {confirmAndContinue(gameStep);}}
			/>

			<div className="CommunicationResult-groups"  onClick={() => {setShowGroupsPopup(true);}}>
				<div className="CommunicationResult-header">
					<span>{getText(generalUiTexts.group, game.languageId)}</span>
				</div>
				<div className="CommunicationResult-body">
					{groups.map((group, gIndex) => {
						return (
							<div key={gIndex} className="CommunicationResult-group">
								<div className="CommunicationResult-groupTitle">{group.title}</div>
								<div className="CommunicationResult-groupPlayers">
									<GroupPlayers group={group} companyData={companyData} type="hover" />
								</div>
							</div>
						);
					})}
				</div>
			</div>

			{(communicationToolData && communicationToolData.questions) && 
				<div className="CommunicationResult-groupAnswers">
					<div className="CommunicationResult-header">
						{communicationToolData.questions.map((question, index) => {
							return (
								<div key={index} className="CommunicationResult-headerText">
									<span>{renderMarkdown(getText(question.title, game.languageId))}</span>
								</div>
							);
						})}
					</div>
					<div className="CommunicationResult-body">
						{groups.map((group, gIndex) => {
							return (
								<div key={gIndex} className="CommunicationResult-groupRow">
									{communicationToolData.questions.map((question, qIndex) => {
										let answer = null;
										if (getIndexOfGameStep(group.gameStep, game.type) >= 
											getIndexOfGameStep(gameStep, game.type)
										) {
											answer = (group.communicationAnswers 
												?	group.communicationAnswers.some((answer) => {
													return (answer.id === question.id);
												})
													? group.communicationAnswers.find((answer) => {
														return (answer.id === question.id);
													}).value
													: null
												: null
											);
										}
										return (
											<div key={qIndex} className="CommunicationResult-groupAnswerContainer">
												<div className="CommunicationResult-groupAnswer">
													<span>{answer}</span>
												</div>
											</div>
										);
									})}
								</div>
							);
						})}
					</div>
				</div>}

			{showGroupsPopup &&
				<GroupsPopup 
					languageId={game.languageId}
					groups={groups}
					companyData={companyData}
					setShowGroupsPopup={setShowGroupsPopup}
				/>
			}
		</div>
	);
};

CommunicationResult.propTypes = {
	game: PropTypes.object.isRequired,
	companyData: PropTypes.object,
	gameStep: PropTypes.string.isRequired,
	groups: PropTypes.array.isRequired,
	handleGoToPage: PropTypes.func.isRequired,
	confirmAndContinue: PropTypes.func.isRequired,
	goToPrevGameStep: PropTypes.func.isRequired,
	toggleInfoPopup: PropTypes.func.isRequired,
};

export default CommunicationResult;
