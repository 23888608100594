import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { communicationToolsData } from 'data/tools-data';
import { decisionToolsData } from 'data/tools-data';
import { generalUiTexts} from 'data/ui-texts';
import {getText} from 'helpers/language-helper';
import {renderMarkdown} from 'helpers/text-helper';
import Logo from 'components/logo/logo';
import Button from 'components/button/button';
import ProgressBar from 'components/progress-bar/progress-bar';
import './communication.scss';

const Communication = (props) => {
	let {
		game,
		group,
		handleGoToPage,
		handleInputText,
		confirmAndContinue,
		toggleInfoPopup
	} = props;

	/* Get data for this game's communication tool  */
	let communicationToolData = communicationToolsData.find((tool) => {return tool.id === game.communicationTool;});

	/* Get data for this game's decision tool  */
	let decisionToolData = decisionToolsData.find((tool) => {return tool.id === game.decisionTool;});
	
	/* Confirmation button status */
	const [confirmBtnIsDisabled, setConfirmBtnIsDisabled] = React.useState(true);
	useEffect(() => {
		setConfirmBtnIsDisabled(false);
		if (communicationToolData && communicationToolData.questions && group.hasOwnProperty('communicationAnswers')) {
			communicationToolData.questions.forEach((question) => {
				if (group.communicationAnswers.length === 0) { setConfirmBtnIsDisabled(true); };
				group.communicationAnswers.forEach((answer) => { 
					if (answer.id === question.id && answer.value === '') {
						setConfirmBtnIsDisabled(true);
					};
				});
			});
			if (group.communicationAnswers.length !== communicationToolData.questions.length) {
				setConfirmBtnIsDisabled(true);
			}
		} else if (group.communicationAnswers === []) {
			setConfirmBtnIsDisabled(true);
		}
	}, [communicationToolData, group]);

	/* Load and store answer values locally */
	const [answers, setAnswers] = useState([]);
	useEffect(() => {
		if (communicationToolData && communicationToolData.questions) {
			let initAnswersArray = [];
			communicationToolData.questions.forEach((question) => {
				/* Answer template  */
				let initAnswer = {id: question.id, value: ''};
				/* Group answer */		
				const groupAnswer = group.communicationAnswers.find((a) => {return (a.id === question.id);});
				if (groupAnswer && groupAnswer.value) initAnswer.value = groupAnswer.value;
				initAnswersArray.push(initAnswer);
			});
			setAnswers(initAnswersArray);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [communicationToolData]);


	/* Update locally stored answers when props are updated */
	useEffect(() => {
		setAnswers((answers) => {
			let newAnswers = JSON.parse(JSON.stringify(answers));
			communicationToolData.questions.forEach((question) => {
				const newAnswerIndex = newAnswers.findIndex((a) => {return a.id === question.id;});
				const groupAnswer = group.communicationAnswers.find((a) => {return (a.id === question.id);});		
				if (newAnswerIndex >= 0 && groupAnswer) {
					/* Only overwrite if local version is different */
					if (newAnswers[newAnswerIndex].value !== groupAnswer.value) {
						newAnswers[newAnswerIndex].value = groupAnswer.value;
					}
				}
			});
			return newAnswers;
		});
	}, [communicationToolData.questions, group.communicationAnswers]);

	/* Update locally stored answers & database when user is typing */
	const handleTextChange = (e, id) => {
		setAnswers((answers) => {
			const newAnswers = JSON.parse(JSON.stringify(answers));
			answers.forEach((answer, i) => {
				if (answer.id === id) newAnswers[i].value = e.target.value;
			});
			return newAnswers;
		});
		handleInputText(e);
	};

	return (
		<div className="Communication">
			<Logo onClick={() => {handleGoToPage('welcome');}}
			/>
			<ProgressBar 
				gameType={game.type}
				title={getText(communicationToolData.title, game.languageId)} 
				linkText={getText(generalUiTexts.flightInfo, game.languageId)}
				toggleInfo={toggleInfoPopup}
			/>
			<Button
				text={game.isPaused 
					? getText(generalUiTexts.paused, game.languageId) 
					: getText(generalUiTexts.submit, game.languageId)
				}
				classes={game.isPaused ? ['paused'] : ['next']}
				isDisabled={game.isPaused ? true : confirmBtnIsDisabled}
				onClick={() => {confirmAndContinue('communication');}}
			/>
			<div className="Communication-container">
				<div className="Communication-form">
					<div className={'Communication-header'}>
						{getText(communicationToolData.title, game.languageId)}
					</div>
					<div className={'Communication-questions'}>
						{communicationToolData && communicationToolData.questions && 
						communicationToolData.questions.map((question) => {
							let answer = null;
							let communicationAnswer = (answers 
								? answers.find((answer) => {return answer.id === question.id;})
								: null
							);
							if (communicationAnswer && communicationAnswer.value) answer = communicationAnswer.value;
							return (
								<div key={question.id} className="Communication-question">
									<div className="Communication-questionTitle">
										{renderMarkdown(getText(question.title, game.languageId))}
									</div>
									<div className="Communication-answer">								
										<textarea
											id={question.id}
											className="Communication-textarea"
											name={question.id}
											placeholder={
												getText(generalUiTexts.reflection.textPlaceholder, game.languageId)
											}
											rows="5"
											value={answer ? answer : ''}
											onChange={(event) => {handleTextChange(event, question.id);}}
										></textarea>
									</div>
								</div>
							);
						})}
						{!communicationToolData && <p>Unknown reflection</p>}
					</div>
				</div>
				<div className="Communication-overview">
					<div className="Communication-overview-header">{game.decisionTool}</div>
					<div className="Communication-overview-content">
						{(decisionToolData && decisionToolData.questions) && 
							decisionToolData.questions.map((question, i) => {
								let groupAnswer = group.decisionAnswers.find((answer) => {
									return answer.id === question.id;
								});
								return (
									<div key={i} className="Communication-overview-answer">
										<strong>{getText(question.title, game.languageId)}</strong>
										<p>{(groupAnswer ? groupAnswer.value : '-')}</p>
									</div>
								);								
							})
						}
					</div>
				</div>
			</div>
		</div>
	);
};

Communication.propTypes = {
	game: PropTypes.object.isRequired,
	group: PropTypes.object.isRequired,
	handleGoToPage: PropTypes.func.isRequired,
	handleInputText: PropTypes.func.isRequired,
	confirmAndContinue: PropTypes.func.isRequired,
	toggleInfoPopup: PropTypes.func.isRequired,
};

export default Communication;
