import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {adminUiTexts} from 'data/ui-texts';
import {renderMarkdown} from 'helpers/text-helper';
import './disable-facilitator-popup.scss';

const DisableFacilitatorPopup = (props) => {
	let {
		isTogglingFacilitatorStatus, 
		authSessionData,
		errMsgFacilitator, 
		selectedFacilitator, 
		setShowDisableFacilitatorPopup, 
		toggleFacilitatorStatus
	} = props;

	let canDisableFacilitator = (authSessionData.userId !== selectedFacilitator.id);
	let deleteBtnClass = 'DisableFacilitatorPopup-deleteBtn' + (isTogglingFacilitatorStatus ? ' disabling' : '');
	
	let popupText = JSON.parse(JSON.stringify(adminUiTexts.confirmDisableFacilitatorPopup.text));
	popupText = popupText.replace(/%name%/g, selectedFacilitator.name);
	if (!canDisableFacilitator) {
		popupText = JSON.parse(JSON.stringify(adminUiTexts.confirmDisableFacilitatorPopup.text2));
	}

	useEffect(() => {
		if (!selectedFacilitator.isEnabled === true) setShowDisableFacilitatorPopup(false);
	}, [selectedFacilitator.isEnabled, setShowDisableFacilitatorPopup]);

	return (
		<div className="DisableFacilitatorPopup">
			<div className="DisableFacilitatorPopup-content">
				<div className="DisableFacilitatorPopup-header">
					<div className="DisableFacilitatorPopup-title">
						{adminUiTexts.confirmDisableFacilitatorPopup.title}
					</div>
					<div 
						className="DisableFacilitatorPopup-closeBtn" 
						onClick={() => {setShowDisableFacilitatorPopup(false);}} 
					/>
				</div>
				<div className="DisableFacilitatorPopup-body">
					{renderMarkdown(popupText)}
					<div className="DisableFacilitatorPopup-errMsg">{errMsgFacilitator}</div>

					{/* Toggle status button */}
					{canDisableFacilitator && <div 
						className={deleteBtnClass} 
						onClick={() => {toggleFacilitatorStatus(false);}}
					>{adminUiTexts.disable}</div>}
					{/* Cancel button */}
					{canDisableFacilitator && <div 
						className="DisableFacilitatorPopup-cancelBtn"
						onClick={() => {setShowDisableFacilitatorPopup(false);}}
					>{adminUiTexts.cancel}</div>}
					{/* Ok button */}
					{!canDisableFacilitator && <div 
						className="DisableFacilitatorPopup-okBtn"
						onClick={() => {setShowDisableFacilitatorPopup(false);}}
					>{adminUiTexts.ok}</div>}	
				</div>
			</div>
		</div>
	);
};

DisableFacilitatorPopup.propTypes = {
	isTogglingFacilitatorStatus: PropTypes.bool.isRequired,
	authSessionData: PropTypes.object.isRequired,
	errMsgFacilitator: PropTypes.any,
	selectedFacilitator: PropTypes.object.isRequired,
	setShowDisableFacilitatorPopup: PropTypes.func.isRequired,
	toggleFacilitatorStatus: PropTypes.func.isRequired
};

export default DisableFacilitatorPopup;