import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {useDrag} from 'react-dnd';
import {getEmptyImage} from 'react-dnd-html5-backend';
import {ReactComponent as StaffIcon} from 'assets/images/icon-staff.svg';
import {ReactComponent as ApprenticeIcon} from 'assets/images/icon-staff-apprentice.svg';
import './game-board-st-dnd-item.scss';

const GameBoardSTDnDItem = ({isDraggable, isSelected, isDraglayer, type, cardData}) => {
	const [{ isDragging }, drag, preview] = useDrag({
		type: cardData.cardType,
		item: { id: cardData.id, type: cardData.cardType},
		canDrag() {return (isDraggable);},
		isDragging(monitor) {return (cardData.id === monitor.getItem().id);},
		collect: (monitor) => {return {isDragging: monitor.isDragging()};},
	});

	useEffect(() => {
		preview(getEmptyImage(), { captureDraggingState: true });
	});

	const opacity = (((isDragging && !isDraglayer && !isSelected)) ? 0.4 : 1);
	let className = 'GameBoardSTDnDItem ' + cardData.color +
		(type ? ' GameBoardSTDnDItem--' + type : '') + 
		(isDraggable ? ' GameBoardSTDnDItem--draggable' : '') +
		(isDragging ? ' GameBoardSTDnDItem--dragging' : '');

	// Get correct crew icon (certifying / apprentice staff)
	let CrewIcon = (cardData.type === 'certifying' ? StaffIcon : ApprenticeIcon);
	
	return (
		<div ref={drag} style={{opacity}} className={className}>
			<CrewIcon />
		</div>
	);
};

GameBoardSTDnDItem.defaultProps = {
	isSelected: false,
	isDraglayer: false,
	type: 'big',
};

GameBoardSTDnDItem.propTypes = {
	isDraggable: PropTypes.bool.isRequired,
	isSelected: PropTypes.bool,
	isDraglayer: PropTypes.bool,
	type: PropTypes.string,
	cardData: PropTypes.object.isRequired
};

export default GameBoardSTDnDItem;