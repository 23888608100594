import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import BestPracticeCardBack from 'components/best-practice-card/best-practice-card-back';
import BestPracticeCardFront from 'components/best-practice-card/best-practice-card-front';
import './best-practice-card-flippable.scss';

const BestPracticeCardFlippable = (props) => {

	let { cardStatus, index, bestPracticesCardsConfirmed, languageId, firstFlip, group, bestPracticesData } = props;
	const [showBack, setShowBack] = useState(false);
	const [cardData, setCardData] = useState({});
	const [hasFlipped, setHasFlipped] = useState(false);

	useEffect(() => {
		bestPracticesData.forEach((item) => {
			if (item.id === cardStatus.id) {
				setCardData(item);
			}
		});
	}, [bestPracticesData, cardStatus]);

	useEffect(() => {
		if (group.bestPracticesCards.length) {
			let currentCardFlip = group.bestPracticesCards.filter((card) => { return card.id === cardStatus.id; });
			if (currentCardFlip[0].effectsApplied === true && hasFlipped === false) {
				setShowBack(true);
			}
		}
	}, [cardStatus.id, group.bestPracticesCards, hasFlipped]);

	return (
		<div className="BestPracticeCardFlippable"
			onClick={(e) => {
				e.stopPropagation(); 
				if (cardStatus.effectsApplied === false) { firstFlip(cardData.id); }
				setShowBack((curentFlipStatus) => { return !curentFlipStatus;});
				if (!hasFlipped) {setHasFlipped(true);}
			}}>
			<div className={'BestPracticeCardFlippable-container' + (showBack ? ' back' : '')}>
				<div className="BestPracticeCardFlippable-front">
					<BestPracticeCardFront 
						languageId={languageId}
						cardData={cardData}
						isDisabled={false}
						index={index}
						bestPracticesCardsConfirmed={bestPracticesCardsConfirmed}
						showCheckbox={false}
					/>
				</div>
				<div className="BestPracticeCardFlippable-back">
					<BestPracticeCardBack languageId={languageId} cardData={cardData} />
				</div>
			</div>
		</div>
	);
};

BestPracticeCardFlippable.propTypes = {
	cardStatus: PropTypes.object.isRequired,
	bestPracticesCardsConfirmed: PropTypes.bool.isRequired,
	languageId: PropTypes.string.isRequired,
	index: PropTypes.number.isRequired,
	firstFlip: PropTypes.func.isRequired,
	group: PropTypes.object.isRequired,
	bestPracticesData: PropTypes.array.isRequired,
};

export default BestPracticeCardFlippable;
