import React, {useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Logo from 'components/logo/logo';
import ProgressBar from 'components/progress-bar/progress-bar';
import Button from 'components/button/button';
import {generalUiTexts} from 'data/ui-texts';
import {gamesData} from 'data/games-data';
import {getText} from 'helpers/language-helper';
import {sortArrayByProperty} from 'helpers/array-helper';
import {getConfirmButtonData} from 'helpers/confirm-button-helper';
import Medal from 'components/ui/medal';
import GroupPlayers from 'components/group-players/group-players';
import GroupsPopup from 'components/groups-popup/groups-popup';
import './game-result-heli.scss';

const GameResultHeli = (props) => {
	let {game, companyData, gameStep, groups, handleGoToPage, confirmAndContinue, toggleInfoPopup} = props;
	
	let [activeGameStep, setActiveGameStep] = useState(gameStep);

	/* Get game step data */
	const gameSteps = gamesData[game.type].gameSteps;
	let resultStepData = gameSteps.find((step) => {return step.id === activeGameStep;});


	useEffect(() => {
		if (gameStep !== activeGameStep) {
			let newGameStepData = gameSteps.find((step) => {return step.id === gameStep;});
			/* Check if next step has the game result as its page */
			if (newGameStepData && newGameStepData.page === 'game-result') {
				setActiveGameStep(gameStep);
			}
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [gameStep]);



	
	/* Show group popup */
	const [showGroupsPopup, setShowGroupsPopup] = useState(false);

	/* Sorted group results */
	let allGroups = groups.map((group, index) => {
		return {
			title: group.title, 
			crewSafetyValue: group.crewSafetyValue,
			missionSuccessValue: group.missionSuccessValue,
			complianceValue: group.complianceValue,
			total: (group.crewSafetyValue + group.missionSuccessValue + group.complianceValue),
			players: group.players
		};
	});
	
	/* Sorted group results */
	let sortedGroups = JSON.parse(JSON.stringify(allGroups));
	sortedGroups = sortArrayByProperty(sortedGroups, 'total', 'DESC');

	/* Auto-continue to next step if facilitator opens next phase */
	// useEffect(() => {
	// 	if (game.gamePhase === 8) {
	// 		confirmAndContinue(gameStep);
	// 	}
	// }, [confirmAndContinue, game.gamePhase, gameStep]);

	/* Confirm button data */
	let confirmBtnData = getConfirmButtonData(true, game, gameStep, getText(generalUiTexts.next, game.languageId));

	return (
		<div className="GameResultHeli">
			<Logo onClick={() => {handleGoToPage('welcome');}} />
			<ProgressBar 
				gameType={game.type}
				title={getText(resultStepData.title, game.languageId)} 
				subtitle={getText(resultStepData.subtitle, game.languageId)} 
				linkText={(resultStepData.infoPopup
					? getText(generalUiTexts.flightInfo, game.languageId) 
					: null
				)}
				toggleInfo={resultStepData.infoPopup ? toggleInfoPopup : null}
			/>
			<Button 
				isDisabled={confirmBtnData.isDisabled}
				text={confirmBtnData.text}
				classes={confirmBtnData.classes}
				onClick={() => {confirmAndContinue(gameStep);}}
			/>
			<div className="GameResultHeli-container">
				<div className="GameResultHeli-header">
					<div className="GameResultHeli-headerTitle" onClick={() => {setShowGroupsPopup(true);}}>
						{getText(generalUiTexts.group, game.languageId)}
					</div>
					<div className="GameResultHeli-headerTitle">
						{getText(generalUiTexts.crewSafety, game.languageId)}</div>
					<div className="GameResultHeli-headerTitle">
						{getText(generalUiTexts.missionSuccess, game.languageId)}</div>
					<div className="GameResultHeli-headerTitle">
						{getText(generalUiTexts.compliance, game.languageId)}</div>
					<div className="GameResultHeli-headerTitle">
						{getText(generalUiTexts.total, game.languageId)}</div>
				</div>
				<div className="GameResultHeli-groupsWrapper">
					{allGroups.map((group, gIndex) => {
						return (
							<div key={gIndex} className="GameResultHeli-groupRow">
								<div className="GameResultHeli-group">
									<div className="GameResultHeli-groupTitle">{group.title}</div>
									<div className="GameResultHeli-groupPlayers">
										<GroupPlayers group={group} companyData={companyData} type="hover" />
									</div>
								</div>
								<div className="GameResultHeli-statValue">{group.crewSafetyValue}</div>
								<div className="GameResultHeli-statValue">{group.missionSuccessValue}</div>
								<div className="GameResultHeli-statValue">{group.complianceValue}</div>
								<div className="GameResultHeli-statValue">{group.total}</div>
								<Medal 
									game={game}
									gameStep={gameStep}
									winner={group.total === sortedGroups[0]?.total } 
									groups={groups} 
								/>
							</div>
						);
					})}
				</div>
			</div>
			
			{/* Groups popup */}
			{showGroupsPopup &&
				<GroupsPopup 
					languageId={game.languageId}
					groups={groups}
					companyData={companyData}
					setShowGroupsPopup={setShowGroupsPopup}
				/>
			}
		</div>
	);
};

GameResultHeli.propTypes = {
	game: PropTypes.object.isRequired,
	companyData: PropTypes.object,
	gameStep: PropTypes.string.isRequired,
	groups: PropTypes.array.isRequired,
	handleGoToPage: PropTypes.func.isRequired,
	confirmAndContinue: PropTypes.func.isRequired,
	toggleInfoPopup: PropTypes.func,
};

export default GameResultHeli;