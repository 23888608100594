import React from 'react';
import PropTypes from 'prop-types';
import {adminUiTexts} from 'data/ui-texts';
import {renderMarkdown} from 'helpers/text-helper';
import './delete-company-popup.scss';

const DeleteCompanyPopup = (props) => {
	let {
		isDeletingCompany, 
		errMsgCompany, 
		selectedCompany, 
		setShowDeleteCompanyPopup, 
		deleteCompany
	} = props;

	let deleteBtnClass = 'DeleteCompanyPopup-deleteBtn' + (isDeletingCompany ? ' deleting' : '');

	let popupText = JSON.parse(JSON.stringify(adminUiTexts.confirmDeleteCompanyPopup.text));
	popupText = popupText.replace(/%company%/g, selectedCompany.title);


	return (
		<div className="DeleteCompanyPopup">
			<div className="DeleteCompanyPopup-content">
				<div className="DeleteCompanyPopup-header">
					<div className="DeleteCompanyPopup-title">
						{adminUiTexts.confirmDeleteCompanyPopup.title}
					</div>
					<div 
						className="DeleteCompanyPopup-closeBtn" 
						onClick={() => {setShowDeleteCompanyPopup(false);}} 
					/>
				</div>
				<div className="DeleteCompanyPopup-body">
					{renderMarkdown(popupText)}
					<div className="DeleteCompanyPopup-errMsg">{errMsgCompany}</div>

					{/* Delete button */}
					<div 
						className={deleteBtnClass} 
						onClick={() => {deleteCompany();}}
					>{adminUiTexts.delete}</div>
					{/* Cancel button */}
					<div 
						className="DeleteCompanyPopup-cancelBtn"
						onClick={() => {setShowDeleteCompanyPopup(false);}}
					>{adminUiTexts.cancel}</div>
				</div>

	
			</div>
		</div>
	);
};



DeleteCompanyPopup.propTypes = {
	isDeletingCompany: PropTypes.bool.isRequired,
	errMsgCompany: PropTypes.any,
	selectedCompany: PropTypes.object.isRequired,
	setShowDeleteCompanyPopup: PropTypes.func.isRequired,
	deleteCompany: PropTypes.func.isRequired
};

export default DeleteCompanyPopup;