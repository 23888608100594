import {dirtyDozenData} from 'data/dirty-dozen-data';
import {getText} from 'helpers/language-helper';

function getOverviewColumns() {
	const overviewColumns = [
		{value: 'gameId', label: 'Id'},
		{value: 'playDate', label: 'Date'},
		{value: 'facilitator', label: 'Facilitator'},
		{value: 'type', label: 'Game'},
		{value: 'scenario', label: 'Scenario'},
		{value: 'phase', label: 'Current phase'},
		{value: 'groups', label: 'Groups'},
		{value: 'players', label: 'Players'},
		{value: 'archived', label: 'Is archived'}
	];
	return overviewColumns;
}

function getGroupsColumns() {
	const groupsColumns = [
		{value: 'gameId', label: 'Id'},
		{value: 'group', label: 'Group'},
		{value: 'players', label: 'Players'},
		{value: 'playerIds', label: 'Player Ids'},
	];
	return groupsColumns;
}

function getSelectedCrewColumns() {
	const selectedCrewColumns = [
		{value: 'gameId', label: 'Id'},
		{value: 'group', label: 'Group'},
		{value: 'crew1', label: '1'},
		{value: 'crew2', label: '2'},
		{value: 'crew3', label: '3'},
		{value: 'crew4', label: '4'},
		{value: 'crew5', label: '5'},
		{value: 'crew6', label: '6'},
	];
	return selectedCrewColumns;
}

function getReflectionColumns() {
	const reflectionColumns = [
		{value: 'gameId', label: 'Id'},
		{value: 'group', label: 'Group'},
		{value: 'reflection', label: 'Reflection type'},
		{value: 'questionNumber', label: 'Question #'},
		{value: 'question', label: 'Question text'},
		{value: 'answer', label: 'Answer'},
		{value: 'selected', label: 'Selected options'}
	];
	return reflectionColumns;
}

function getDecisionColumns() {
	const decisionColumns = [
		{value: 'gameId', label: 'Id'},
		{value: 'group', label: 'Group'},
		{value: 'type', label: 'Tool'},
		{value: 'question', label: 'Question'},
		{value: 'answer', label: 'Answer'},
	];
	return decisionColumns;
}

function getDirtyDozensColumns(languageId) {
	let dirtyDozensColumns = [
		{value: 'gameId', label: 'Id'},
		{value: 'group', label: 'Group'},
	];
	dirtyDozenData.forEach((dd) => {
		dirtyDozensColumns.push({value: 'dirtydozen-' + dd.id, label: getText(dd.title, languageId)});
	});
	dirtyDozensColumns.push({value: 'total', label: 'Total'});
	return dirtyDozensColumns;
}

function getEventCardsCRMColumns() {
	const eventCardsCRMColumns = [
		{value: 'gameId', label: 'Id'},
		{value: 'group', label: 'Group'},
		{value: 'card1a', label: '1a'},
		{value: 'card1b', label: '1b'},
		{value: 'card2a', label: '2a'},
		{value: 'card2b', label: '2b'},
		{value: 'card3a', label: '3a'},
		{value: 'card3b', label: '3b'},
		{value: 'card4a', label: '4a'},
		{value: 'card4b', label: '4b'},
		{value: 'card5a', label: '5a'},
		{value: 'card5b', label: '5b'},
		{value: 'card6a', label: '6a'},
		{value: 'card6b', label: '6b'},
		{value: 'card7a', label: '7a'},
		{value: 'card7b', label: '7b'},
		{value: 'card8a', label: '8a'},
		{value: 'card8b', label: '8b'},
		{value: 'card9a', label: '9a'},
		{value: 'card9b', label: '9b'},
		{value: 'card10a', label: '10a'},
		{value: 'card10b', label: '10b'},
		{value: 'card11a', label: '11a'},
		{value: 'card11b', label: '11b'},
		{value: 'card12a', label: '12a'},
		{value: 'card12b', label: '12b'},
	];
	return eventCardsCRMColumns;
}

function getResultsCRMColumns() {
	const resultsCRMColumns = [
		{value: 'gameId', label: 'Id'},
		{value: 'group', label: 'Group'},
		{value: 'dirtydozen', label: 'Dirty Dozens'},
		{value: 'inconvenience', label: 'Inconvenience'},
		{value: 'risk', label: 'Risk (x2)'},
		{value: 'total', label: 'Total'}
	];
	return resultsCRMColumns;
}

function getEventCardsSTColumns() {
	const eventCardsSTColumns = [
		{value: 'gameId', label: 'Id'},
		{value: 'group', label: 'Group'},
		{value: 'card1a', label: '1a'},
		{value: 'card1b', label: '1b'},
		{value: 'card2a', label: '2a'},
		{value: 'card2b', label: '2b'},
		{value: 'card3a', label: '3a'},
		{value: 'card3b', label: '3b'},
		{value: 'card4a', label: '4a'},
		{value: 'card4b', label: '4b'},
		{value: 'card5a', label: '5a'},
		{value: 'card5b', label: '5b'},
		{value: 'card6a', label: '6a'},
		{value: 'card6b', label: '6b'},
		{value: 'card7a', label: '7a'},
		{value: 'card7b', label: '7b'},
		{value: 'card8a', label: '8a'},
		{value: 'card8b', label: '8b'},
		{value: 'card9a', label: '9a'},
		{value: 'card9b', label: '9b'},
		{value: 'card10a', label: '10a'},
		{value: 'card10b', label: '10b'},
		{value: 'card11a', label: '11a'},
		{value: 'card11b', label: '11b'},
		{value: 'card12a', label: '12a'},
		{value: 'card12b', label: '12b'},
		{value: 'card13a', label: '13a'},
		{value: 'card13b', label: '13b'},
		{value: 'card14a', label: '14a'},
		{value: 'card14b', label: '14b'},
		{value: 'card15a', label: '15a'},
		{value: 'card15b', label: '15b'},
		{value: 'card16a', label: '16a'},
		{value: 'card16b', label: '16b'},

	];
	return eventCardsSTColumns;
}

function getResultsSTColumns() {
	const resultsSTColumns = [
		{value: 'gameId', label: 'Id'},
		{value: 'group', label: 'Group'},
		{value: 'time', label: 'Time'},
		{value: 'risk', label: 'Risk'},
		{value: 'cost', label: 'Cost'},
		{value: 'total', label: 'Total'}
	];
	return resultsSTColumns;
}

function getEventCardsCRMHColumns() {
	const eventCardsCRMHColumns = [
		{value: 'gameId', label: 'Id'},
		{value: 'group', label: 'Group'},
		{value: 'card1a', label: '1a'},
		{value: 'card1b', label: '1b'},
		{value: 'card2a', label: '2a'},
		{value: 'card2b', label: '2b'},
		{value: 'card3a', label: '3a'},
		{value: 'card3b', label: '3b'},
		{value: 'card4a', label: '4a'},
		{value: 'card4b', label: '4b'},
		{value: 'card5a', label: '5a'},
		{value: 'card5b', label: '5b'},
		{value: 'card6a', label: '6a'},
		{value: 'card6b', label: '6b'},
		{value: 'card7a', label: '7a'},
		{value: 'card7b', label: '7b'},
		{value: 'card8a', label: '8a'},
		{value: 'card8b', label: '8b'},
		{value: 'card9a', label: '9a'},
		{value: 'card9b', label: '9b'},
		{value: 'card10a', label: '10a'},
		{value: 'card10b', label: '10b'},
		{value: 'card11a', label: '11a'},
		{value: 'card11b', label: '11b'},
		{value: 'card12a', label: '12a'},
		{value: 'card12b', label: '12b'},
		{value: 'card13a', label: '13a'},
		{value: 'card13b', label: '13b'},
		{value: 'card14a', label: '14a'},
		{value: 'card14b', label: '14b'},
		{value: 'card15a', label: '15a'},
		{value: 'card15b', label: '15b'},
		{value: 'card16a', label: '16a'},
		{value: 'card16b', label: '16b'},
		{value: 'card17a', label: '17a'},
		{value: 'card17b', label: '17b'},
		{value: 'card18a', label: '18a'},
		{value: 'card18b', label: '18b'},
		{value: 'card19a', label: '19a'},
		{value: 'card19b', label: '19b'},
		{value: 'card20a', label: '20a'},
		{value: 'card20b', label: '20b'},

	];
	return eventCardsCRMHColumns;
}

function getExerciseCRMHColumns() {
	const exerciseColumns = [
		{value: 'gameId', label: 'Id'},
		{value: 'group', label: 'Group'},
		{value: 'type', label: 'Tool'},
		{value: 'question', label: 'Question'},
		{value: 'answer', label: 'Answer'},
	];
	return exerciseColumns;
};

function getResultsCRMHColumns() {
	const resultsCRMHColumns = [
		{value: 'gameId', label: 'Id'},
		{value: 'group', label: 'Group'},
		{value: 'safety', label: 'Crew Safety'},
		{value: 'success', label: 'Mission Success'},
		{value: 'compliance', label: 'Compliance'},
		{value: 'total', label: 'Total'}
	];
	return resultsCRMHColumns;
};


function getCRMASheets(languageId) {
	let sheets = [
		{id: 'overview', name: 'Overview', columns: getOverviewColumns(), data: []}, 
		{id: 'groups', name: 'Groups', columns: getGroupsColumns(), data: []},
		{id: 'crew', name: 'Selected crew', columns: getSelectedCrewColumns(), data: []},
		{id: 'ground', name: 'Ground event cards', columns: getEventCardsCRMColumns(), data: []},
		{id: 'air', name: 'Air event cards', columns: getEventCardsCRMColumns(), data: []},
		{id: 'reflections', name: 'Reflections', columns: getReflectionColumns(), data: []},
		{id: 'decisions', name: 'Decisions', columns: getDecisionColumns(), data: []},
		{id: 'communication', name: 'Communication', columns: getDecisionColumns(), data: []},
		{id: 'results', name: 'Results', columns: getResultsCRMColumns(), data: []},
		{id: 'dirtydozens', name: 'Dirty Dozens', columns: getDirtyDozensColumns(languageId), data: []}
	];
	return sheets;
}

function getCRMHSheets() {
	let sheets = [
		{id: 'overview', name: 'Overview', columns: getOverviewColumns(), data: []}, 
		{id: 'groups', name: 'Groups', columns: getGroupsColumns(), data: []},
		{id: 'crew', name: 'Selected crew', columns: getSelectedCrewColumns(), data: []},
		{id: 'eventcards', name: 'Event cards', columns: getEventCardsCRMHColumns(), data: []},
		{id: 'reflections', name: 'Reflections', columns: getReflectionColumns(), data: []},
		{id: 'exercise', name: 'Exercise', columns: getExerciseCRMHColumns(), data: []},
		{id: 'results', name: 'Results', columns: getResultsCRMHColumns(), data: []}
	];
	return sheets;
}

function getSTSheets() {
	let sheets = [
		{id: 'overview', name: 'Overview', columns: getOverviewColumns(), data: []}, 
		{id: 'groups', name: 'Groups', columns: getGroupsColumns(), data: []},
		{id: 'crew', name: 'Selected crew', columns: getSelectedCrewColumns(), data: []},
		{id: 'eventcards', name: 'Event cards', columns: getEventCardsSTColumns(), data: []},
		{id: 'reflections', name: 'Reflections', columns: getReflectionColumns(), data: []},
		{id: 'results', name: 'Results', columns: getResultsSTColumns(), data: []},
	];
	return sheets;
}


export {
	getOverviewColumns,
	getGroupsColumns,
	getSelectedCrewColumns,
	getReflectionColumns,
	getDecisionColumns,
	getDirtyDozensColumns,
	getEventCardsCRMColumns,
	getResultsCRMColumns,
	getEventCardsSTColumns,
	getResultsSTColumns,
	getCRMASheets,
	getCRMHSheets,
	getSTSheets
};