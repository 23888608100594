import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { gamesData } from 'data/games-data';
import { calculateBenefits } from 'helpers/group-game-helper-st';
import BestPracticesBenefits from './best-practices-benefits';
import InfoPopup from 'components/info-popup/info-popup';

class BestPracticesBenefitsController extends Component {
	
	constructor(props) {
		super(props);
		this.state = {
			showInfoPopup: false,
			animateTimeStats: false,
			animateRiskStats: false,
			animateCostStats: false,
			statDiffs: null,
			bestPracticesData: gamesData[this.props.game.type].bestPracticesData,
		};
		this.statsAnimationTimeout = null;
	}

	/**
	 * Component did mount
	 */
	componentDidMount = () => {
		this.props.updateGroup({bestPracticesCards: this.props.group.bestPracticesCards.map((card) => {
			return { id: card.id, isFlipped: false, effectsApplied: false};
		})});
	};

	/**
	 * Hide / show info popup
	 */
	toggleInfoPopup = (show = null) => {
		let showInfoPopup = !this.state.showInfoPopup;
		if (show !== null) showInfoPopup = show;
		this.setState({ showInfoPopup});
	};

	/* stats animation counter */
	animationCountdown = () => {
		setTimeout(() => { // return the timeoutID
			this.setState({
				animateTimeStats:false,
				animateRiskStats:false,
				animateCostStats:false,
				statDiffs: null
			});
		}, 5000);
	};

	/**
	 * clear Timeout in unmount
	 */
	componentWillUnmount = () => { 
		if (this.statsAnimationTimeout) {
			clearTimeout(this.statsAnimationTimeout);
			this.statsAnimationTimeout = null;
		}
	}; 

	/**
	 * componentDidUpdate
	 */
	componentDidUpdate(prevProps) {
		this.props.group.bestPracticesCards.forEach((card, i) => {
			if (this.props.group.bestPracticesCards[i].effectsApplied === true) {
				if (prevProps.group.bestPracticesCards[i].effectsApplied !== true) {
					/* A card just had its effects applied, animate effects */

					this.setState({
						animateTimeStats: prevProps.group.timeValue !== this.props.group.timeValue,
						animateRiskStats: prevProps.group.riskValue !== this.props.group.riskValue,
						animateCostStats: prevProps.group.costValue !== this.props.group.costValue,
						statDiffs: {
							time: (this.props.group.timeValue - prevProps.group.timeValue),
							risk: (this.props.group.riskValue - prevProps.group.riskValue),
							cost: (this.props.group.costValue - prevProps.group.costValue)
						}
					});
					this.statsAnimationTimeout = this.animationCountdown();
				} 
			}
		});
	}

	/**
	 * Apply benefits
	 * @param {number} id
	 */
	applyBenefits = (id) => {
		this.state.bestPracticesData.forEach((item, i) => {
			if (item.id === id) {
				const newValues = calculateBenefits(item, this.props.group);
				const newBestPracticesCards = JSON.parse(JSON.stringify(
					this.props.group.bestPracticesCards
				));
				newBestPracticesCards.forEach((card, i) => {
					if (card.id === id) {
						newBestPracticesCards[i].effectsApplied = true;
						newBestPracticesCards[i].isFlipped = true;
					}
				});
				this.props.updateGroup({
					costValue: newValues.costValue >= 0 ? newValues.costValue : 0,
					riskValue: newValues.riskValue >= 0 ? newValues.riskValue : 0,
					timeValue: newValues.timeValue >= 0 ? newValues.timeValue : 0,
					bestPracticesCards: newBestPracticesCards
				});
			}
		});
	};
	
	/**
	 * Flip benefit card
	 * @param {number} id 
	 */
	firstFlip = (id) => {
		const newBestPracticesCards = JSON.parse(JSON.stringify(
			this.props.group.bestPracticesCards
		));
		newBestPracticesCards.forEach((card, i) => {
			if (card.id === id) {
				if (newBestPracticesCards[i].effectsApplied === false) {
					this.applyBenefits(id);
				} 
			}
		});
	}; 

	/**
	 * Render component
	 */
	render() {
		return (
			<>
				<BestPracticesBenefits
					game={this.props.game}
					group={this.props.group}
					handleGoToPage={this.props.handleGoToPage}
					confirmAndContinue={this.props.confirmAndContinue}
					firstFlip={this.firstFlip}
					toggleInfoPopup={this.toggleInfoPopup}
					animateTimeStats={this.state.animateTimeStats}
					animateRiskStats={this.state.animateRiskStats}
					animateCostStats={this.state.animateCostStats}
					statDiffs={this.state.statDiffs}
					bestPracticesData={this.state.bestPracticesData}
				/>
				{this.state.showInfoPopup && <InfoPopup
					canToggle={true}
					game={this.props.game}
					gameStep={this.props.group.gameStep}
					toggleInfoPopup={this.toggleInfoPopup}
					handleLogout={null}
				/>}	
			</>
		);
	}
}

BestPracticesBenefitsController.propTypes = {
	game: PropTypes.object.isRequired,
	group: PropTypes.object.isRequired,
	handleGoToPage: PropTypes.func.isRequired,
	updateGroup: PropTypes.func.isRequired,
	confirmAndContinue: PropTypes.func.isRequired,
};

export default BestPracticesBenefitsController;
