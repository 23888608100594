import React from 'react';
import PropTypes from 'prop-types';
import {gamesData} from 'data/games-data';
import {getText} from 'helpers/language-helper';
import Logo from 'components/logo/logo';
import InfoBox from 'components/info-box/info-box';
import Button from 'components/button/button';
import aeroteam from 'assets/images/aeroteam.png';
import {Link} from 'react-router-dom';
import './gameover.scss';

const Gameover = ({ languageId, gameType, handleGoToPage, handleLogout }) => {
	let gameoverText = getText(gamesData[gameType].goodbyeText, languageId);
	
	return (
		<div className="Gameover">
			<Link to="/" className="Gameover-link">
				<img src={aeroteam} className="aeroteam" alt="aeroteam logo" />
			</Link>
			<Logo onClick={() => {handleGoToPage('welcome');}}/>
			<InfoBox type="gameover" title="" text={gameoverText} />
			<Button text="" classes={['logout']} onClick={handleLogout} />
		</div>
	);
};

Gameover.propTypes = {
	languageId: PropTypes.string.isRequired,
	gameType: PropTypes.string.isRequired,
	handleGoToPage: PropTypes.func.isRequired,
	handleLogout: PropTypes.func.isRequired,
};

export default Gameover;
