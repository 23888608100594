import React from 'react';
import PropTypes from 'prop-types';
import appConfig from 'config/app.config';
import { generalUiTexts } from 'data/ui-texts';
import { gamesData } from 'data/games-data';
import {getText} from 'helpers/language-helper';
import { renderMarkdown } from 'helpers/text-helper';
import {replaceTextPlaceholders} from 'helpers/group-crew-helper';
import { ReactComponent as ArrowDown } from 'assets/images/arrow-down.svg';
import './event-card-crm-front.scss';

const EventCardCRMFront = (props) => {
	let {
		isDisabled,
		resourcePlacementsConfirmed,
		movedFromPrevious,
		animationFinished,
		index,
		languageId,
		gameType,
		selectedOptionId,
		selectedCrew,
		selectedHospital,
		card,
		cardData,
		handleToggleOption	
	} = props;

	/* Label for default option */
	let defaultOptionLabel = getText(generalUiTexts.defaultNextTurn, languageId);
	if (index >= appConfig.eventCardsPerRow || cardData.roundsMax < 2) {
		defaultOptionLabel = getText(generalUiTexts.defaultThisTurn, languageId);
	}

	/* Text replacements */
	let text = JSON.parse(JSON.stringify(getText(cardData.text, languageId)));
	let crewMemberData = null;
	if (cardData.slotId) {
		let crewMemberId = selectedCrew.find((c) => {return c.slotId === cardData.slotId;}).id;
		crewMemberData = gamesData[gameType].crewData.availableCrew.find((c) => {return c.id === crewMemberId;});
		if (crewMemberData) {
			text = replaceTextPlaceholders(
				text, 
				getText(crewMemberData.name, languageId), 
				crewMemberData.gender ? crewMemberData.gender : null
			);
		}
	}

	return (
		<div
			className={
				'EventCardCRMFront ' + gameType + 
				(cardData.type === 'critical' ? ' critical' : '') +
				(cardData.type === 'possible-threat' ? ' possibleThreat' : '') 
			}
		>
			<div className="EventCardCRMFront-header">
				<div className="EventCardCRMFront-title">{getText(cardData.title, languageId)}</div>
			</div>
			<div className="EventCardCRMFront-body">
				<div className="EventCardCRMFront-text">{renderMarkdown(text)}</div>
				<div className="EventCardCRMFront-options">
					{cardData.options &&
						cardData.options.map((option, index) => {
							let optionText = JSON.parse(JSON.stringify(getText(option.text, languageId)));
							if (crewMemberData) {
								optionText = replaceTextPlaceholders(
									optionText, 
									getText(crewMemberData.name, languageId), 
									crewMemberData.gender ? crewMemberData.gender : null
								);
							}
							if (selectedHospital) {
								optionText = optionText.replace(/%hospital%/g, selectedHospital);
							}
							let optionIsSelected = selectedOptionId === option.id;
							let canToggleOption =
								(option.id !== 'default' && !resourcePlacementsConfirmed) || (
									(cardData.isActionCard === true && !card.resourcePlacementsConfirmed) 
								);
							return (
								<div
									key={index}
									className={
										'EventCardCRMFront-option EventCardCRMFront-option--' +
										option.id +
										(option.id === 'default' ? ' default' : '') +
										(canToggleOption ? ' selectable' : '') +
										(optionIsSelected ? ' selected' : '')
									}
									onClick={(e) => {
										if (canToggleOption) {
											e.stopPropagation();
											handleToggleOption(cardData.id, option.id);
										}
									}}
								>
									<div className="EventCardCRMFront-optionCost">
										{(option.id !== 'default' && option.cost === 0) && 
											<div 
												className={'EventCardCRMFront-optionFree' + 
													(optionIsSelected ? ' selected' : '')}
											><span>{getText(generalUiTexts.free, languageId)}</span></div>
										}
										{[...Array(option.cost)].map((_, index) => {
											return (
												<div
													key={index}
													className={
														'EventCardCRMFront-resource' +
														(optionIsSelected ? ' selected' : '')
													}
												/>
											);
										})}
									</div>
									<div className="EventCardCRMFront-optionText">
										<span>
											<strong>
												{option.id === 'default'
													? defaultOptionLabel
													: option.id}
												:{' '}
											</strong>
											{optionText}
										</span>
									</div>
								</div>
							);
						})
					}
					{(
						cardData.noDefaultOption === true && 
						gameType !== 'crm-helicopters' && 
						!resourcePlacementsConfirmed
					) && <div className={'EventCardCRMFront-option default'}>
						<div className="EventCardCRMFront-optionText">
							<span>
								<strong>{defaultOptionLabel}: </strong>
								{getText(generalUiTexts.noDefaultOption, languageId)}.
							</span>
						</div>
					</div>}
				</div>
			</div>
			{((card.effectsApplied && card.concequenceType === 'move') || 
			( movedFromPrevious && !animationFinished ) ) && (
				<div className="EventCardCRMFront-moveOverlay">
					<div className="EventCardCRMFront-moveMessage">
						<p>{getText(generalUiTexts.movesToNextRound, languageId)}</p>
						<ArrowDown />
					</div>
				</div>
			)}
			{(isDisabled && animationFinished) && <div className="EventCard-disabledOverlay" />}
		</div>
	);
};

EventCardCRMFront.propTypes = {
	isDisabled: PropTypes.bool.isRequired,
	resourcePlacementsConfirmed: PropTypes.bool.isRequired,
	movedFromPrevious: PropTypes.bool.isRequired,
	animationFinished: PropTypes.bool.isRequired,
	index: PropTypes.number.isRequired,
	languageId: PropTypes.string.isRequired,
	gameType: PropTypes.string.isRequired,
	selectedOptionId: PropTypes.string,
	selectedCrew: PropTypes.array.isRequired,
	selectedHospital: PropTypes.number,
	card: PropTypes.object.isRequired,
	cardData: PropTypes.object.isRequired,
	handleToggleOption: PropTypes.func.isRequired,
};

export default EventCardCRMFront;
