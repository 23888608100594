import React from 'react';
import PropTypes from 'prop-types';
import {generalUiTexts} from 'data/ui-texts';
import {getText} from 'helpers/language-helper';
import GroupPlayers from 'components/group-players/group-players';
import './groups-popup.scss';

const GroupsPopup = ({languageId, groups, companyData, setShowGroupsPopup}) => {

	return (
		<div className="GroupsPopup" onClick={() => {setShowGroupsPopup(false);}}>
			<div className="GroupsPopup-content" onClick={(e) => {e.stopPropagation();}}>
				<div className="GroupsPopup-closeBtn" onClick={() => {setShowGroupsPopup(false);}} />
				<div className="GroupsPopup-title">{getText(generalUiTexts.groupsPopupTitle, languageId)}</div>
				<div className="GroupsPopup-body">
					{groups.map((group, index) => {
						return (
							<div key={index} className="GroupsPopup-group">
								<div className="GroupsPopup-groupTitle">{group.title}</div>
								<GroupPlayers group={group} companyData={companyData} type="popup" />
							</div>
						);
					})}
				</div>
			</div>
		</div>
	);
};

GroupsPopup.propTypes = {
	languageId: PropTypes.string.isRequired,
	groups: PropTypes.array.isRequired,
	companyData: PropTypes.object,
	setShowGroupsPopup: PropTypes.func.isRequired
};

export default GroupsPopup;
