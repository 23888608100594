import React from 'react';
import PropTypes from 'prop-types';
import './logo.scss';

const Logo = ({classes, onClick}) => {
	let className = 'Logo';
	if (classes && classes.length > 0) {
		classes.forEach((c) => {className = className + ' Logo--' + c;});
	}

	return (
		<div onClick={() => {if (onClick) onClick();}} className={className} />
	);
};

Logo.propTypes = {
	classes: PropTypes.array,
	onClick: PropTypes.func
};

export default Logo;