import React from 'react';
import PropTypes from 'prop-types';
import {generalUiTexts} from 'data/ui-texts';
import {dirtyDozenData} from 'data/dirty-dozen-data';
import {getText} from 'helpers/language-helper';
import DirtyDozen from 'components/dirty-dozen/dirty-dozen';
import './dirty-dozen-popup.scss';

const DirtyDozenPopup = ({languageId, group, setShowDirtyDozenPopup}) => {

	return (
		<div className="DirtyDozenPopup-popup" onClick={() => {setShowDirtyDozenPopup(null);}}>
			<div className="DirtyDozenPopup-content" onClick={(e) => {e.stopPropagation();}}>
				<div className="DirtyDozenPopup-closeBtn" onClick={() => {setShowDirtyDozenPopup(null);}} />
				<div className="DirtyDozenPopup-title">{getText(generalUiTexts.dirtyDozen, languageId)}</div>
				<div className="DirtyDozenPopup-body">
					{dirtyDozenData.map((stat, index) => {
						return (
							<DirtyDozen
								key={index}
								title={getText(stat.title, languageId)}
								value={group.dirtyDozenValues[index]}
								isHighlighted={false}
								isColored={(group.dirtyDozenValues[index] > 0)}
							/>
						);
					})}
				</div>
			</div>
		</div>
	);
};

DirtyDozenPopup.propTypes = {
	languageId: PropTypes.string.isRequired,
	group: PropTypes.object.isRequired,
	setShowDirtyDozenPopup: PropTypes.func.isRequired
};

export default DirtyDozenPopup;