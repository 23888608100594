import React from 'react';
import PropTypes from 'prop-types';
import {adminUiTexts} from 'data/ui-texts';
import {sortArrayByProperty} from 'helpers/array-helper';
import Button from 'components/button/button';
import './admins.scss';

const Admins = (props) => {
	const {
		isResettingFacilitatorPw, 
		companyId, 
		facilitators, 
		games, 
		selectFacilitator, 
		resetFacilitatorPassword
	} = props;

	let isCompanyPage = (companyId !== null);

	let sortedAdmins = sortArrayByProperty(facilitators.filter((f) => {return (f.isAdmin === true);}), 'name', 'ASC');

	return (
		<div className={'Admins ' + (isCompanyPage ? ' company' : '')}>
			<div className="Admins-header">
				<div className="Admins-section name">{adminUiTexts.name}</div>
				<div className="Admins-section email">{adminUiTexts.email}</div>
				<div className="Admins-section login">{adminUiTexts.lastLogin}</div>
				{isCompanyPage && <div className="Admins-section games">{adminUiTexts.games}</div>}
				<div className="Admins-section status">{adminUiTexts.status}</div>
				<div className="Admins-section reset">{adminUiTexts.sendInvite}</div>
				
			</div>
			<div className="Admins-body">
				{sortedAdmins.map((facilitatorData) => {
					let numberOfGames = games.filter((g) => {
						return (g.facilitatorId === facilitatorData.id && g.companyId === companyId);
					}).length;
					let status = adminUiTexts.notInvited;
					if (facilitatorData.invitationSent === true) {
						status = (facilitatorData.isEnabled === true 
							? adminUiTexts.enabled : adminUiTexts.disabled);
					}
					
					return (
						<div 
							key={facilitatorData.id} 
							className="Admins-facilitator" 
							onClick={() => {if (isCompanyPage) selectFacilitator(facilitatorData.id);}}
						>
							<div className="Admins-section name">{facilitatorData.name}</div>
							<div className="Admins-section email">{facilitatorData.email}</div>
							<div className="Admins-section login">
								{facilitatorData.lastLogin ? facilitatorData.lastLogin : '-'}
							</div>
							{isCompanyPage && <div className="Admins-section games">{numberOfGames}</div>}
							<div className={'Admins-section status' + 
									(facilitatorData.isEnabled === true ? ' enabled' : '') +
									(facilitatorData.invitationSent === true ? '' : ' notInvited')
							}
							>{status}</div>
							<div className="Admins-section reset">
								<Button 
									text={adminUiTexts.sendEmail} 
									classes={['createCompany']} 
									isLoading={(isResettingFacilitatorPw === facilitatorData.id ? true : false) }
									onClick={(e) => {
										e.stopPropagation();
										resetFacilitatorPassword(
											facilitatorData.email, 
											facilitatorData.id, 
											facilitatorData.invitationSent
										);
									}} 
								/>
							</div>
						</div>
					);
				})}
			</div>
		</div>
	);
};

Admins.propTypes = {
	isResettingFacilitatorPw: PropTypes.any,
	companyId: PropTypes.string,
	facilitators: PropTypes.array.isRequired,
	games: PropTypes.array.isRequired,
	selectFacilitator: PropTypes.func,
	resetFacilitatorPassword: PropTypes.func.isRequired,
};

export default Admins;