function checkIfCanCreateGame(gameType, companyData) {
	let canCreateGame = false;
	
	if (gameType && companyData) {
		let companyGameAccessData = (companyData.games
			? companyData.games.find((game) => {return game.id === gameType;})
			: null
		);

		if (
			companyGameAccessData && companyGameAccessData.enabled === true &&
			(
				companyGameAccessData.scenarios && 
				companyGameAccessData.scenarios.some((scenario) => {return scenario.enabled === true;})
			)
		) canCreateGame = true;
	}

	return canCreateGame;
}

function checkIfGameIsAvailable(gameData, companyData) {
	let gameIsAvailable = false;
	if (gameData && companyData) {
		let companyGameAccessData = (companyData.games
			? companyData.games.find((game) => {return game.id === gameData.type;})
			: null
		);
		
		if (companyGameAccessData && companyGameAccessData.enabled === true) {
			if (gameData.scenario && companyGameAccessData.scenarios) {
				let gameScenarioAccessData = companyGameAccessData.scenarios.find((s) => {
					return s.id === gameData.scenario;
				});
				if (gameScenarioAccessData && gameScenarioAccessData.enabled === true) {
					gameIsAvailable = true;
				}
			}
		}
	}
	return gameIsAvailable;
};


export {
	checkIfCanCreateGame,
	checkIfGameIsAvailable
};
