import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {generalUiTexts} from 'data/ui-texts';
import { gamesData } from 'data/games-data';
import {dirtyDozenData} from 'data/dirty-dozen-data';
import {getIndexOfGameStep} from 'helpers/group-game-helper';
import {getConfirmButtonData} from 'helpers/confirm-button-helper';
import {getText} from 'helpers/language-helper';
import Logo from 'components/logo/logo';
import Button from 'components/button/button';
import ProgressBar from 'components/progress-bar/progress-bar';
import InfoBox from 'components/info-box/info-box';
import GroupPlayers from 'components/group-players/group-players';
import GroupsPopup from 'components/groups-popup/groups-popup';
import { useWindowSize } from 'helpers/windowchange-helper';
import { isIOS } from 'react-device-detect';
import './reflection-result.scss';

const ReflectionResult = ({
	game,
	companyData,
	gameStep,
	groups,
	handleGoToPage,
	confirmAndContinue,
	goToPrevGameStep,
	toggleInfoPopup
}) => {

	/* Get reflection data */
	const gameStepData = gamesData[game.type].gameSteps.find((g) => {return g.id === gameStep;});
	const reflectionsData = gamesData[game.type].reflectionsData;
	let [reflectionData] = useState(
		reflectionsData.find((reflection) => {
			return reflection.resultGameStep === gameStep;
		})
	);

	/* Show question popup */
	const [showQuestionBox, setshowQuestionBox] = useState(false);
	const [questionBoxData, setQuestionBoxData] = useState({ title: '', text: '' });
	const handleshowQuestionBox = (questionsId, index) => {
		let currentQuestion = reflectionData.questions.find((question) => {
			return question.id === questionsId;
		});
		let qNumber = (currentQuestion.number ? currentQuestion.number : index + 1);
		let questionBoxTitle = `${getText(generalUiTexts.reflection.question, game.languageId)} ${qNumber}`;
		let questionBoxText = getText(currentQuestion.text, game.languageId);
		setQuestionBoxData({ title: questionBoxTitle, text: questionBoxText });
		setshowQuestionBox(true);
	};

	/* Show group popup */
	const [showGroupsPopup, setShowGroupsPopup] = useState(false);
	
	/* Confirmation button data */
	let confirmBtnData = getConfirmButtonData(true, game, gameStep, getText(generalUiTexts.next, game.languageId));
	if (game.type === 'safetytraining') confirmBtnData.classes.push('st');
	
	/* Layout */
	const sizeCheck = useWindowSize();
	const groupsDiv = React.useRef(null);
	const groupsDivBox = groupsDiv?.current?.getBoundingClientRect();
	const [isIOs, setIsIOs] = useState(isIOS);
	const [stepsHeight, setStepsHeight] = useState(0);
	useEffect(() => {
		setIsIOs(isIOS);
		if (groupsDivBox?.height) {
			setStepsHeight(groupsDivBox?.height);
		}
	}, [sizeCheck, groupsDivBox]);

	/* Unknown reflection */
	if (!reflectionData) {
		return (
			<div className="ReflectionResult">
				{game.type !== 'safetytraining' && <Logo onClick={() => {handleGoToPage('welcome');}}/>}
				<ProgressBar 
					gameType={game.type}
					gameStepId={gameStepData.id}
					title={getText(gameStepData.title, game.languageId)} 
					subtitle={getText(gameStepData.subtitle, game.languageId)} 
					linkText={null}
					toggleInfo={null}
					onClick={() => {handleGoToPage('welcome');}}
					backBtnLink={gameStepData.backBtn ? goToPrevGameStep : null}
				/>
				<Button
					isDisabled={confirmBtnData.isDisabled}
					text={confirmBtnData.text}
					classes={confirmBtnData.classes}
					onClick={() => {confirmAndContinue(gameStep);}}
				/>
			</div>
		);
	}
	
	
	/* Known reflection */
	return (
		<div className="ReflectionResult">
			{game.type !== 'safetytraining' && <Logo onClick={() => {handleGoToPage('welcome');}}/>}
			<ProgressBar 
				gameType={game.type}
				gameStepId={gameStepData.id}
				title={getText(reflectionData.title, game.languageId)} 
				subtitle={(reflectionData.resultSubtitle 
					? getText(reflectionData.resultSubtitle, game.languageId) 
					: getText(reflectionData.subtitle, game.languageId)
				)}  
				linkText={(game.type !== 'safetytraining' && gameStepData.infoPopup
					? getText(generalUiTexts.flightInfo, game.languageId) : null)}
				toggleInfo={(game.type !== 'safetytraining' && gameStepData.infoPopup
					? toggleInfoPopup : null)}
				onClick={(game.type === 'safetytraining' ? () => {handleGoToPage('welcome');} : null)}
				backBtnLink={gameStepData.backBtn ? goToPrevGameStep : null}
			/>
			{(game.type === 'safetytraining') &&
				<Button
					text={''}
					classes={['st', 'info']}
					onClick={() => {toggleInfoPopup();}}
				/>}
			<Button
				isDisabled={confirmBtnData.isDisabled}
				text={confirmBtnData.text}
				classes={confirmBtnData.classes}
				onClick={() => {confirmAndContinue(gameStep);}}
			/>

			{showQuestionBox && 
				<InfoBox
					title={questionBoxData.title}
					text={questionBoxData.text}
					handleClose={() => {setshowQuestionBox(false);}}
				/>
			}

			{showGroupsPopup &&
				<GroupsPopup 
					languageId={game.languageId}
					groups={groups}
					companyData={companyData}
					setShowGroupsPopup={setShowGroupsPopup}
				/>
			}
			<div ref={groupsDiv} className="ReflectionResult-groups" onClick={() => {setShowGroupsPopup(true);}}>
				<div className="ReflectionResult-header">
					<span>{getText(generalUiTexts.group, game.languageId)}</span>
				</div>
				<div className="ReflectionResult-body">
					{groups.map((group, gIndex) => {
						return (
							<div key={`g-${gIndex}`} className="ReflectionResult-group">
								<div className="ReflectionResult-groupTitle"><span>{group.title}</span></div>
								<div className="ReflectionResult-groupPlayers">
									<GroupPlayers group={group} companyData={companyData} type="hover" />
								</div>
							</div>
						);
					})}
				</div>
			</div>
			{ (gameStep === 'best-practices-result') ? (
				<React.Fragment>
					<div className="ReflectionResult-groupAnswers" 
						style={{height: (isIOs && (stepsHeight !== 0)) ? `${stepsHeight}px` : 'auto'}}>
						<div className="ReflectionResult-header">
							{[0, 1].map((index) => {
								return (
									<div
										key={`q-${index}`}
										className="ReflectionResult-headerText"
									>
										<span>
											{getText(generalUiTexts.reflection.tool, game.languageId)} {index + 1}
										</span>
									</div>
								);
							})}
						</div>
						<div className="ReflectionResult-body bestPractices">
							{groups.map((group, gIndex) => {
								return (
									<div key={`r-${gIndex}`} className="ReflectionResult-groupRow">
										{ group.bestPracticesCards.length > 0 ?
											group.bestPracticesCards.map((card, qIndex) => {
												let answerValue = null;
												if (getIndexOfGameStep(group.gameStep, game.type) >=
												getIndexOfGameStep(gameStep, game.type)
												) {
													if (group.reflectionAnswers) {
														if (
															group.reflectionAnswers.some((answer) => {
																return (
																	answer.gameStep ===
																	reflectionData.gameStep &&
																answer.id === card.id
																);
															})
														) {
															answerValue = group.reflectionAnswers.find(
																(answer) => {
																	return (
																		answer.gameStep ===
																		reflectionData.gameStep &&
																	answer.id === card.id
																	);
																}
															).value;
														}
													}
												}
												let question = 
													getText(gamesData[game.type].bestPracticesData.find((item) => { 
														return item.id === card.id; 
													}).title, game.languageId);
												let answer = answerValue;
												return (
													<div 
														key={`a-${qIndex}`} 
														className="ReflectionResult-groupAnswerContainer"
													>
														<div className="ReflectionResult-groupQuestion">
															{question}
														</div>
														<div className="ReflectionResult-groupAnswer">
															<span>{answer}</span>
														</div>
													</div>
												);
											}) :
											[0, 1].map((qIndex) => {
												return (
													<div 
														key={`a-${qIndex}`} 
														className="ReflectionResult-groupAnswerContainer"
													>
														<div className="ReflectionResult-groupQuestion">
														</div>
														<div className="ReflectionResult-groupAnswer">
															<span></span>
														</div>
													</div>
												);
											})
										}
									</div>
								);
							})}
						</div>
					</div>
				</React.Fragment>
			) : ( 
				<React.Fragment>
					<div className="ReflectionResult-groupAnswers" 
						style={{height: (isIOs && (stepsHeight !== 0)) ? `${stepsHeight}px` : 'auto'}}>
						<div className="ReflectionResult-header">
							{(reflectionData && reflectionData.showDirtyDozens) && 
								<div className="ReflectionResult-headerText">
									<span>{getText(generalUiTexts.dirtyDozen, game.languageId)}</span>
								</div>
							}
							{(reflectionData && reflectionData.topics) &&
								<div className="ReflectionResult-headerText">
									<span>{getText(generalUiTexts.reflection.topics, game.languageId)}</span>
								</div>
							}
							{reflectionData &&
								reflectionData.questions &&
								reflectionData.questions.map((question, index) => {
									if (question.answerType === 'intro') return null;
									let className =	question.answerType;
									let qNumber = (question.number ? question.number : index + 1);
									return (
										<div
											key={`q-${index}`}
											className={`ReflectionResult-headerText ${className}`}
											onClick={() => {
												handleshowQuestionBox(question.id, index);
											}}
										>
											<span>
												{getText(generalUiTexts.reflection.question, game.languageId)} {qNumber}
											</span>
										</div>
									);
								})}
						</div>
						<div className="ReflectionResult-body">
							{groups.map((group, gIndex) => {
								let dirtyDozenIds = null;
								if (
									reflectionData && 
									reflectionData.showDirtyDozens && 
									reflectionData.numberOfDirtyDozensToSelect
								) {
									dirtyDozenIds = (group.dirtyDozenIds ? group.dirtyDozenIds : []);
								}
								let focusPointIds = null;
								if (
									reflectionData && 
									reflectionData.showFocusPoints && 
									reflectionData.numberOfFocusPointsToSelect
								) {
									focusPointIds = (group.focusPointIds ? group.focusPointIds : []);
								}
								let topics = null;
								if (reflectionData && reflectionData.topics) {
									if (group.selectedTopics && group.selectedTopics.length > 0) {
										topics = group.selectedTopics.map((topicId) => {
											let topicTitle = getText(reflectionData.topics.find((t) => {
												return t.id === topicId;
											}).title, game.languageId);
											return topicTitle;
										});
									} else {
										topics = [];
									}
								}
								return (
									<div key={`r-${gIndex}`} className="ReflectionResult-groupRow">
										{dirtyDozenIds && <div className="ReflectionResult-groupAnswerContainer">
											{dirtyDozenIds.map((ddId, index) => {
												if (index >= reflectionData.numberOfDirtyDozensToSelect) return null;
												let ddData = dirtyDozenData.find((dd) => {return dd.id === ddId;});
												return (
													<div 
														key={ddId}
														className="ReflectionResult-groupAnswerDirtyDozen"
													><span>{getText(ddData.title, game.languageId)}</span></div>
												);
											})}
										</div>}
										{focusPointIds && <div className="ReflectionResult-groupAnswerContainer">
											{focusPointIds.map((fpId, index) => {
												if (index >= reflectionData.numberOfFocusPointsToSelect) return null;
												let fpData = reflectionData.focusPoints.find((fp) => {
													return fp.id === fpId;
												});
												return (
													<div 
														key={fpId}
														className="ReflectionResult-groupAnswerDirtyDozen"
													><span>{getText(fpData.title, game.languageId)}</span></div>
												);
											})}
										</div>}
										{topics && <div className="ReflectionResult-groupAnswerContainer">
											{topics.map((t, index) => {
												return (
													<div 
														key={index}
														className="ReflectionResult-groupAnswerTopics"
													><span>{t}</span></div>
												);

											})}
										</div>}
										{reflectionData && reflectionData.questions &&
											reflectionData.questions.map((question, qIndex) => {
												if (question.answerType === 'intro') return null;
												let className =	question.answerType;
												let answerValue = null;
												if (getIndexOfGameStep(group.gameStep, game.type) >=
												getIndexOfGameStep(gameStep, game.type)
												) {
													if (group.reflectionAnswers) {
														if (
															group.reflectionAnswers.some((answer) => {
																return (
																	answer.gameStep ===
																	reflectionData.gameStep &&
																answer.id === question.id
																);
															})
														) {
															answerValue = group.reflectionAnswers.find(
																(answer) => {
																	return (
																		answer.gameStep ===
																		reflectionData.gameStep &&
																	answer.id === question.id
																	);
																}
															).value;
														}
													}
												}
												let answer = answerValue;
												if (question.answerType === 'scale' && answer) {
													answer = [...Array(5)].map((_, index) => {
														let value = index + 1;
														let className = 'ReflectionResult-option';
														if (answerValue > value) {
															className = className + 
															' ReflectionResult-option--included';
														}
														if (answerValue === value) {
															className = className + 
															' ReflectionResult-option--selected';
														}
														return (
															<div key={`s-${index}`} className={className}>
																{value}
															</div>
														);
													});
												}
												return (
													<div 
														key={`a-${qIndex}`} 
														className={`ReflectionResult-groupAnswerContainer ${className}`}
													>
														<div className="ReflectionResult-groupAnswer">
															<span>{answer}</span>
														</div>
													</div>
												);
											})}
									</div>
								);
							})}
						</div>
					</div>
				</React.Fragment>
			)}
		</div>
	);
};

ReflectionResult.propTypes = {
	game: PropTypes.object.isRequired,
	companyData: PropTypes.object,
	gameStep: PropTypes.string.isRequired,
	groups: PropTypes.array.isRequired,
	handleGoToPage: PropTypes.func.isRequired,
	confirmAndContinue: PropTypes.func.isRequired,
	goToPrevGameStep: PropTypes.func.isRequired,
	toggleInfoPopup: PropTypes.func.isRequired,
};

export default ReflectionResult;
