import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { 
	toggleEventCardOption, 
	allRequiredActionsHaveBeenTaken,
	calculateCardEffects, 
	adjustGameStepCards, 
	handlePossibleIncident,
	triggerCriticalEvent,
} from 'helpers/group-game-helper';
import { gamesData } from 'data/games-data';
import { generalUiTexts } from 'data/ui-texts';
import {getText} from 'helpers/language-helper';
import GameBoardCRM from './game-board-crm';
import GameBoardCRMHeli from './game-board-crm-heli';
import InfoBox from 'components/info-box/info-box';
import InfoPopup from 'components/info-popup/info-popup';
import InfoMapPopup from 'components/info-map-popup/info-map-popup';


class GameBoardCRMController extends Component {
	constructor(props) {
		super(props);
		this.state = {
			delayDealingCards: true,
			isConfirmingResourcePlacements: false,
			showInfoPopup: false,
			showResourceWarning: false,
			showRequiredOptionsMissingError: false,
			resolvedEventCardIndex: -1
		};
		this.timeout = null;
	}

	/**
	 * Component did mount
	 */
	componentDidMount = () => {
		/* Show info popup */
		const gameStepData = gamesData[this.props.game.type].gameSteps.find((step) => {
			return step.id === this.props.group.gameStep;
		});
		const showInfoPopup = (gameStepData.infoPopup && gameStepData.infoPopup.autoShow === true ? true : false);
		const groundRoundIsOpen = (this.props.game.hasOwnProperty('groundRoundIsOpen') 
			? this.props.game.groundRoundIsOpen
			: false
		);
		if (
			showInfoPopup === true && 
			(!this.props.isFacilitator || (!groundRoundIsOpen && this.props.game.type === 'crm-aeroplanes'))			
		) {
			this.setState({ showInfoPopup: true});
		} else {
			this.setState({delayDealingCards: false});
		}

		/* Check if round cards have been adjusted to previous round's results */
		if (!this.props.isFacilitator) {
			if (this.props.group.gameStepCards.hasOwnProperty(this.props.group.gameStep)) {
				if (!this.props.group.gameStepCards[this.props.group.gameStep].cardsAdjusted) {
					this.handleAdjustGameStepCards();
				}
			}		
		}
	};

	/**
	 * Component did update
	 * @param {object} prevProps
	 */
	componentDidUpdate = (prevProps) => {
		if (!this.props.isFacilitator) {
			const gameStep = this.props.group.gameStep;

			/* Same game step */
			if (prevProps.group.gameStep === this.props.group.gameStep) {
				/* Resource placement has been confirmed */
				if (
					prevProps.group.hasOwnProperty('gameStepCards') &&
					prevProps.group.gameStepCards.hasOwnProperty(gameStep) &&
					!prevProps.group.gameStepCards[gameStep].resourcePlacementsConfirmed &&
					this.props.group.gameStepCards[gameStep].resourcePlacementsConfirmed
				) {
					/* Close all popups */
					this.closeWarningPopups();
					this.toggleInfoPopup(false);

					/* Automatically show effects of first card */
					this.timeout = setTimeout(() => {
						this.handleApplyEventCardEffects(true, -1);
					}, 500);
				}

				/* Check if a new event card has been resolved */
				if (
					prevProps.group.hasOwnProperty('gameStepCards') &&
					this.props.group.hasOwnProperty('gameStepCards') &&
					prevProps.group.gameStepCards.hasOwnProperty(gameStep) &&
					this.props.group.gameStepCards.hasOwnProperty(gameStep) &&
					this.props.group.gameStepCards[gameStep].resourcePlacementsConfirmed
				) {
					let resolvedEventCardIndex = -1;
					this.props.group.gameStepCards[gameStep].cards.forEach((card, index) => {
						if (card && card.effectsApplied && card.effectsApplied === true) {
							if (prevProps.group.gameStepCards[gameStep].cards[index].effectsApplied !== true) {
								resolvedEventCardIndex = index;
							}
						}
					});
					if (resolvedEventCardIndex >= 0) {
						this.timeout = setTimeout(() => {this.setState({resolvedEventCardIndex});}, 1000);
						this.timeout = setTimeout(() => {this.setState({resolvedEventCardIndex: -1});}, 4000);
					}
				}

			/* Game progressed to next game step */
			} else {
				if (prevProps.group.gameStep && this.props.game.type === 'crm-helicopters') {
					/* CRM-heli: auto-show info popup */
					this.setState({showInfoPopup: true, delayDealingCards: true});
				}
				if (this.props.group.gameStepCards.hasOwnProperty(this.props.group.gameStep)) {
					/* Check if round cards have been adjusted to previous round's results */
					if (!this.props.group.gameStepCards[this.props.group.gameStep].cardsAdjusted) {
						this.handleAdjustGameStepCards();
					}
				}
			}
		}
	};

	/**
	 * Hide / show info popup
	 */
	toggleInfoPopup = (show = null) => {
		let showInfoPopup = !this.state.showInfoPopup;
		if (show !== null) showInfoPopup = show;
		this.setState({ showInfoPopup, delayDealingCards: false });
	};

	/**
	 * Close warning popups
	 */
	closeWarningPopups = () => {
		this.setState({ showResourceWarning: false, showRequiredOptionsMissingError: false });
	};

	/**
	 * Adjust game step cards according to effects from previous game steps
	 */
	handleAdjustGameStepCards = () => {
		if (this.props.isFacilitator) return;
		let groupUpdates = adjustGameStepCards(this.props.group, this.props.game.type, this.props.game.scenario);
		this.props.updateGroup(groupUpdates);
	};	

	/**
	 * Flip / replace possible incident event card
	 * @param {number} cardIndex
	 * @param {string} cardId
	 */
	triggerPossibleIncident = (cardIndex, cardId) => {
		let groupUpdates = handlePossibleIncident(
			this.props.group, 
			cardIndex, 
			cardId, 
			this.props.game.type,
			this.props.game.scenario
		);
		this.props.updateGroup(groupUpdates);
	};

	/**
	 * Replace event card with critical event card
	 * @param {number} cardIndex
	 * @param {string} cardId
	 */
	handleTriggerCriticalEvent = (cardIndex, cardId) => {
		if (this.props.isFacilitator) return;
		let groupUpdates = triggerCriticalEvent(
			this.props.group, 
			cardIndex, 
			cardId, 
			this.props.game.type,
			this.props.game.scenario
		);
		this.props.updateGroup(groupUpdates);
	};

	/**
	 * Select / deselect option on card
	 * @param {string} cardId
	 * @param {string} optionId
	 */
	handleToggleOption = (cardId, optionId) => {
		if (this.props.isFacilitator) return;

		/* Toggle event card option */
		let groupUpdates = toggleEventCardOption(
			this.props.group, 
			cardId, 
			optionId, 
			this.props.game.type, 
			this.props.game.scenario
		);
		this.props.updateGroup(groupUpdates);
	};

	handleConfirmActionCardOption = (indexOfNextActionCard) => {
		if (this.props.isFacilitator) return;

		const gameStep = this.props.group.gameStep;
		let gameStepCards = JSON.parse(JSON.stringify(this.props.group.gameStepCards));
		if (
			indexOfNextActionCard >= 0 &&
			gameStepCards[gameStep].cards[indexOfNextActionCard].selectedOptionId
		) {
			if (gameStepCards[gameStep].cards[indexOfNextActionCard].resourcePlacementsConfirmed === true) {
				this.handleApplyEventCardEffects(false, indexOfNextActionCard);
			} else {
				gameStepCards[gameStep].cards[indexOfNextActionCard].resourcePlacementsConfirmed = true;
				this.props.updateGroup({gameStepCards: gameStepCards}).then(() => {
					this.handleApplyEventCardEffects(false, indexOfNextActionCard);
				});
			}
		}
	};

	/**
	 * Check if resource placement can be confirmed
	 */
	handleConfirmResourcePlacements = () => {
		if (this.props.isFacilitator) return;

		/* Check if all cards that require the user to select an option has an selectedOptionId */
		let noMissingActions = allRequiredActionsHaveBeenTaken(
			this.props.group.gameStepCards[this.props.group.gameStep].cards, 
			this.props.game.type,
			this.props.game.scenario
		);
		if (!noMissingActions) {
			/* Error: required option(s) missing */
			this.setState({ showRequiredOptionsMissingError: true });
			return;
		}

		/* Check if all resources have been placed (crm-aeroplanes only) */
		let noAvailableResources = this.props.group.resources.filter((resource) => {
			return (resource.isAvailable === true && resource.isPlaced === false);
		}).length === 0;
		if (!noAvailableResources) {
			/* Warning: not all resources have been placed */
			this.setState({ showResourceWarning: true });
			return;
		}

		/* Confirm resource placements */
		this.confirmResourcePlacements();
	};

	/**
	 * Confirm resource placements
	 */
	confirmResourcePlacements = () => {
		this.setState({isConfirmingResourcePlacements: true}, () => {
			this.props.confirmResourcePlacements(this.props.group.gameStep).then(() => {
				/* componentDidUpdate() reacts to resource placements being confirmed */
				this.setState({isConfirmingResourcePlacements: false});
			}, (error) => {
				console.error(error);
				this.setState({isConfirmingResourcePlacements: false});
			});
		});
	};

	/**
	 * Calculate and apply event card effects
	 * */
	handleApplyEventCardEffects = (firstCard = false, index = -1) => {
		if (this.props.isFacilitator) return;
		let groupUpdates = calculateCardEffects(
			firstCard, 
			index, 
			this.props.group, 
			this.props.game.type, 
			this.props.game.scenario
		);
		this.props.updateGroup(groupUpdates);
	};
	
	/**
	 * Render component
	 */
	render() {
		/* Access */
		let groundRoundIsOpen = (this.props.game.hasOwnProperty('groundRoundIsOpen') 
			? this.props.game.groundRoundIsOpen
			: false
		);

		let GameBoardComponent = GameBoardCRM;
		if (this.props.game.type === 'crm-helicopters') GameBoardComponent = GameBoardCRMHeli;

		return (
			<React.Fragment>
				<GameBoardComponent
					languageId={this.props.game.languageId}
					gameType={this.props.game.type}
					gameScenario={this.props.game.scenario}
					isFacilitator={this.props.isFacilitator}
					delayDealingCards={this.state.delayDealingCards}
					isConfirmingResourcePlacements={this.state.isConfirmingResourcePlacements}
					gameIsPaused={this.props.game.isPaused}
					gamePhase={this.props.game.gamePhase}
					resolvedEventCardIndex={this.state.resolvedEventCardIndex}
					group={this.props.group}
					handleGoToPage={this.props.handleGoToPage}
					toggleInfoPopup={this.toggleInfoPopup}
					triggerPossibleIncident={this.triggerPossibleIncident}
					handleToggleOption={this.handleToggleOption}
					handleTriggerCriticalEvent={this.handleTriggerCriticalEvent}
					handleConfirmActionCardOption={this.handleConfirmActionCardOption}
					handleConfirmResourcePlacements={this.handleConfirmResourcePlacements}
					handleApplyEventCardEffects={this.handleApplyEventCardEffects}
					confirmAndContinue={this.props.confirmAndContinue}
				/>
				{(this.state.showInfoPopup && this.props.game.type !== 'crm-helicopters') && (
					<InfoPopup
						canToggle={groundRoundIsOpen}
						game={this.props.game}
						gameStep={this.props.group.gameStep}
						toggleInfoPopup={this.toggleInfoPopup}
						handleLogout={ null }
					/>
				)}
				{(this.state.showInfoPopup && this.props.game.type === 'crm-helicopters') && ( 
					<InfoMapPopup 
						isFacilitator={this.props.isFacilitator}
						gameStep={this.props.group.gameStep}
						game={this.props.game} 
						group={this.props.group}
						updateGroup={this.props.updateGroup}
						toggleInfoPopup={this.toggleInfoPopup}
					/>
				)}		
				{this.state.showResourceWarning && (
					<InfoBox
						isFullscreen={true}
						title={getText(generalUiTexts.warning, this.props.game.languageId)}
						text={getText(generalUiTexts.resourceWarning, this.props.game.languageId)}
						buttons={[
							{
								text: getText(generalUiTexts.confirm, this.props.game.languageId),
								action: this.confirmResourcePlacements,
								params: [],
								isLoading: this.state.isConfirmingResourcePlacements,
								classes: ['confirm']
							},
							{
								text: getText(generalUiTexts.cancel, this.props.game.languageId),
								action: this.closeWarningPopups,
								params: [],
								classes: ['cancel']
							},
						]}
						handleClose={() => {this.closeWarningPopups();}}
					/>
				)}
				{this.state.showRequiredOptionsMissingError && (
					<InfoBox
						isFullscreen={true}
						title={getText(generalUiTexts.warning, this.props.game.languageId)}
						text={getText(generalUiTexts.requiredOptionsWarning, this.props.game.languageId)}
						buttons={[
							{
								text: getText(generalUiTexts.ok, this.props.game.languageId),
								action: this.closeWarningPopups,
								params: [],
							},
						]}
						handleClose={() => {
							this.closeWarningPopups();
						}}
					/>
				)}
			</React.Fragment>
		);
	}
}

GameBoardCRMController.propTypes = {
	isFacilitator: PropTypes.bool.isRequired,
	game: PropTypes.object.isRequired,
	group: PropTypes.object.isRequired,
	handleGoToPage: PropTypes.func.isRequired,
	updateGroup: PropTypes.func,
	confirmResourcePlacements: PropTypes.func.isRequired,
	confirmAndContinue: PropTypes.func.isRequired,
};

export default GameBoardCRMController;
