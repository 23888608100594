import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import appConfig from 'config/app.config';
import {loginUiTexts} from 'data/ui-texts';
import {languagesData} from 'data/languages-data';
import {gamesData} from 'data/games-data';
import {getText} from 'helpers/language-helper';
import Logo from 'components/logo/logo';
import LoginFacilitatorController from './login-facilitator-controller';
import LoginGroupController from './login-group-controller';
import Select from 'components/select/select';
import aeroteam from 'assets/images/aeroteam.png';

const LoginContainer = ({languageId, gameType, activeLoginBox, updateLanguage, setActiveLoginBox}) => {
	const gameTitle = (gamesData.hasOwnProperty(gameType) 
		? getText(gamesData[gameType].titleOverview, languageId) 
		: ''
	);

	return (
		<div className="Login">
			<Link to="/" className="Login-link">
				<img src={aeroteam} className="Login-aeroteamLogo" alt="aeroteam logo" />
			</Link>
			<div className="Login-language">
				<Select 
					isDisabled={false}
					type="login"
					options={languagesData}
					defaultId={appConfig.defaultLanguage}
					selectedId={languageId}
					onSelect={updateLanguage}
				/>
			</div>
			<div className={'LoginContainer ' + activeLoginBox}>
				<Logo classes={['login']} />
				<div className="Login-gameTitle">{gameTitle}</div>
				<div className="Login-tabContainer">
					<div
						className={'Login-tabButton' + (activeLoginBox === 'facilitator' ? ' active' : '')}
						onClick={() =>{setActiveLoginBox('facilitator');}}
					>{getText(loginUiTexts.facilitator, languageId)}</div>
					<div
						className={'Login-tabButton' + (activeLoginBox === 'group' ? ' active' : '')}
						onClick={() =>{setActiveLoginBox('group');}}
					>{getText(loginUiTexts.group, languageId)}</div>
				</div>
				<div className="Login-formContainer">
					{activeLoginBox === 'facilitator' && 
						<LoginFacilitatorController languageId={languageId} />}
					{activeLoginBox === 'group' && 
						<LoginGroupController languageId={languageId} gameType={gameType} />}
				</div>
			</div>
			<div className='Login-logo' onClick={() => {window.open('https://cphgamelab.dk/', '_blank', 'noopener,noreferrer');}} ></div>
		</div>
	);
};

LoginContainer.propTypes = {
	languageId: PropTypes.string.isRequired,
	gameType: PropTypes.string.isRequired,
	activeLoginBox: PropTypes.string.isRequired,
	updateLanguage: PropTypes.func.isRequired,
	setActiveLoginBox: PropTypes.func.isRequired
};

export default LoginContainer;