import React,  { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { exerciseToolsData } from 'data/tools-data';
import { generalUiTexts} from 'data/ui-texts';
import {gamesData} from 'data/games-data';
import {renderMarkdown} from 'helpers/text-helper';
import {getText} from 'helpers/language-helper';
import Logo from 'components/logo/logo';
import Button from 'components/button/button';
import ProgressBar from 'components/progress-bar/progress-bar';
import './exercise.scss';

const Exercise = (props) => {
	let {
		group,
		exerciseTool,
		handleGoToPage,
		handleInputText,
		confirmAndContinue,
		gameIsPaused,
		game
	} = props;

	/* Get data for this game's exercise tool  */
	let exerciseToolData = exerciseToolsData.find((tool) => {return tool.id === exerciseTool;});
	const scenarioData = gamesData[game.type].scenarios.find((sc) => {return sc.id === game.scenario;});
	const threatsData = scenarioData.threatsData;

	/* Confirm button status */
	const [confirmBtnIsDisabled, setConfirmBtnIsDisabled] = React.useState(false);	
	useEffect(() => {
		let btnIsDisabled = true;
		if (
			exerciseToolData && 
			exerciseToolData.questions && 
			group.hasOwnProperty('exerciseAnswers') &&
			group.exerciseAnswers.length > 0
		) {
			btnIsDisabled = false;
			exerciseToolData.questions.forEach((question) => {
				if (btnIsDisabled === true) return;
				const groupAnswer = group.exerciseAnswers.find((answer) => {return answer.id === question.id;});
				if (!groupAnswer || groupAnswer.value === '') btnIsDisabled = true;
			});
		}
		setConfirmBtnIsDisabled(btnIsDisabled);
	}, [exerciseToolData, group]);
	

	/* Triggered threats text */
	let threats =  `${group.threats.map((threat) => {
		let threatName = getText(threatsData.find((t) => {return t.id === threat;}).title, game.languageId);
		if (threatName) return `${threatName}`;
		return `${threat}`;
	}).join(', ')}`;
			

	/* Load and store answer values locally */
	const [answers, setAnswers] = useState([]);
	useEffect(() => {
		if (exerciseToolData && exerciseToolData.questions) {
			let initAnswersArray = [];
			exerciseToolData.questions.forEach((question) => {
				/* Answer template  */
				let initAnswer = {id: question.id, value: ''};
				/* Group answer */		
				const groupAnswer = group.exerciseAnswers?.find((a) => {return (a.id === question.id);});
				if (groupAnswer && groupAnswer.value) initAnswer.value = groupAnswer.value;
				initAnswersArray.push(initAnswer);
			});
			setAnswers(initAnswersArray);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [exerciseToolData]);

	/* Update locally stored answers when props are updated */
	useEffect(() => {
		setAnswers((answers) => {
			let newAnswers = JSON.parse(JSON.stringify(answers));
			exerciseToolData.questions.forEach((question) => {
				const newAnswerIndex = newAnswers.findIndex((a) => {return a.id === question.id;});
				const groupAnswer = group.exerciseAnswers?.find((a) => {return (a.id === question.id);});		
				if (newAnswerIndex >= 0 && groupAnswer) {
					/* Only overwrite if local version is different */
					if (newAnswers[newAnswerIndex].value !== groupAnswer.value) {
						newAnswers[newAnswerIndex].value = groupAnswer.value;
					}
				}
			});
			return newAnswers;
		});
	}, [exerciseToolData.questions, group.exerciseAnswers]);

	/* Update locally stored answers & database when user is typing */
	const handleTextChange = (e, id) => {
		setAnswers((answers) => {
			let newAnswers = JSON.parse(JSON.stringify(answers));
			answers.forEach((answer, i) => {
				if (answer.id === id) newAnswers[i].value = e.target.value;
			});
			return newAnswers;
		});
		handleInputText(e);
	};

	return (
		<div className="Exercise">
			<Logo onClick={() => {handleGoToPage('welcome');}}
			/>
			<ProgressBar 
				gameType={game.type}
				title={getText(exerciseToolData.title, game.languageId)} 	
				// linkText={getText(generalUiTexts.flightInfo, game.languageId)}
				// toggleInfo={toggleInfoPopup}
				linkText={null}
				toggleInfo={null}
			/>
			<Button
				text={gameIsPaused 
					? getText(generalUiTexts.paused, game.languageId) 
					: getText(generalUiTexts.submit, game.languageId)
				}
				classes={gameIsPaused ? ['paused'] : ['next']}
				isDisabled={gameIsPaused ? true : confirmBtnIsDisabled}
				onClick={() => {confirmAndContinue('exercise');}}
			/>
			<div className="Exercise-container">
				<div className="Exercise-form">
					<div className={'Exercise-header'}>{getText(exerciseToolData.title, game.languageId)}</div>
					<div className={'Exercise-intro'}>
						{exerciseToolData.intro && getText(exerciseToolData.intro, game.languageId)}
					</div>
					<div className={'Exercise-questions'}>
						{exerciseToolData && exerciseToolData.questions && 
						exerciseToolData.questions.map((question) => {
							let answer = null;
							let exerciseAnswer = (answers 
								? answers.find((answer) => {return answer.id === question.id;})
								: null
							);
							if (exerciseAnswer && exerciseAnswer.value) answer = exerciseAnswer.value;
							return (
								<div key={question.id} className="Exercise-question">
									<div className="Exercise-questionTitle">
										{renderMarkdown(getText(question.title, game.languageId))}
									</div>
									<div className="Exercise-answer">
										<textarea
											id={question.id}
											className="Exercise-textarea"
											name={question.id}
											placeholder={
												(question.placeholder 
													? getText(question.placeholder, game.languageId)
													: getText(generalUiTexts.reflection.textPlaceholder, 
														game.languageId)
												)	
											}
											rows="5"
											value={answer ? answer : ''}
											onChange={(event) => {handleTextChange(event, question.id);}}
										></textarea>
									</div>
								</div>
							);
						})}
						{!exerciseToolData && <p>Unknown reflection</p>}
					</div>
				</div>
				<div className="Exercise-overview">
					{threats && <div className="threat">
						<p className="threat-title">{getText(generalUiTexts.threats, game.languageId)}:</p>
						<div className="threat-content">{threats}</div>
					</div>}
				</div>
			</div>
		</div>
	);
};

Exercise.propTypes = {
	group: PropTypes.object.isRequired,
	exerciseTool: PropTypes.string.isRequired,
	handleGoToPage: PropTypes.func.isRequired,
	handleInputText: PropTypes.func.isRequired,
	confirmAndContinue: PropTypes.func.isRequired,
	gameIsPaused: PropTypes.bool.isRequired,
	game: PropTypes.object.isRequired,
};

export default Exercise;
