import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import EventCardSTFront from './event-card-st-front';
import EventCardSTBack from './event-card-st-back';
import './event-card-st.scss';

const EventCardST = (props) => {
	let {
		selectedOptionsConfirmed,
		isDisabled,
		effectsApplied,
		languageId,
		gameType,
		group,
		card,
		cardData,
		cardTypes,
		selectedCrew,
		animationFinished,
		handleFindCard,
		handleMoveCard
	} = props;

	/* Animation / show back */
	let animateCardFlip = effectsApplied;
	const [flip, setFlip] = useState(true); 
	const toggleFlip = () => {
		if (animateCardFlip) {
			setFlip((flip) => { return !flip; });
		}
	};

	useEffect(()=>{
		setFlip(true);
	}, [group.gameStep]);

	return (
		<div 
			className={'EventCardST' + (animateCardFlip ? flip ? ' animate' : ' back' : '')}
			onClick={() => {toggleFlip();}}
		>
			<div className={'EventCardST-container' + (animateCardFlip ? flip ? ' animate' : ' back' : '') }>
				<div className="EventCardST-front">
					<EventCardSTFront
						isDisabled={isDisabled}
						animationFinished={animationFinished}
						languageId={languageId}
						gameType={gameType}
						group={group}
						card={card}
						cardData={cardData}
						cardTypes={cardTypes}
						selectedOptionsConfirmed={selectedOptionsConfirmed}
						handleFindCard={handleFindCard}
						handleMoveCard={handleMoveCard}
					/>
				</div>
				<div className="EventCardST-back">
					<EventCardSTBack 
						isDisabled={isDisabled} 
						languageId={languageId}
						gameType={gameType}
						card={card} 
						cardData={cardData} 
						selectedCrew={selectedCrew} 
					/>
				</div>
			</div>
		</div>
	);
};

EventCardST.propTypes = {
	selectedOptionsConfirmed: PropTypes.bool.isRequired,
	isDisabled: PropTypes.bool.isRequired,
	effectsApplied: PropTypes.bool.isRequired,
	animationFinished: PropTypes.bool.isRequired,
	languageId: PropTypes.string.isRequired,
	gameType: PropTypes.string.isRequired,
	group: PropTypes.object.isRequired,
	card: PropTypes.object.isRequired,
	cardData: PropTypes.object.isRequired,
	cardTypes: PropTypes.array.isRequired,
	selectedCrew: PropTypes.array.isRequired,
	handleMoveCard: PropTypes.func.isRequired,
	handleFindCard: PropTypes.func.isRequired,

};

export default EventCardST;
