import React from 'react';
import PropTypes from 'prop-types';
import './group-players.scss';

const GroupPlayers = ({group, companyData, type}) => {
	let groupPlayers = [];
	if (group.players && companyData && companyData.players) {
		/* Check player still exists as company player */
		group.players.forEach((player) => {
			let playerData = companyData.players.find((p) => {return p.id === player.id;});
			if (playerData) groupPlayers.push(playerData);
		});
	}


	return (
		<div className={'GroupPlayers ' + type}>
			{groupPlayers.map((player) => {
				return (
					<div key={player.id} className="GroupPlayers-player">{player.name}</div>
				);
			})}
		</div>
	);
};

GroupPlayers.propTypes = {
	group: PropTypes.object.isRequired,
	companyData: PropTypes.object,
	type: PropTypes.string.isRequired
};

export default GroupPlayers;
