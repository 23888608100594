import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {gamesData} from 'data/games-data';
import {settingsUiTexts} from 'data/ui-texts';
import {sortArrayByProperty} from 'helpers/array-helper';
import {getText} from 'helpers/language-helper';
import Overview from './overview';
import GroupPopup from './group-popup';
import StatusPopup from './status-popup';
import InfoBox from 'components/info-box/info-box';

class OverviewController extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showStatusPopup: null,
			showGroupsAreNotReadyPopup: false,
			groupId: null,
		};
		this.timeout = null;
	}

	/**
	 * Component will unmount
	 */
	componentWillUnmount = () => {
		if (this.timeout) clearTimeout(this.timeout);
	};

	/**
	 * Open / close group popup
	 * @param {string} groupId 
	 */
	toggleGroupPopup = (groupId) => {
		this.setState({ groupId });
		if (!groupId && this.props.isFullscreen) this.props.handleToggleFullscreen();
	};

	/**
	 * Open / close status popup
	 * @param {bool} showStatusPopup 
	 * @param {} btnIsActive 
	 */
	toggleStatusPopup = (showStatusPopup, minIndex = -1) => {
		/* Close popup */
		if (!showStatusPopup) {
			this.setState({showStatusPopup: false, statusPopupData: null});
			if (this.props.isFullscreen) this.props.handleToggleFullscreen();
			return;
		}

		/* Open popup */
		if (minIndex < 0) {
			/* Inactive */
			this.setState({showStatusPopup: true, statusPopupData: {type: 'no-status'}});
		} else {
			/* Show status */
			const gamePhases = gamesData[this.props.game.type].gamePhases;
			let currentGamePhase = gamePhases.find((phase) => {return phase.id === this.props.game.gamePhase;});
			if (currentGamePhase.statusBtn) {
				let statusPopupData = {
					gameStepId: currentGamePhase.statusBtn.gameStepIds[minIndex],
					type: currentGamePhase.statusBtn.types[minIndex],
				};
				if (statusPopupData.type === 'reflection') {
					statusPopupData.reflectionId = currentGamePhase.statusBtn.reflectionIds[minIndex];
				}
				this.setState({showStatusPopup: true, statusPopupData});
			}
		}
	};

	/**
	 * Show / hide warning popup
	 */
	toggleGroupsAreNotReadyPopup = () => {
		let showGroupsAreNotReadyPopup = !this.state.showGroupsAreNotReadyPopup;
		this.setState({showGroupsAreNotReadyPopup});
	};

	/**
	 * Pause and unpause game
	 */
	togglePauseGame = () => {
		let isPaused = (this.props.game.isPaused ? false : true);
		this.props.pauseGame(isPaused);
	};

	/**
	 * Unlock next step in current game phase
	 */
	handleUnlockNextStepInPhase = () => {
		const gamePhases = gamesData[this.props.game.type].gamePhases;
		let currentGamePhase = gamePhases.find((phase) => {return phase.id === this.props.game.gamePhase;});
		if (currentGamePhase && currentGamePhase.steps) {
			let nextStep = null;
			currentGamePhase.steps.forEach((step) => {
				if (nextStep) return;
				if (!this.props.game.hasOwnProperty(step.id) || this.props.game[step.id] === false) nextStep = step;
			});
			if (nextStep) this.props.unlockGameStep(nextStep.id);
		}
	};

	/**
	 * Handle go to next game phase
	 */
	handleGoToNextGamePhase = () => {
		let allGroupsAreReady = true;

		/* Check if all groups are ready */
		const gameSteps = gamesData[this.props.game.type].gameSteps;
		let lastGameStepInPhase = null;
		let gameStepsInPhase = gameSteps.filter((step) => {return step.phase === this.props.game.gamePhase;});
		if (gameStepsInPhase.length > 0) {
			lastGameStepInPhase = sortArrayByProperty(gameStepsInPhase, 'index', 'DESC')[0];
		}
		this.props.groups.forEach((group) => {
			if (lastGameStepInPhase && group.gameStep !== lastGameStepInPhase.id) allGroupsAreReady = false;
		});

		if (allGroupsAreReady) {
			this.goToNextGamePhase();
		} else {
			this.setState({showGroupsAreNotReadyPopup: true});
		}
	};

	/**
	 * Go to next game phase
	 */
	goToNextGamePhase = () => {
		/* Get next game phase */
		const gamePhases = gamesData[this.props.game.type].gamePhases;
		let currentGamePhaseIndex = gamePhases.findIndex((phase) => {
			return phase.id === this.props.game.gamePhase;
		});
		if (currentGamePhaseIndex < 0) currentGamePhaseIndex = 0;
		let nextGamePhase = (currentGamePhaseIndex + 1 < gamePhases.length 
			? gamePhases[currentGamePhaseIndex + 1] 
			: null
		);

		if (nextGamePhase) {
			this.props.goToGamePhase(nextGamePhase.id);
		}
		this.setState({showGroupsAreNotReadyPopup: false});
	};

	/**
	 * Render component
	 */
	render() {
		if (this.props.game !== null) {
			return (
				<React.Fragment>
					<Overview
						languageId={this.props.userData.languageId}
						email={this.props.userData.email}
						game={this.props.game}
						companyData={this.props.companyData}
						groups={this.props.groups}
						togglePauseGame={this.togglePauseGame}
						toggleGroupPopup={this.toggleGroupPopup}
						toggleStatusPopup={this.toggleStatusPopup}
						handleUnlockNextStepInPhase={this.handleUnlockNextStepInPhase}
						handleGoToNextGamePhase={this.handleGoToNextGamePhase}
						handleGoToPage={this.props.handleGoToPage}
						handleLogout={this.props.handleLogout}
					/>
					{this.state.groupId && 
						<GroupPopup
							isFullscreen={this.props.isFullscreen}
							languageId={this.props.userData.languageId}
							game={this.props.game}
							companyData={this.props.companyData}
							groupId={this.state.groupId}
							groups={this.props.groups}
							toggleGroupPopup={this.toggleGroupPopup}
							handleToggleFullscreen={this.props.handleToggleFullscreen}
						/>
					}
					{this.state.showStatusPopup && 
						<StatusPopup 
							isFullscreen={this.props.isFullscreen}
							languageId={this.props.userData.languageId}
							statusPopupData={this.state.statusPopupData}
							game={this.props.game}
							companyData={this.props.companyData}
							groups={this.props.groups}
							toggleStatusPopup={this.toggleStatusPopup}
							handleToggleFullscreen={this.props.handleToggleFullscreen}
						/>
					}
					{this.state.showGroupsAreNotReadyPopup && 
						<InfoBox
							isFullscreen={true}
							languageId={this.props.userData.languageId}
							title={getText(settingsUiTexts.nextPhaseWarningPopup.title, this.props.userData.languageId)}
							text={getText(settingsUiTexts.nextPhaseWarningPopup.text, this.props.userData.languageId)}
							buttons={[
								{
									text: getText(
										settingsUiTexts.nextPhaseWarningPopup.confirm, this.props.userData.languageId
									),
									action: this.goToNextGamePhase,
									params: [],
									classes: ['confirm']
								},
								{
									text: getText(
										settingsUiTexts.nextPhaseWarningPopup.cancel, this.props.userData.languageId
									),
									action: this.toggleGroupsAreNotReadyPopup,
									params: [],
									classes: ['cancel']
								},
							]}
							handleClose={() => {this.toggleGroupsAreNotReadyPopup();}}
						/>
					}
				</React.Fragment>
			);
		}
		return <div>Unknown game</div>;
	}
}

OverviewController.propTypes = {
	isFullscreen: PropTypes.bool.isRequired,
	userData: PropTypes.object.isRequired,
	companyData: PropTypes.object,
	game: PropTypes.object.isRequired,
	groups: PropTypes.array.isRequired,
	unlockGameStep: PropTypes.func.isRequired,
	goToGamePhase: PropTypes.func.isRequired,
	pauseGame: PropTypes.func.isRequired,
	handleGoToPage: PropTypes.func.isRequired,
	handleToggleFullscreen: PropTypes.func.isRequired,
	handleLogout: PropTypes.func.isRequired,
};

export default OverviewController;
