import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { gamesData } from 'data/games-data';
import { generalUiTexts } from 'data/ui-texts';
import {getText} from 'helpers/language-helper';
import { sortArrayByProperty } from 'helpers/array-helper';
import {getConfirmButtonData} from 'helpers/confirm-button-helper';
import Logo from 'components/logo/logo';
import Button from 'components/button/button';
import ProgressBar from 'components/progress-bar/progress-bar';
import CrewCarousel from './crew-carousel';
import CrewSlots from './crew-slots';
import CrewDndContainer from './crew-dnd-container';
import CrewDndCard from './crew-dnd-card';
import CrewDndCustomDragLayer from './crew-dnd-custom-drag-layer';
import './crew-select.scss';

const CrewSelect = (props) => {
	let {
		isFacilitator,
		showCarousel,
		panCards,
		game,
		availableCrew,
		handleGoToPage,
		toggleInfoPopup,
		updateSelectedCrew,
		confirmSelectedCrew,
	} = props;

	/* Game step data */
	const gameStepData = gamesData[game.type].gameSteps.find((step) => {return step.id === 'crew-select';});

	/* Crew cards */
	let [crewCards, setCrewCards] = useState([]);
	useEffect(() => {setCrewCards(availableCrew);}, [availableCrew]);

	/* Card types */
	const cardTypes = gamesData[game.type].crewData.cardTypes;

	/* Selected crew member (shown in popup) */
	let [selectedCrewMember, setSelectedCrewMember] = useState(null);

	/* Move crew card */
	const handleMoveCard = (cardId, containerId) => {
		if (isFacilitator) return;
		let newCrewCards = JSON.parse(JSON.stringify(crewCards));
		newCrewCards.forEach((card) => {
			if (card.id === cardId) {
				if (containerId === 'available') {
					/* Move selected card back to carousel */
					card.slotId = null;
				} else {
					/* If a card is already placed in the selected container, move it back to carousel */
					newCrewCards.forEach((card) => {
						if (card.slotId === containerId) card.slotId = null;
					});
					/* Move card to selected container */
					card.slotId = containerId;
				}
			}
		});

		/* Update crew cards (component) */
		setCrewCards(newCrewCards);

		/* Update selected crew (database) */
		let selectedCrew = [];
		newCrewCards.forEach((card) => {
			if (card.slotId !== null) selectedCrew.push({ id: card.id, slotId: card.slotId });
		});
		selectedCrew = sortArrayByProperty(selectedCrew, 'slotId', 'ASC');
		updateSelectedCrew(selectedCrew);
	};

	/* Find crew card (in container or among selected crew) */
	const handleFindCard = (cardId) => {
		let cardContainer = null;
		if (
			crewCards.some((card) => {
				return card.id === cardId;
			})
		) {
			let card = crewCards.filter((card) => {
				return card.id === cardId;
			})[0];
			cardContainer = card.slotId === null ? 'available' : card.slotId;
		}
		return cardContainer;
	};

	/* Confirmation button data */
	let allCrewSlotsFilled = crewCards.filter((card) => {
		return card.slotId !== null;
	}).length === gamesData[game.type].crewData.crewSlots.length;
	let confirmBtnData = getConfirmButtonData(allCrewSlotsFilled, game, 'crew-select');
	if (game.type === 'safetytraining') confirmBtnData.classes.push('st');

	return (
		<div className={'CrewSelect' + (game.type === 'crm-helicopters' ? ' heli' : '')}>
			<CrewDndContainer
				gameType={game.type}
				containerId="available"
				cardTypes={cardTypes}
				cardTypesAccepted={cardTypes}
				handleFindCard={handleFindCard}
				handleMoveCard={handleMoveCard}
			>
				{game.type !== 'safetytraining' && <Logo onClick={() => {handleGoToPage('welcome');}}/>}
				<ProgressBar
					gameType={game.type}
					title={getText(gameStepData.title, game.languageId)} 
					subtitle={getText(gameStepData.subtitle, game.languageId)} 
					linkText={(game.type !== 'safetytraining' 
						? getText(generalUiTexts.crewInfo, game.languageId) 
						: null
					)}
					toggleInfo={(game.type !== 'safetytraining' ? toggleInfoPopup : null)}
					onClick={(game.type === 'safetytraining' ? () => {handleGoToPage('welcome');} : null)}
				/>
				{game.type === 'safetytraining' && <Button
					text={''}
					classes={['st', 'info']}
					onClick={() => {toggleInfoPopup();}}
				/>}
				<Button
					isDisabled={confirmBtnData.isDisabled}
					text={confirmBtnData.text}
					classes={confirmBtnData.classes}
					onClick={() => {confirmSelectedCrew();}}
				/>
				<div className="CrewSelect-content">
					{showCarousel && (
						<CrewCarousel
							isFacilitator={isFacilitator}
							panCards={panCards}
							languageId={game.languageId}
							gameType={game.type}
							crewCards={crewCards.concat(crewCards)}
						/>
					)}
					<CrewSlots
						gameType={game.type}
						isFacilitator={isFacilitator}
						languageId={game.languageId}
						crewCards={crewCards}
						cardTypes={cardTypes}
						selectedCrewMember={selectedCrewMember}
						handleFindCard={handleFindCard}
						handleMoveCard={handleMoveCard}
						setSelectedCrewMember={setSelectedCrewMember}
					/>
				</div>
			</CrewDndContainer>
			<CrewDndCustomDragLayer 
				languageId={game.languageId}
				gameType={game.type} 
				handleFindCard={handleFindCard}
			/>


			{selectedCrewMember && (
				<div className="CrewSelect-popup" onClick={() => {setSelectedCrewMember(null);}}>
					<div className="CrewSelect-popupContent" onClick={(e) => {e.stopPropagation(); }}>
						<div className="CrewSelect-popupCloseBtn" onClick={() => {setSelectedCrewMember(null);}}/>
						<CrewDndCard 
							isInFocus={true}
							isDraggable={false}
							languageId={game.languageId}
							gameType={game.type}
							layout="crewSelectPopup"
							cardData={selectedCrewMember}
						/>
					</div>
				</div>
			)}
		</div>
	);
};

CrewSelect.propTypes = {
	isFacilitator: PropTypes.bool.isRequired,
	showCarousel: PropTypes.bool.isRequired,
	panCards: PropTypes.bool.isRequired,
	game: PropTypes.object.isRequired,
	availableCrew: PropTypes.array.isRequired,
	handleGoToPage: PropTypes.func.isRequired,
	toggleInfoPopup: PropTypes.func.isRequired,
	updateSelectedCrew: PropTypes.func.isRequired,
	confirmSelectedCrew: PropTypes.func.isRequired,
};

export default CrewSelect;
