import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import CrewCardSTFront from './crew-card-st-front';
import CrewCardSTBack from './crew-card-st-back';
import './crew-card-st-flippable.scss';

const CrewCardSTFlippable = ({
	canFlip,
	isFlipped,
	isDraggable, 
	isSelected,
	animateDD,
	languageId,
	cardData,
	cardTitle,
	layout,
	selectedCrew,
	onClick,
	toggleDirtyDozenPopup,
	shake = false
}) => {
	let [showBack, setShowBack] = useState(false);

	/* Auto flip (with delay if in crew feedback) */
	useEffect(() => {
		if (isFlipped) {
			setShowBack(true);
		} else {
			if (layout === 'showBack') setTimeout(() => {setShowBack(true);}, 500);
		}
	}, [isFlipped, layout]);

	return (
		<div
			className={'CrewCardSTFlippable' + (canFlip ? ' flippable' : '') + (onClick ? ' clickable' : '')}
			onClick={(e) => {
				e.stopPropagation(); 
				if (canFlip) {setShowBack(!showBack);} else {if (onClick) onClick();}
			}}
		>
			<div 
				className={'CrewCardSTFlippable-container' + (showBack ? ' back' : '')}
			>
				<div className="CrewCardSTFlippable-front">
					<CrewCardSTFront 
						isSelected={isSelected} 
						languageId={languageId}
						layout={layout} 
						cardData={cardData} 
						cardTitle={cardTitle}
					/>
				</div>
				<div className="CrewCardSTFlippable-back">
					<CrewCardSTBack 
						isDraggable={isDraggable}
						isSelected={isSelected}
						animateDD={animateDD}
						languageId={languageId}
						layout={layout} 
						cardData={cardData} 
						selectedCrew={selectedCrew} 
						toggleDirtyDozenPopup={toggleDirtyDozenPopup}
						shake={shake && isDraggable && showBack}
					/>
				</div>
			</div>
		</div>
	);
};

CrewCardSTFlippable.defaultProps = {
	isFlipped: false,
	animateDD: false
};

CrewCardSTFlippable.propTypes = {
	canFlip: PropTypes.bool.isRequired,
	isFlipped: PropTypes.bool,
	isDraggable: PropTypes.bool.isRequired,
	isSelected: PropTypes.bool.isRequired,
	animateDD: PropTypes.bool,
	languageId: PropTypes.string.isRequired,
	cardData: PropTypes.object.isRequired,
	cardTitle: PropTypes.string,
	layout: PropTypes.string,
	selectedCrew: PropTypes.array,
	onClick: PropTypes.func,
	toggleDirtyDozenPopup: PropTypes.func,
	shake: PropTypes.bool,
};

export default CrewCardSTFlippable;
