import React, {Component} from 'react';
import PropTypes from 'prop-types';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import appConfig from 'config/app.config';
import {loginUiTexts} from 'data/ui-texts';
import {getText} from 'helpers/language-helper';
import {checkIfGameIsAvailable} from 'helpers/game-access-helper';
import apiHelper from 'helpers/api-helper';
import LoginGroup from './login-group';

class LoginGroupController extends Component {
	constructor(props) {
		super(props);
		this.state = {
			code: null,
			groups: null,
			companyData: null,
			feedback: null,
			isLoading: false,
		};
	}

	/**
	 * Update input field
	 * @param {obj} event 
	 */
	handleInput = (event) => {
		let value = event.target.value;
		let name = event.target.name;
		this.setState({[name]: value, feedback: null});
	};

	/**
	 * Find the game with the given code, return error if not found
	 * @param {obj} event 
	 */
	handleFindGame = (event) => {
		event.preventDefault();
		const code = this.state.code;
		if (!code || code.length === 0) {
			this.setState({feedback: getText(loginUiTexts.someFieldsMissing, this.props.languageId)});
			return;
		}
		this.setState({isLoading: true});
		const db = firebase.firestore();

		db.collection(appConfig.gamesDbName).doc(code).get().then((gameDoc) => {
			if (gameDoc.exists && gameDoc.data().type === this.props.gameType && gameDoc.data().companyId) {
				db.collection('companies').doc(gameDoc.data().companyId).get().then((companyDoc) => {
					if (companyDoc.exists) {
						let companyData = {...companyDoc.data(), id: companyDoc.id};
						let gameIsAvailable = checkIfGameIsAvailable(gameDoc.data(), companyData);
						if (gameIsAvailable) {
							db.collection(appConfig.groupsDbName).where('gameId', '==', code).get().then((docs) => {
								let groups = [];
								docs.forEach((doc) => {groups.push({...doc.data(), id: doc.id});});
								this.setState({groups: groups, companyData, isLoading: false});
							}).catch((error) => {
								console.error(error);
								this.setState({
									isLoading: false,
									feedback: getText(loginUiTexts.unknownError, this.props.languageId)

								});		
							});
						} else {
							this.setState({
								isLoading: false,
								feedback: getText(loginUiTexts.notAvailable, this.props.languageId)
							});
						}
					} else {
						this.setState({
							isLoading: false,
							feedback: getText(loginUiTexts.couldNotFindCompany, this.props.languageId)
						});
					}
				}).catch(() => {
					this.setState({
						isLoading: false,
						feedback: getText(loginUiTexts.couldNotFindCompany, this.props.languageId)
					});
				});
			} else {
				this.setState({
					isLoading: false,
					feedback: getText(loginUiTexts.gameNotFound, this.props.languageId)
				});
			}
		}).catch((error) => {
			console.error(error);
			this.setState({feedback: getText(loginUiTexts, this.props.languageId).unknownError, isLoading: false});
		});	
	};	


	/**
	 * Get a login token.
	 * Call firebase auth to sign in with that token.
	 * @param {string} groupId 
	 */
	handleJoinGame = (groupId) => {
		this.setState({
			isLoading: true,
		});
		apiHelper('group/join-game', {gameCode: this.state.code, groupId: groupId}).then(
			(response)=>{
				let token = response.token;
				firebase.auth().signInWithCustomToken(token)
					.then(() => {
						// All okay, should trigger the authStateChanged in LoginController
						this.setState({isLoading: false});
					})
					.catch((error) => {
						console.error('sign in error', error);
						this.setState({
							isLoading: false, 
							feedback: getText(loginUiTexts.unknownError, this.props.languageId)
						});
					});
			},
			(rejection) => {
				console.error('rejection: ', rejection);
				this.setState({
					isLoading: false, 
					groups: null, 
					feedback: getText(loginUiTexts.unknownError, this.props.languageId)
				});
			}
		);
	};
	
	/**
	 * Render component
	 */
	render = () => {
		return (
			<LoginGroup
				languageId={this.props.languageId}
				handleInput={this.handleInput}
				handleFindGame={this.handleFindGame}
				handleJoinGame={this.handleJoinGame}
				code={this.state.code}
				companyData={this.state.companyData}
				groups={this.state.groups}
				feedback={this.state.feedback}
				isLoading={this.state.isLoading}
			/>
		);
	};
}

LoginGroupController.propTypes = {
	languageId: PropTypes.string.isRequired,
	gameType: PropTypes.string.isRequired,
};

export default LoginGroupController;
