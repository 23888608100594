import React from 'react';
import PropTypes from 'prop-types';
import './progress-bar.scss';

const ProgressBar = ({ gameType, gameStepId, page, title, subtitle, linkText, toggleInfo, onClick, backBtnLink }) => {
	return (
		<div 
			className={'ProgressBar ' + gameType 
				+ (page ? ' ' + page : '') 
				+ (linkText && toggleInfo ? ' link' : '')
				+ (backBtnLink ? ' backLink' : '')
			}
			onClick={onClick ? onClick : null}
		>
			{(backBtnLink && gameStepId) && 
				<div 
					className="ProgressBar-backBtn" 
					onClick={(e) => {e.stopPropagation(); backBtnLink(gameStepId);}}
				/>
			}
			<div className="ProgressBar-text"><span>{title} </span> {(subtitle ? ' // ' + subtitle : '')}</div>
			{(toggleInfo && linkText) && 
				<div className="ProgressBar-flightInfoBtn" onClick={toggleInfo}>{linkText}</div>
			}
		</div>
	);
};

ProgressBar.defaultProps = {
	page: null,
	subtitle: null,
	linkText: null,
	toggleInfo: null,
	onClick: null
};

ProgressBar.propTypes = {
	gameType: PropTypes.string.isRequired,
	gameStepId: PropTypes.string,
	page: PropTypes.string,
	title: PropTypes.string.isRequired,
	subtitle: PropTypes.string,
	linkText: PropTypes.string,
	toggleInfo: PropTypes.func,
	onClick: PropTypes.func,
	backBtnLink: PropTypes.func,
};

export default ProgressBar;
