import React from 'react';
import PropTypes from 'prop-types';
import {gamesData} from 'data/games-data';
import {generalUiTexts} from 'data/ui-texts';
import {getText} from 'helpers/language-helper';
import {getConfirmButtonData} from 'helpers/confirm-button-helper';
import Logo from 'components/logo/logo';
import Button from 'components/button/button';
import ProgressBar from 'components/progress-bar/progress-bar';
import InfoBox from 'components/info-box/info-box';
import './welcome.scss';

const Welcome = (props) => {
	const {
		isFacilitator, 
		game, 
		gameStep, 
		confirmAndContinue, 
		handleGoToPage, 
		handleLogout, 
	} = props;
	
	/* Gamestep data */
	let gameStepData = gamesData[game.type].gameSteps.find((step) => {return step.id === gameStep;});

	/* Welcome text */
	let welcomeGameStepData = gamesData[game.type].gameSteps.find((step) => {return step.id === 'welcome';});
	let welcomeText = getText(gamesData[game.type].welcomeText, game.languageId);

	/* Confirmation button data */
	let confirmBtnText = (gameStep === 'welcome' 
		? getText(generalUiTexts.start, game.languageId)
		: getText(generalUiTexts.continue, game.languageId)
	);
	let confirmBtnData = getConfirmButtonData(game.isStarted, game, gameStep, confirmBtnText, 'welcome');

	return (
		<div className="Welcome">
			{game.type !== 'safetytraining' && <Logo />}
			<ProgressBar 
				gameType={game.type}
				title={getText(welcomeGameStepData.title, game.languageId)} 
				subtitle={getText(welcomeGameStepData.subtitle, game.languageId)} 
			/>
			<Button 
				text={confirmBtnData.text}
				classes={confirmBtnData.classes}
				isDisabled={confirmBtnData.isDisabled}
				onClick={() => {
					(gameStep === 'welcome' ? confirmAndContinue(gameStep) : handleGoToPage(gameStepData.page));
				}}
			/>
			<InfoBox 
				title={getText(generalUiTexts.welcome, game.languageId)} 
				text={welcomeText}
				buttons={[{
					isDisabled: confirmBtnData.isDisabled,
					text: confirmBtnText,
					action: (gameStep === 'welcome' 
						? (game.isStarted ? confirmAndContinue : () => {return null; }) : handleGoToPage),
					params: [(gameStep === 'welcome' 
						? gameStep : gameStepData.page)],
				}]}
			/>
			{!isFacilitator && <Button text="" classes={['logout']} onClick={handleLogout} />}
		</div>
	);
};

Welcome.propTypes = {
	isFacilitator: PropTypes.bool.isRequired,
	game: PropTypes.object.isRequired,
	gameStep: PropTypes.string.isRequired,
	confirmAndContinue: PropTypes.func.isRequired,
	handleGoToPage: PropTypes.func.isRequired,
	handleLogout: PropTypes.func.isRequired
};

export default Welcome;