import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {getText} from 'helpers/language-helper';
import './reflection-topics.scss';

const ReflectionTopics = ({languageId, group, reflectionData, handleUpdateSelectedTopics}) => {
	/* Get selected topics (database) */
	let [groupSelectedTopics, setGroupSelectedTopics] = useState((group.selectedTopics ? group.selectedTopics : []));
	
	/* Get selected topics (local) */
	let [selectedTopics, setSelectedTopics] = useState(() => {
		if (group.selectedTopics) return group.selectedTopics;
		return [];
	});

	/* Update order of dirty dozen if props have changed */
	useEffect(() => {
		let updateSelectedTopics = false;
		if (group.selectedTopics) {
			if (!groupSelectedTopics) {
				setGroupSelectedTopics(group.selectedTopics);
			} else {
				if (group.selectedTopics.length !== groupSelectedTopics.length) {
					updateSelectedTopics = true;
				} else {
					group.selectedTopics.forEach((topic, index) => {
						/* Database has been updated */
						if (topic !== groupSelectedTopics[index]) updateSelectedTopics = true;
					});
				}
			}
		}
		if (updateSelectedTopics) {
			setGroupSelectedTopics(group.selectedTopics);
			setSelectedTopics(group.selectedTopics);
		}	
	}, [group.selectedTopics, selectedTopics, groupSelectedTopics]);

	/* Select / deselect topic */
	const toggleTopic = (topicId) => {
		let newSelectedTopics = JSON.parse(JSON.stringify(selectedTopics));
		let topicIndex = newSelectedTopics.indexOf(topicId);
		let updateSelectedTopics = false;

		if (topicIndex >= 0) {
			newSelectedTopics.splice(topicIndex, 1);
			updateSelectedTopics = true;
		} else {
			if (newSelectedTopics.length < reflectionData.numberOfTopicsToSelect) {
				newSelectedTopics.push(topicId);
				updateSelectedTopics = true;
			}
		}

		if (updateSelectedTopics) {
			setSelectedTopics(newSelectedTopics);
			handleUpdateSelectedTopics(newSelectedTopics);
		}
	};

	return (
		<div className="ReflectionTopics">
			{reflectionData.topics.map((topic, index) => {
				let isSelected = selectedTopics.indexOf(topic.id) >= 0;
				let isDisabled = (!isSelected && selectedTopics.length >= reflectionData.numberOfTopicsToSelect);
				return (
					<div 
						key={index} 
						className={'ReflectionTopics-topic' + (isDisabled ? ' disabled' : '')}
						onClick={() => {toggleTopic(topic.id);}}
					>
						{getText(topic.title, languageId)}
						<div className={'ReflectionTopics-toggle' + (isSelected ? ' selected' : '')}/>
					</div>
				);
			})}
		</div>
	);
};

ReflectionTopics.propTypes = {
	languageId: PropTypes.string.isRequired,
	group: PropTypes.object.isRequired,
	reflectionData: PropTypes.object.isRequired,
	handleUpdateSelectedTopics: PropTypes.func.isRequired
};

export default ReflectionTopics;