import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {dirtyDozenData} from 'data/dirty-dozen-data';
import {getText} from 'helpers/language-helper';
import ReflectionDnDItem from './reflection-dnd-item';
import './reflection-dd.scss';

const ReflectionDD = ({languageId, group, handleUpdateDirtyDozenOrder}) => {
	/* Get order of dirty dozen (database) */
	let [groupDDIds, setGroupDDIds] = useState((group.dirtyDozenIds ? group.dirtyDozenIds : null));
	
	/* Get order of dirty dozen (local) */
	let [ddIds, setDdIds] = useState(() => {
		if (group.dirtyDozenIds) return group.dirtyDozenIds;
		return dirtyDozenData.map((dd) => {return dd.id;});
	});

	/* Update order of dirty dozen if props have changed */
	useEffect(() => {
		let updateDDIds = false;
		if (group.dirtyDozenIds) {
			if (!groupDDIds) {
				setGroupDDIds(group.dirtyDozenIds);
			} else {
				if (group.dirtyDozenIds.length === groupDDIds.length) {
					group.dirtyDozenIds.forEach((ddId, index) => {
						if (ddId !== groupDDIds[index]) {
							/* Database has been updated by another group member */
							updateDDIds = true;
						}
					});
				}
			}
		}
		if (updateDDIds) {
			setGroupDDIds(group.dirtyDozenIds);
			setDdIds(group.dirtyDozenIds);
		}	
	}, [group.dirtyDozenIds, ddIds, groupDDIds]);

	/* Move DD to new place */
	const moveItem = (dragIndex, hoverIndex) => {
		const dragDDId = ddIds[dragIndex];
		let newDdIds = JSON.parse(JSON.stringify(ddIds));
		newDdIds.splice(dragIndex, 1);
		newDdIds.splice(hoverIndex, 0, dragDDId);
		setDdIds(newDdIds);
	};

	/* Update dirty dozen order in database */
	const updateDirtyDozenOrder = () => {
		handleUpdateDirtyDozenOrder(ddIds);
	};

	return (
		<div className="ReflectionDD">
			{ddIds.map((ddId, index) => {
				let ddData = dirtyDozenData.find((dd) => {return dd.id === ddId;});
				return (
					<div key={ddId} className="ReflectionDD-dd">
						{index < 3 && <div className="ReflectionDD-position"><span>{(index + 1)}</span></div>}
						<ReflectionDnDItem 
							id={ddId} 
							index={index} 
							text={getText(ddData.title, languageId)} 
							moveItem={moveItem}
							updateOrder={updateDirtyDozenOrder}
						/>
					</div>
				);
			})}
		</div>
	);
};

ReflectionDD.propTypes = {
	languageId: PropTypes.string.isRequired,
	group: PropTypes.object.isRequired,
	handleUpdateDirtyDozenOrder: PropTypes.func.isRequired
};

export default ReflectionDD;