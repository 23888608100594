import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {getText} from 'helpers/language-helper';
import ReflectionDnDItem from './reflection-dnd-item';
import './reflection-fp.scss';

const ReflectionFP = ({languageId, group, focusPoints, handleUpdateFocusPointsOrder}) => {
	/* Get order of focus points (database) */
	let [groupFPIds, setGroupFPIds] = useState((group.focusPointsIds ? group.focusPointsIds : null));
	
	/* Get order of focus points (local) */
	let [fpIds, setFpIds] = useState(() => {
		if (group.focusPointsIds) return group.focusPointsIds;
		return focusPoints.map((fp) => {return fp.id;});
	});

	/* Update order of focus points if props have changed */
	useEffect(() => {
		let updateFPIds = false;
		if (group.focusPointsIds) {
			if (!groupFPIds) {
				setGroupFPIds(group.focusPointsIds);
			} else {
				if (group.focusPointsIds.length === groupFPIds.length) {
					group.focusPointsIds.forEach((ddId, index) => {
						if (ddId !== groupFPIds[index]) {
							/* Database has been updated by another group member */
							updateFPIds = true;
						}
					});
				}
			}
		}
		if (updateFPIds) {
			setGroupFPIds(group.focusPointsIds);
			setFpIds(group.focusPointsIds);
		}	
	}, [group.focusPointsIds, fpIds, groupFPIds]);

	/* Move focus point to new place */
	const moveItem = (dragIndex, hoverIndex) => {
		const dragFPId = fpIds[dragIndex];
		let newFPIds = JSON.parse(JSON.stringify(fpIds));
		newFPIds.splice(dragIndex, 1);
		newFPIds.splice(hoverIndex, 0, dragFPId);
		setFpIds(newFPIds);
	};

	/* Update dirty dozen order in database */
	const updateFocusPointsOrder = () => {
		handleUpdateFocusPointsOrder(fpIds);
	};

	return (
		<div className="ReflectionFP">
			{fpIds.map((fpId, index) => {
				let fpData = focusPoints.find((fp) => {return fp.id === fpId;});
				return (
					<div key={fpId} className="ReflectionFP-fp">
						{index < 3 && <div className="ReflectionFP-position"><span>{(index + 1)}</span></div>}
						<ReflectionDnDItem 
							id={fpId} 
							index={index} 
							text={getText(fpData.title, languageId)} 
							moveItem={moveItem}
							updateOrder={updateFocusPointsOrder}
						/>
					</div>
				);
			})}
		</div>
	);
};

ReflectionFP.propTypes = {
	languageId: PropTypes.string.isRequired,
	group: PropTypes.object.isRequired,
	focusPoints: PropTypes.array.isRequired,
	handleUpdateFocusPointsOrder: PropTypes.func.isRequired
};

export default ReflectionFP;