import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {gamesData} from 'data/games-data';
import {dirtyDozenData} from 'data/dirty-dozen-data';
import {getCrewMemberEffects} from 'helpers/group-crew-helper';
import CrewFeedback from './crew-feedback';
import CrewFeedbackHeli from './crew-feedback-heli';
import CrewFeedbackST from './crew-feedback-st';
import InfoPopup from 'components/info-popup/info-popup';
import DirtyDozenInfoPopup from 'components/dirty-dozen-info-popup/dirty-dozen-info-popup';

class CrewFeedbackController extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showInfoPopup: false,
			dirtyDozenId: null
		};
	}

	componentDidMount = () => {
		if (!this.props.isFacilitator) {
			const gameStepData = gamesData[this.props.game.type].gameSteps.find((step) => {
				return step.id === 'crew-feedback';
			});
			if (gameStepData.infoPopup && gameStepData.infoPopup.autoShow === true) {
				this.timeout = setTimeout(() => {
					this.setState({ showInfoPopup: true });
				}, 500);
			}
		}
	};

	/**
	 * Hide / show info popup
	 */
	toggleInfoPopup = () => {
		let showInfoPopup = !this.state.showInfoPopup;
		this.setState({ showInfoPopup: showInfoPopup });
	};

	/**
	 * ST: Hide / show dirty dozen info popup
	 * @param {number} dirtyDozenId 
	 */
	toggleDirtyDozenPopup = (dirtyDozenId) => {
		this.setState({dirtyDozenId});
	};

	/**
	 * CRM: Select crew member in slot
	 * @param {number} slotId 
	 */
	handleSelectSlotCRM = (slotId) => {
		if (!slotId) {
			this.props.updateGroup({activeFeedbackCrewId: null});
		} else {
			let selectedCrew = JSON.parse(JSON.stringify(this.props.group.selectedCrew));
			let riskValue = JSON.parse(JSON.stringify(this.props.group.riskValue));
			let dirtyDozenValues = JSON.parse(JSON.stringify(this.props.group.dirtyDozenValues));
	
			/* Get index of selected crew member in selectedCrew array */
			let selectedCrewMemberIndex = selectedCrew.findIndex((crewMember) => {
				return (crewMember.slotId === slotId);
			});
			if (selectedCrewMemberIndex >= 0) {
				/* Flag crew member as active (update database) */
				this.props.updateGroup({activeFeedbackCrewId: selectedCrew[selectedCrewMemberIndex].id});
	
				/* Apply effects if they have not already been applied (update database) */
				if (
					!selectedCrew[selectedCrewMemberIndex].hasOwnProperty('effectApplied') ||
					selectedCrew[selectedCrewMemberIndex].effectApplied === false
				) {
					let personData = gamesData[this.props.game.type].crewData.availableCrew.filter((crew) => {
						return crew.id === selectedCrew[selectedCrewMemberIndex].id;
					})[0];
	
					/* Get effects */
					let effects = getCrewMemberEffects(personData, selectedCrew);
	
					/* Apply effects */
					effects.forEach((effect) => {
						if (effect.type === 'dirtyDozen') {
							let dirtyDozenIndex = dirtyDozenData.findIndex((dd) => {
								return dd.id === effect.dirtyDozenId;
							});
							dirtyDozenValues[dirtyDozenIndex] = dirtyDozenValues[dirtyDozenIndex] + effect.value;
						}
						if (effect.type === 'risk') {
							riskValue = riskValue + effect.value;
						}
					});
					
					selectedCrew[selectedCrewMemberIndex].effectApplied = true;
					this.props.updateGroup({
						dirtyDozenValues: dirtyDozenValues, 
						riskValue: riskValue, 
						selectedCrew: selectedCrew});
				}
			}	
		}
	};

	/**
	 * CRMH: Select crew member in slot
	 * @param {number} slotId 
	 */
	handleSelectSlotCRMH = (slotId) => {
		if (!slotId) {
			this.props.updateGroup({activeFeedbackCrewId: null});
		} else {
			let selectedCrew = JSON.parse(JSON.stringify(this.props.group.selectedCrew));
	
			/* Get index of selected crew member in selectedCrew array */
			let selectedCrewMemberIndex = selectedCrew.findIndex((crewMember) => {
				return (crewMember.slotId === slotId);
			});
			if (selectedCrewMemberIndex >= 0) {
				/* Flag crew member as active (update database) */
				this.props.updateGroup({activeFeedbackCrewId: selectedCrew[selectedCrewMemberIndex].id});
	
				/* Mark effects as applied if they have not already been applied (update database) */
				if (
					!selectedCrew[selectedCrewMemberIndex].hasOwnProperty('effectApplied') ||
					selectedCrew[selectedCrewMemberIndex].effectApplied === false
				) {
					selectedCrew[selectedCrewMemberIndex].effectApplied = true;
					this.props.updateGroup({selectedCrew: selectedCrew});
				}
			}	
		}
	};

	/**
	 * ST: Select crew member in slot
	 * @param {number} slotId 
	 */
	handleSelectSlotST = (slotId) => {
		if (!slotId) {
			this.props.updateGroup({activeFeedbackCrewId: null});
		} else {
			/* Get index of selected crew member in selectedCrew array */
			let selectedCrew = [...this.props.group.selectedCrew];
			let selectedCrewMemberIndex = selectedCrew.findIndex((crewMember) => {
				return (crewMember.slotId === slotId);
			});
			if (selectedCrewMemberIndex >= 0) {
				/* Flag crew member as active (update database) */
				this.props.updateGroup({activeFeedbackCrewId: selectedCrew[selectedCrewMemberIndex].id});
	
				/* Apply effects if they have not already been applied (update database) */
				if (
					!selectedCrew[selectedCrewMemberIndex].hasOwnProperty('effectApplied') ||
					selectedCrew[selectedCrewMemberIndex].effectApplied === false
				) {

					/* Get selected crew member dirty dozen */
					let dirtyDozenIds = (selectedCrew[selectedCrewMemberIndex].hasOwnProperty('dirtyDozenIds') 
						? selectedCrew[selectedCrewMemberIndex].dirtyDozenIds 
						: []
					);
					/* Get static crew member data */
					const crewMemberData = gamesData[this.props.game.type].crewData.availableCrew.find((c) => {
						return c.id === selectedCrew[selectedCrewMemberIndex].id;
					});

					/* Update selected crew member dirty dozens */
					if (crewMemberData && crewMemberData.dirtyDozenIds) {
						crewMemberData.dirtyDozenIds.forEach((ddId) => {
							if (dirtyDozenIds.indexOf(ddId) < 0) dirtyDozenIds.push(ddId);
						});
					}
					selectedCrew[selectedCrewMemberIndex].effectApplied = true;
					selectedCrew[selectedCrewMemberIndex].dirtyDozenIds = dirtyDozenIds;

					/* Update group */
					this.props.updateGroup({selectedCrew: selectedCrew});
				}
			}	
		}
	};

	/**
	 * Render component
	 */
	render() {
		let affectedDirtyDozens = [];
		let riskIsAffected = false;

		/* CRM: Get affected dirty dozens (they glow) */
		if (this.props.game.type === 'crm-aeroplanes') {
			if (this.props.group.activeFeedbackCrewId) {
				let personData = gamesData[this.props.game.type].crewData.availableCrew.find((crew) => {
					return crew.id === this.props.group.activeFeedbackCrewId;
				});
				if (personData) {
					let effects = getCrewMemberEffects(personData, this.props.group.selectedCrew);
					
					effects.forEach((effect) => {
						if (effect.type === 'dirtyDozen') {affectedDirtyDozens.push(effect.dirtyDozenId);}
						if (effect.type === 'risk') riskIsAffected = true;
					});
				}
			}
		}

		/* Get component */
		let CrewFeedbackComponent = CrewFeedback;
		if (this.props.game.type === 'crm-helicopters') CrewFeedbackComponent = CrewFeedbackHeli;
		if (this.props.game.type === 'safetytraining') CrewFeedbackComponent = CrewFeedbackST;


		return (
			<React.Fragment>
				<CrewFeedbackComponent
					game={this.props.game}
					riskIsAffected={riskIsAffected}
					group={this.props.group}
					affectedDirtyDozens={affectedDirtyDozens}
					handleGoToPage={this.props.handleGoToPage}
					handleSelectSlotCRM={this.handleSelectSlotCRM}
					handleSelectSlotCRMH={this.handleSelectSlotCRMH}
					handleSelectSlotST={this.handleSelectSlotST}
					confirmAndContinue={this.props.confirmAndContinue}
					toggleInfoPopup={this.toggleInfoPopup}
					toggleDirtyDozenPopup={this.toggleDirtyDozenPopup}
				/>
				{this.state.showInfoPopup && <InfoPopup
					canToggle={true}
					game={this.props.game}
					gameStep={'crew-feedback'}
					toggleInfoPopup={this.toggleInfoPopup}
					handleLogout={null}
				/>}
				{this.state.dirtyDozenId && 
					<DirtyDozenInfoPopup 
						dirtyDozenId={this.state.dirtyDozenId} 
						languageId={this.props.game.languageId}
						togglePopup={this.toggleDirtyDozenPopup}
					/>
				}
			</React.Fragment>
		);
	}
}

CrewFeedbackController.propTypes = {
	isFacilitator: PropTypes.bool.isRequired,
	game: PropTypes.object.isRequired,
	group: PropTypes.object.isRequired,
	handleGoToPage: PropTypes.func.isRequired,
	updateGroup: PropTypes.func.isRequired,
	confirmAndContinue: PropTypes.func.isRequired,
};

export default CrewFeedbackController;
