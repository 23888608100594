import React from 'react';
import PropTypes from 'prop-types';
import {gamesData} from 'data/games-data';
import {generalUiTexts} from 'data/ui-texts';
import {getText} from 'helpers/language-helper';
import InfoBox from 'components/info-box/info-box';

const InfoPopup = ({canToggle, gameStep, game, toggleInfoPopup, handleLogout}) => {
	let popupTitle = '';
	let popupText = '';

	const gameStepData = gamesData[game.type].gameSteps.find((step) => {return step.id === gameStep;});
	if (gameStepData && gameStepData.infoPopup) {
		popupTitle = getText(gameStepData.infoPopup.title, game.languageId);
		if (gameStepData.infoPopup.isScenarioSpecific === true) {
			const scenariodata = gamesData[game.type].scenarios.find((sc) => {return sc.id === game.scenario;});
			popupText = scenariodata[gameStepData.infoPopup.contentId];
		} else {
			popupText = gamesData[game.type][gameStepData.infoPopup.contentId];
		}
		if (!(typeof popupText === 'object')) {
			console.error('error: popuptext is not an object ', gameStep, gameStepData.infoPopup.contentId);
		} else {
			popupText = getText(popupText, game.languageId);
		}
	}
	
	return (
		<InfoBox
			isFullscreen={true}
			type="briefing"
			title={popupTitle}
			text={popupText}
			buttons={[{ 
				isDisabled: (!canToggle),
				text: (canToggle 
					? getText(generalUiTexts.ok, game.languageId) 
					: getText(generalUiTexts.waiting, game.languageId)
				), 
				action: toggleInfoPopup, 
				params: [] 
			}]}
			handleClose={(canToggle ? () => {toggleInfoPopup();} : null)}
			handleLogout={handleLogout}
		/>
	);
};


InfoPopup.propTypes = {
	canToggle: PropTypes.bool.isRequired,
	gameStep: PropTypes.string.isRequired,
	game: PropTypes.object.isRequired,
	toggleInfoPopup: PropTypes.func.isRequired,
	handleLogout: PropTypes.func
};

export default InfoPopup;
