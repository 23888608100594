import React from 'react';
import PropTypes from 'prop-types';
import {loginUiTexts} from 'data/ui-texts';
import {getText} from 'helpers/language-helper';
import Button from 'components/button/button';

const LoginFacilitator = (props) => {
	let {
		isLoading,
		showResetPasswordBox,
		passwordIsReset,
		invalidInputEmail,
		invalidInputPassword,
		languageId,
		email,
		password,
		errMsg,
		handleInput,
		handleLogin,		
		toggleResetPasswordBox,
		handleResetPassword
	} = props;

	if (isLoading && !showResetPasswordBox) {
		return (
			<div className="LoginFacilitator">
				<div className="Login-loadingWrapper" />
			</div>
		);
	}

	if (showResetPasswordBox) {
		return (
			<React.Fragment>
				<div className="LoginFacilitator">
					{!passwordIsReset && 
					<form className="Login-form reset" onSubmit={() => {handleResetPassword();}}>
						<p className="Login-info">{getText(loginUiTexts.resetPassword, languageId)}</p>
						<input
							className={invalidInputEmail ? ' invalid' : ''}
							name="email"
							type="text"
							placeholder={getText(loginUiTexts.email, languageId)} 
							autoComplete="section-teacherlogin email"
							value={email ? email : ''} 
							onChange={(event)=>{handleInput(event);}}
						/>
						<p className="Login-errorMessage">{errMsg}</p> 
						
						<Button 
							text={getText(loginUiTexts.reset, languageId)} 
							classes={['login']} 
							isLoading={isLoading}
							onClick={() => {handleResetPassword();}}
						/>
					</form>}
					{passwordIsReset && 
						<form className="Login-form feedback">
							<p className="Login-info">{getText(loginUiTexts.passwordResetFeedback, languageId)}</p>
							<p 
								className="Login-infoLink"
								onClick={() => {toggleResetPasswordBox();}} 
							>{getText(loginUiTexts.backToLogin, languageId)}</p>
						</form>}
				</div>
				<div 
					onClick={() => {toggleResetPasswordBox();}} 
					className="Login-resetPasswordBtn"
				>{getText(loginUiTexts.backToLogin, languageId)}</div>
			</React.Fragment>
		);
	}

	return (
		<React.Fragment>
			<div className="LoginFacilitator">
				<form className="Login-form" onSubmit={handleLogin}>
					<input
						className={invalidInputEmail ? ' invalid' : ''}
						name="email"
						type="text"
						placeholder={getText(loginUiTexts.email, languageId)} 
						autoComplete="section-teacherlogin email"
						value={email ? email : ''} 
						onChange={(event)=>{handleInput(event);}}
					/>
					<input
						className={invalidInputPassword ? ' invalid' : ''}
						name="password"
						type="password"
						placeholder={getText(loginUiTexts.password, languageId)}
						autoComplete="section-teacherlogin password"
						value={password ? password : ''} 
						onChange={(event)=>{handleInput(event);}}
					/>
					<p className="Login-errorMessage">{errMsg}</p> 
					<Button text={getText(loginUiTexts.login, languageId)} classes={['login']} onClick={handleLogin} />
				</form>
			</div>
			<div 
				onClick={() => {toggleResetPasswordBox();}} 
				className="Login-resetPasswordBtn"
			>{getText(loginUiTexts.forgotPassword, languageId)}</div>
		</React.Fragment>
	);
};

LoginFacilitator.propTypes = {
	isLoading: PropTypes.bool.isRequired,
	showResetPasswordBox: PropTypes.bool.isRequired,
	passwordIsReset: PropTypes.bool.isRequired,
	invalidInputEmail: PropTypes.bool.isRequired,
	invalidInputPassword: PropTypes.bool.isRequired,
	languageId: PropTypes.string.isRequired,
	email: PropTypes.string,
	password: PropTypes.string,
	errMsg: PropTypes.string,
	handleLogin: PropTypes.func.isRequired,
	handleInput: PropTypes.func.isRequired,
	toggleResetPasswordBox: PropTypes.func.isRequired,
	handleResetPassword: PropTypes.func.isRequired,
};

export default LoginFacilitator;