import React from 'react';
import PropTypes from 'prop-types';
import { gamesData} from 'data/games-data';
import { generalUiTexts } from 'data/ui-texts';
// import {renderMarkdown, addSToName} from 'helpers/text-helper';
import {renderMarkdown } from 'helpers/text-helper';
import {getText} from 'helpers/language-helper';
import './crew-card-back-heli.scss';

const CrewCardBackHeli = ({languageId, cardData, layout, onClick}) => {
	const crewData = gamesData['crm-helicopters'].crewData;
	function renderInfo() {
		return (
			<div className="CrewCardBackHeli-info">
				<div className="CrewCardBackHeli-name"><span>{getText(cardData.name, languageId)}</span></div>
				<div className="CrewCardBackHeli-title">{getText(cardData.title, languageId)}</div>
				{(layout !== 'crewFeedback' && layout !== 'crewFeedbackWhite') && 
					<div className="CrewCardBackHeli-age">
						<span>{getText(generalUiTexts.age, languageId)}: </span>{cardData.age}
					</div>}
			</div>
		);
	}

	return (
		<div className={'CrewCardBackHeli ' + layout} onClick={onClick}>
			<div className="CrewCardBackHeli-top">
				{(layout === 'crewPopup' || layout === 'crewFeedbackSelected') && 
					<div className="CrewCardBackHeli-description">
						{renderMarkdown(getText(cardData.description, languageId))}
					</div>}
				{/* {layout === 'crewFeedbackSelected' && 
					<div className="CrewCardBackHeli-description"><p>{
						getText(generalUiTexts.heliCrewCardBackText, languageId)
							.replace(/%names%/g, addSToName(getText(cardData.name, languageId)))
					}:</p></div>} */}
				{cardData.experiences.map((exp, index) => {
					let isPrimary = true;
					if (cardData.slotExperienceId && cardData.slotExperienceId !== exp.id) isPrimary = false;
					return (
						<div key={index} className={'CrewCardBackHeli-experience' + (isPrimary ? '' : ' secondary')}>
							<div className="CrewCardBackHeli-experienceLabel">
								{getText(crewData.experienceTypes[exp.id].label, languageId)}
							</div>
							<div className="CrewCardBackHeli-experienceScale">
								{[...Array(5)].map((_, index) =>  {
									let value = index + 1;
									let className = 'CrewCardBackHeli-experienceScaleNumber';
									if (exp.value === value) className = className + ' selected';						
									return <div key={index} className={className}><span>{value}</span></div>;
								})}
							</div>
						</div>
					);
				})}
				<div className="CrewCardBackHeli-issues">
					{cardData.issues.map((issue, index) => {
						return (
							<div key={index} className="CrewCardBackHeli-issue">
								<span>{getText(crewData.issueTypes[issue], languageId)}</span>
							</div>
						);
					})}
				</div>
			</div>
			{(layout === 'crewFeedbackSelected' || layout === 'crewPopup') && 
						<div className={'CrewCardBackHeli-bottom ' + cardData.type}>{renderInfo()}</div>}
		</div>
	);
};

CrewCardBackHeli.defaultProps = {
	layout: 'default',
	onClick: null
};

CrewCardBackHeli.propTypes = {
	languageId: PropTypes.string.isRequired,
	cardData: PropTypes.object.isRequired,
	layout: PropTypes.string,
	onClick: PropTypes.func
};

export default CrewCardBackHeli;