import React from 'react';

function fillMeter(maxPoints, currentPoints, className, animate = false, statDiff = 0) {
	let animateIndexMin = maxPoints;
	let animateIndexMax = -1;
	if (animate && statDiff > 0) {
		animateIndexMax = currentPoints - 1;
		animateIndexMin = currentPoints - statDiff;
	}
	if (animate && statDiff < 0) {
		animateIndexMin = currentPoints;
		animateIndexMax = currentPoints - (statDiff + 1);
	}

	return (
		[...Array(maxPoints)].map((_, i) => {
			return (
				<div
					key={i}
					className={className + 
						(i < currentPoints ? ' filled' : '') +
						(i === (currentPoints - 1) ? ' big' : '') +
						((i >= animateIndexMin && i <= animateIndexMax) ? ' animate' : '')
					}
				/>
			);
		})
	);
};

export {fillMeter};