import React from 'react';
import PropTypes from 'prop-types';
import { settingsUiTexts } from 'data/ui-texts';
import {getText} from 'helpers/language-helper';
import Button from 'components/button/button';
import './delete-game-popup.scss';

const DeleteGamePopup = ({isDeletingGame, languageId, gameId, toggleDeleteGamePopup, handleDeleteGame}) => {
	const popupText = JSON.parse(JSON.stringify(getText(settingsUiTexts.deleteGamePopup.areYouSure, languageId)))
		.replace('%game%', gameId);

	return (
		<div className="DeleteGamePopup">
			<div className="DeleteGamePopup-content">
				<div className="DeleteGamePopup-title">
					{getText(settingsUiTexts.deleteGamePopup.title, languageId)}
				</div>
				<div className="DeleteGamePopup-closeBtn" onClick={() => {toggleDeleteGamePopup(null);}}/>
				<div className="DeleteGamePopup-text">{popupText}?</div>
				<div className="DeleteGamePopup-buttons">
					<Button
						classes={['delete']}
						isLoading={isDeletingGame}
						text={getText(settingsUiTexts.deleteGamePopup.delete, languageId)}
						onClick={() => {handleDeleteGame(gameId);}}
					/>
					<Button
						classes={['cancel']}
						text={getText(settingsUiTexts.deleteGamePopup.cancel, languageId)}
						onClick={() => {toggleDeleteGamePopup(null);}}
					/>
				</div>
			</div>
		</div>
	);
};

DeleteGamePopup.propTypes = {
	isDeletingGame: PropTypes.bool.isRequired,
	languageId: PropTypes.string.isRequired,
	gameId: PropTypes.string,
	toggleDeleteGamePopup: PropTypes.func.isRequired,
	handleDeleteGame: PropTypes.func.isRequired,
};

export default DeleteGamePopup;
