import React from 'react';
import {gamesData} from 'data/games-data';
import { ReactComponent as GoldMedal } from 'assets/images/gold-medal.svg';
import PropTypes from 'prop-types';

const Medal = ({ game, gameStep, groups, winner }) => {
	const gameSteps = gamesData[game.type].gameSteps;
	let gameStepData = gameSteps.find((step) => {return step.id === gameStep;});

	let showMedal = (gameStepData.showMedal ? gameStepData.showMedal : false);
	if (groups.length < 2) showMedal = false;
	if (showMedal) {
		// Only show medal if all groups have caught up to this game step
		let currentGameStepIndex = gameSteps.findIndex((step) => {return step.id === gameStep;});
		groups.forEach((group) => {
			let groupGameStepIndex = gameSteps.findIndex((step) => {return step.id === group.gameStep;});
			if (groupGameStepIndex < currentGameStepIndex) showMedal = false;
		});
	}

	if (showMedal && winner) {
		return <GoldMedal className="goldMedal"/>;
	}
	return null;
};


Medal.defaultProps = {
	winner: false
};


Medal.propTypes = {
	game: PropTypes.object.isRequired,
	gameStep: PropTypes.string.isRequired,
	groups: PropTypes.array.isRequired,
	winner: PropTypes.bool.isRequired,
};

export default Medal;
