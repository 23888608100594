import React, {Component} from 'react';
import PropTypes from 'prop-types';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import appConfig from 'config/app.config';
import Settings from './settings';
import CreateGame from './create-game';

class SettingsController extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: false,
			isCreatingGame: false,
			isDeletingGame: false,
			showCreateGamePage: false,
			showDeleteGamePopup: false,
			gameId: null,
			groups: [],
			errMsg: null
		};
	}

	/**
	 * Change user language
	 * @param {string} languageId 
	 */
	handleChangeUserLanguage = (languageId) => {
		this.props.updateUser({languageId: languageId});
	};

	/**
	 * Open / close create/edit game page
	 */
	toggleCreateGamePage = (gameId = null) => {
		if (this.state.showCreateGamePage) {
			/* Return to overview page */
			this.setState({showCreateGamePage: false, gameId: null, groups: []});	
		} else {
			if (!gameId) {
				/* Create game */
				this.setState({showCreateGamePage: true});
			} else {
				/* Edit game */
				this.setState({showCreateGamePage: true, gameId: gameId}, () => {
					const db = firebase.firestore();
					db.collection(appConfig.groupsDbName).where('gameId', '==', gameId).get().then((docs) => {
						let groups = [];
						docs.forEach((doc) => {
							let groupObj = doc.data();
							groupObj.id = doc.id;
							groups.push(groupObj);
						});
						this.setState({groups});
					});
				});
			}
		}
	};

	/**
	 * Toggle delete game popup
	 * @param {string} gameId 
	 */
	toggleDeleteGamePopup = (gameId) => {
		let showDeleteGamePopup = (gameId ? true : false);
		this.setState({showDeleteGamePopup, gameId});
	};

	/**
	 * Create game
	 */
	createGame = (companyId, gameId, gameTitle, gameDate, gameScenario, 
		gameDecisionTool, gameCommunicationTool, gameExerciseTool, languageId, gameGroups
	) => {
		if (this.state.isLoading || this.state.isCreatingGame) return;
		this.setState({isCreatingGame: true}, () => {
			this.props.createGame(
				companyId,
				gameId,
				gameTitle, 
				gameDate, 
				gameScenario, 
				gameDecisionTool, 
				gameCommunicationTool, 
				gameExerciseTool,
				languageId,
				gameGroups
			).then((response) => {
				if (response.status === 'success') {
					if (gameId) {
						this.setState({gameId: response.gameId, isCreatingGame: false});
					} else {
						this.setState({isCreatingGame: false, showCreateGamePage: false});
					}
				} else {
					console.error(response);
					this.setState({isCreatingGame: false});
				}
			}, (error) => {
				this.setState({isCreatingGame: false});
				console.error(error);
			});
		});
	};

	/**
	 * Delete game
	 * @param {string} gameId 
	 */
	handleDeleteGame = (gameId) => {
		if (this.state.isLoading || this.state.isDeletingGame) return;
		this.setState({isDeletingGame: true}, () => {
			this.props.deleteGame(gameId).then(() => {
				this.toggleDeleteGamePopup(null);
				this.setState({isDeletingGame: false});
			});
		});
	};

	/**
	 * Render component
	 */
	render() {
		/* Create/edit game page */
		if (this.state.showCreateGamePage && this.props.userData !== null && this.props.companiesData.length > 0) {
			let game = (this.state.gameId 
				? this.props.games.find((g) => {return g.id === this.state.gameId;})
				: null
			);
			return (
				<CreateGame 
					isCreatingGame={this.state.isCreatingGame}
					userData={this.props.userData}
					companiesData={this.props.companiesData}
					gameType={this.props.gameType}
					game={game}
					users={this.props.users}
					groups={this.state.groups}
					errMsg={this.state.errMsg}
					toggleCreateGamePage={this.toggleCreateGamePage}
					createGame={this.createGame}
					handleLogout={this.props.handleLogout}
				/>
			);
		}

		/* Settings page */
		return (
			<Settings
				showAllGames={this.props.showAllGames}
				isDeletingGame={this.state.isDeletingGame}
				showDeleteGamePopup={this.state.showDeleteGamePopup}
				isAdmin={this.props.userData ? this.props.userData.isAdmin : false}
				languageId={this.props.userData.languageId}
				userId={this.props.userData ? this.props.userData.id : ''}
				email={this.props.userData ? this.props.userData.email : ''} 
				gameId={this.state.gameId}
				companiesData={this.props.companiesData}
				gameType={this.props.gameType}
				games={this.props.games}
				toggleShowAllGames={this.props.toggleShowAllGames}
				handleChangeUserLanguage={this.handleChangeUserLanguage}
				handleGoToGame={this.props.handleGoToGame} 
				toggleCreateGamePage={this.toggleCreateGamePage}
				toggleDeleteGamePopup={this.toggleDeleteGamePopup}
				handleDeleteGame={this.handleDeleteGame}
				handleResetGame={this.props.handleResetGame}
				handleLogout={this.props.handleLogout} 
				initGame={this.props.initGame}
			/>
		);
	}
}

SettingsController.propTypes = {
	showAllGames: PropTypes.bool.isRequired,
	userData: PropTypes.object,
	companiesData: PropTypes.array.isRequired,
	gameType: PropTypes.string.isRequired,
	games: PropTypes.array.isRequired,
	users: PropTypes.array.isRequired,
	updateUser: PropTypes.func.isRequired,
	toggleShowAllGames: PropTypes.func.isRequired,
	handleGoToGame: PropTypes.func.isRequired,
	handleResetGame: PropTypes.func.isRequired,
	createGame: PropTypes.func.isRequired,
	initGame: PropTypes.func.isRequired,
	deleteGame: PropTypes.func.isRequired,
	handleLogout: PropTypes.func.isRequired
};

export default SettingsController;
