import React from 'react';
import PropTypes from 'prop-types';
import {useDrop} from 'react-dnd';
import './game-board-st-dnd-container.scss';

const GameBoardSTDndContainer = (props) => {
	let {
		gameType, 
		containerId, 
		cardTypes,
		cardTypesAccepted, 
		handleFindCard, 
		handleMoveCard, 
		children
	} = props;

	// eslint-disable-next-line no-unused-vars
	const [{isOver, canDrop, isMatch}, drop] = useDrop({
		accept: cardTypes,
		drop(item, monitor) {
			if (handleFindCard && handleMoveCard) {
				let fromContainerId = handleFindCard(item.id);
				
				/* Ignore none-shallow drops */
				if (containerId === 'available' && !monitor.isOver({shallow: true})) return;
				
				/* Ignore hovering over itself */
				if (containerId === fromContainerId) return;

				/* Ignore mismatch between cardType and card types accepted by container */
				if (cardTypesAccepted.indexOf(item.type) < 0) return;

				/* Move card */
				handleMoveCard(item.id, containerId);
			}
		},
		collect: (monitor) => {
			return {
				isOver: monitor.isOver(),
				canDrop: monitor.canDrop(),
				isMatch: (
					monitor.getItem() && cardTypesAccepted.indexOf(monitor.getItem().type) >= 0 ? true : false
				)
			};
		}
	});
	let className = 'GameBoardSTDndContainer GameBoardSTDndContainer--' + containerId + 
		' GameBoardSTDndContainer--' + gameType;
	// if (canDrop) className = className + ' GameBoardSTDndContainer--dragging';
	if (isOver && containerId !== 'available') {
		className = className + ' ' + (isMatch ? 'green' : 'red');
	}
	return <div id={containerId} ref={drop} className={className}>{children}</div>;
};

GameBoardSTDndContainer.propTypes = {
	gameType: PropTypes.string.isRequired,
	containerId: PropTypes.oneOfType([
		PropTypes.string.isRequired,
		PropTypes.number.isRequired
	]),
	cardTypes: PropTypes.array.isRequired,
	cardTypesAccepted: PropTypes.array.isRequired,
	handleFindCard: PropTypes.func.isRequired,
	handleMoveCard: PropTypes.func.isRequired,
	children: PropTypes.oneOfType([
		PropTypes.array,
		PropTypes.object,
	]),
};


export default GameBoardSTDndContainer;