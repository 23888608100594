import { generalUiTexts } from 'data/ui-texts';
import { gamesData } from 'data/games-data';
import {getText} from 'helpers/language-helper';

function getConfirmButtonData(conditionsMet, game, gameStep, text = null, page = null) {
	/* Special case: welcome page during game */
	if (gameStep !== 'welcome' && page === 'welcome') {
		let confirmBtnData = {
			isDisabled: false,
			classes: ['next'],
			text: getText(generalUiTexts.continue, game.languageId)
		};
		return confirmBtnData;
	}


	/* Default (waiting for facilitator) */
	let confirmBtnData = {
		isDisabled: true,
		classes: ['next', 'waiting'],
		text: getText(generalUiTexts.waiting, game.languageId)
	};



	/* Game is paused */
	if (game.isPaused) {
		confirmBtnData.classes = ['paused'];
		confirmBtnData.text = getText(generalUiTexts.paused, game.languageId);
		return confirmBtnData;
	}

	/* Conditions not met */
	if (!conditionsMet) {
		confirmBtnData.classes = ['next'];
		confirmBtnData.text = getText(generalUiTexts.confirm, game.languageId);
		if (text) confirmBtnData.text = text;
		return confirmBtnData;
	}

	/* Game is not paused & conditions are met, check if waiting for facilitator to unlock */
	const gameSteps = gamesData[game.type].gameSteps;
	let indexOfGameStep = gameSteps.findIndex((step) => {return step.id === gameStep;});
	if (indexOfGameStep >= 0 && indexOfGameStep + 1 < gameSteps.length) {
		/* There are more game steps */
		if (game.gamePhase >= gameSteps[indexOfGameStep + 1].phase) {
			/* The phase of the next game step is open */
			if (
				!gameSteps[indexOfGameStep].hasOwnProperty('nextBtnCondition') ||
				!gameSteps[indexOfGameStep].nextBtnCondition ||
				game[gameSteps[indexOfGameStep].nextBtnCondition] === true
			) {
				/* No special conditions / special conditions are met */
				confirmBtnData.isDisabled = false;
				confirmBtnData.classes = ['next'];
				confirmBtnData.text = getText(generalUiTexts.confirm, game.languageId);
				if (text) confirmBtnData.text = text;
			}
		}
	}
	return confirmBtnData;
};

export {getConfirmButtonData};
