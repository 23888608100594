/**
	* Create the html elements for printing info about a game
	* @param {string} facilitatorEmail
	* @param {string} sessionDate
	* @param {string} groups
	* @param {object} companyData
  */
function handlePrintGameInfo(gameTitle, facilitatorEmail, sessionDate, groups, companyData) {
	let html = [
		'<div class="game-info">Facilitator email: ' + facilitatorEmail + '</div>',
		'<div class="game-info">Scheduled start date: ' + sessionDate + '</div>',
	];
	groups.forEach((group, index) => {
		html.push('<div class="game-info">Group ' + (index + 1) + ':</div>');
		group.players.forEach((player) => {
			if (companyData.players && companyData.players.some((p) => {return p.id === player.id;})) {
				html.push('<div>Player: ' + player.name + ' // ' + player.id + '</div>');
			}
		});
	});
	printHelper(gameTitle, html, 'gameInfo');
}

/**
 * Opens new tab with print content and triggers the print window
 * @param {string} html
 * @param {string} type
 * @param {bool} pageBreak
 */
function printHelper(documentTitle, html, type) {
	var myWindow = window.open();
	myWindow.document.write('<html><head>');
	myWindow.document.write('<title>' + documentTitle + '</title>');
	myWindow.document.write('<link href="https://fonts.googleapis.com/css?family=Open+Sans:400,700" rel="stylesheet">');
	myWindow.document.write('<link href="/print.css" rel="stylesheet">');
	myWindow.document.write('</head>');
	myWindow.document.write('<body>');
	myWindow.document.write('<table class="content content--' + type + '">');
	myWindow.document.write('<tbody><tr><td>');
	html.forEach((content) => {myWindow.document.write(content);});
	myWindow.document.write('</td></tr>');
	myWindow.document.write('<tfoot><tr><td><div class="footer-space"></div></td></tr></tfoot>');
	myWindow.document.write('</table>');
	myWindow.document.write('</body>');
	myWindow.document.write('</html>');
	myWindow.document.close();
	myWindow.focus();
	myWindow.addEventListener('load', () => {setTimeout(() => {myWindow.print();}, 200);});
};

export {handlePrintGameInfo};
