import React, {Component} from 'react';
import PropTypes from 'prop-types';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import appConfig from 'config/app.config';
import {gamesData} from 'data/games-data';
import ReflectionResult from './reflection-result';
import InfoPopup from 'components/info-popup/info-popup';

class ReflectionResultController extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			showInfoPopup: false,
			groups: []
		};
		this.unsubscribeGroups = null;
	}

	/**
	 * Component did mount
	 */
	componentDidMount = () => {
		/* Subscribe to groups */
		this.subscribeToGroups();

		/* Show popup */
		if (!this.props.isFacilitator) {
			const gameStepData = gamesData[this.props.game.type].gameSteps.find((step) => {
				return step.id === this.props.gameStep;
			});
			if (gameStepData.infoPopup && gameStepData.infoPopup.autoShow === true) {
				this.timeout = setTimeout(() => {
					this.setState({ showInfoPopup: true });
				}, 500);
			}
		}
	};

	/**
	 * Component will unmount
	 */
	componentWillUnmount = () => {
		if (this.unsubscribeGroups !== null) this.unsubscribeGroups();
	};


	/**
	 * Subscribe to groups
	 */
	subscribeToGroups = () => {
		if (this.unsubscribeGroups !== null) this.unsubscribeGroups();
		const db = firebase.firestore();
		return new Promise((resolve)=>{
			this.unsubscribeGroups = db.collection(appConfig.groupsDbName).where('gameId', '==', this.props.game.id)
				.onSnapshot((docs) => {
					let groups = [];
					docs.forEach((doc) => {
						let groupObj = doc.data();
						groupObj.id = doc.id;
						groups.push(groupObj);
					});
					this.setState({groups: groups, isLoading: false}, () => {resolve({status: 'ok'});});
				}, (error) => {
					console.error('could not get groups: ', error);
					this.setState({isLoading: false}, () => {resolve({status: 'error', error: error});});
				});
		});
	};

	/**
	 * Hide / show info popup
	 */
	toggleInfoPopup = (show = null) => {
		let showInfoPopup = !this.state.showInfoPopup;
		if (show !== null) showInfoPopup = show;
		this.setState({ showInfoPopup, delayDealingCards: false });
	};

	/**
	 * Render component 
	 */
	render() {
		return (
			<>
				<ReflectionResult
					game={this.props.game}
					companyData={this.props.companyData}
					gameStep={this.props.gameStep}
					groups={this.state.groups}
					handleGoToPage={this.props.handleGoToPage}
					confirmAndContinue={this.props.confirmAndContinue}
					goToPrevGameStep={this.props.goToPrevGameStep}
					toggleInfoPopup={this.toggleInfoPopup}
				/>
				{this.state.showInfoPopup && <InfoPopup
					canToggle={true}
					game={this.props.game}
					gameStep={this.props.gameStep}
					toggleInfoPopup={this.toggleInfoPopup}
					handleLogout={null}
				/>}
			</>
		);
	}
}

ReflectionResultController.propTypes = {
	isFacilitator: PropTypes.bool.isRequired,
	game: PropTypes.object.isRequired,
	companyData: PropTypes.object,
	gameStep: PropTypes.string.isRequired,
	handleGoToPage: PropTypes.func.isRequired,
	goToPrevGameStep: PropTypes.func,
	confirmAndContinue: PropTypes.func.isRequired,
};

export default ReflectionResultController;
