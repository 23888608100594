import React from 'react';
import PropTypes from 'prop-types';
import { generalUiTexts } from 'data/ui-texts';
import { dirtyDozenData } from 'data/dirty-dozen-data';
import { gamesData} from 'data/games-data';
import {getText} from 'helpers/language-helper';
import { renderMarkdown } from 'helpers/text-helper';
import {replaceTextPlaceholders} from 'helpers/group-crew-helper';
import './event-card-crm-back.scss';

const EventCardCRMBack = ({ isDisabled, languageId, gameType, gameScenario, card, cardData, selectedCrew}) => {
	/* Get crew and threats data */
	const crewData = gamesData[gameType].crewData;
	const scenarioData = gamesData[gameType].scenarios.find((sc) => {return sc.id === gameScenario;});
	const threatsData = scenarioData.threatsData;

	/* Check if card has "CALL FACILITATOR" text */
	let hasCallFacilitatorText = false;

	/* Card text (front) */
	let cardTitle = JSON.parse(JSON.stringify(getText(cardData.title, languageId)));
	let cardText = JSON.parse(JSON.stringify(getText(cardData.text, languageId)));
	let cardEffects = [];
	let selectedOptionData = cardData.options
		? cardData.options.find((option) => {return option.id === card.selectedOptionId;})
		: null;

	/* Critical event avoided */
	if (cardData.type === 'possible-threat') {
		cardTitle = JSON.parse(JSON.stringify(getText(cardData.title2, languageId)));
		cardText = JSON.parse(JSON.stringify(getText(cardData.text2, languageId)));
	}

	/* Result does not depend on any options */
	if (!selectedOptionData && cardData.consequences) {
		cardText = JSON.parse(JSON.stringify(getText(cardData.consequences.text, languageId)));
		if (cardData.consequences.hasCallFacilitatorText === true) hasCallFacilitatorText = true;
		cardEffects = cardData.consequences.effects ? cardData.consequences.effects : [];
	}
	/* Selected option consequence (text + effects) */
	let isThreat = false;
	let triggersCriticalEffect = false;
	if (selectedOptionData) {
		/* Default text & effects */
		cardText = JSON.parse(JSON.stringify(getText(selectedOptionData.consequences.text, languageId)));
		if (selectedOptionData.consequences.hasCallFacilitatorText === true) hasCallFacilitatorText = true;
		cardEffects = selectedOptionData.consequences.effects ? selectedOptionData.consequences.effects : [];
		/* Conditional text & effects */
		if (card.concequenceType === 'conditional' && selectedOptionData.consequences.conditionals) {
			let conditionalIndex = card.conditionalIndex ? card.conditionalIndex : 0;
			let conditionalData = selectedOptionData.consequences.conditionals[conditionalIndex];
			if (conditionalData.text) {
				cardText = JSON.parse(JSON.stringify(getText(conditionalData.text, languageId)));
			}
			if (conditionalData.hasCallFacilitatorText === true) hasCallFacilitatorText = true;
			cardEffects = conditionalData.effects ? conditionalData.effects : [];
		}

		/* Text contains dynamic elements */
		cardEffects.forEach((effect) => {
			if (effect.type === 'replace-crewmember') {
				let crewMemberId = selectedCrew.find((crew) => {
					return crew.slotId === effect.slotId;
				})
					? selectedCrew.find((crew) => {return crew.slotId === effect.slotId;}).id
					: null;
				if (crewMemberId) {
					let crewMemberData = crewData.availableCrew.find((crew) => {
						return crew.id === crewMemberId;
					});
					if (crewMemberData) {
						cardText = cardText.replace(/%name%/g, getText(crewMemberData.name, languageId));
					}
				}
			}
		});

		/* Replace pronouns */
		if (cardData.slotId) {
			let crewMemberId = selectedCrew.find((c) => {return c.slotId === cardData.slotId;}).id;
			let crewMemberData = gamesData[gameType].crewData.availableCrew.find((c) => {
				return c.id === crewMemberId;
			});
			if (crewMemberData) {
				cardText = replaceTextPlaceholders(
					cardText, 
					getText(crewMemberData.name, languageId), 
					crewMemberData.gender ? crewMemberData.gender : null
				);
			}
		}

		/* Triggers potential threat or critical event */
		isThreat = cardEffects.some((effect) => {
			return effect.type === 'threat';
		});
		if (isThreat) {
			let threatId = cardEffects.find((effect) => {
				return effect.type === 'threat';
			}).threatId;
			let threatData = threatsData.find((threat) => {
				return threat.id === threatId;
			});
			if (threatData) cardTitle = getText(threatData.title, languageId);
		}
		triggersCriticalEffect = cardEffects.some((effect) => {
			return effect.type === 'critical-event';
		});
	}

	/* Add "CALL FACILIATOR" text */
	if (hasCallFacilitatorText) {
		cardText += '<br /><br /><center>' + 
			getText(generalUiTexts.callFacilitator, languageId) + '</center>';
	}


	/* Get effects that will be displayd on the card */
	let displayedCardEffects = [];
	displayedCardEffects = cardEffects.filter((effect) => {
		return (
			effect.type === 'dirtydozen' || 
			effect.type === 'risk' || 
			effect.type === 'inconvenience' ||
			effect.type === 'crewSafety' ||
			effect.type === 'missionSuccess' ||
			effect.type === 'compliance'
		);
	});
	if (displayedCardEffects.length > 0) {
		cardText = cardText + '<br /><br />' + getText(generalUiTexts.youGet, languageId) + ':';
	}

	return (
		<div
			className={
				'EventCardCRMBack' +
				(cardData.type === 'critical' ? ' critical' : '') +
				(isThreat ? ' threat' : '') +
				(triggersCriticalEffect ? ' triggersCriticalEffect' : '')
			}
		>
			<div className="EventCardCRMBack-header">
				<div className="EventCardCRMBack-title">{cardTitle}</div>
			</div>
			<div className="EventCardCRMBack-body">
				<div className={'EventCardCRMBack-text'}>{renderMarkdown(cardText)}</div>
				{displayedCardEffects.length > 0 && (
					<div className="EventCardCRMBack-effects">
						{displayedCardEffects.map((effect, index) => {
							if (effect.type === 'dirtydozen' && effect.dirtyDozenId) {
								let ddData = dirtyDozenData.find((dd) => {
									return dd.id === effect.dirtyDozenId;
								});
								if (ddData) {
									return (
										<div key={index} className="EventCardCRMBack-effect">
											<div className={'EventCardCRMBack-effectValue yellow'}>+{effect.value}</div>
											<div className="EventCardCRMBack-effectTitle">
												{getText(ddData.title, languageId)}
											</div>
										</div>
									);
								}
							}
							if (effect.type === 'inconvenience') {
								return (
									<div key={index} className="EventCardCRMBack-effect">
										<div
											className="EventCardCRMBack-effectValue blue">
											{(effect.value > 0 ? '+' : '') + effect.value}
										</div>
										<div className="EventCardCRMBack-effectTitle">
											{getText(generalUiTexts.inconvenience, languageId)}
										</div>
									</div>
								);
							}
							if (effect.type === 'risk') {
								return (
									<div key={index} className="EventCardCRMBack-effect">
										<div className="EventCardCRMBack-effectValue red">
											{(effect.value > 0 ? '+' : '') + effect.value}
										</div>
										<div className="EventCardCRMBack-effectTitle">
											{getText(generalUiTexts.risk, languageId)}
										</div>
									</div>
								);
							}
							if (
								effect.type === 'crewSafety' || 
								effect.type === 'missionSuccess' || 
								effect.type === 'compliance'
							) {
								return (
									<div key={index} className="EventCardCRMBack-effect">
										<div className="EventCardCRMBack-effectValue blue">
											{(effect.value > 0 ? '+' : '') + effect.value}
										</div>
										<div className="EventCardCRMBack-effectTitle">
											{getText(generalUiTexts[effect.type], languageId)}
										</div>
									</div>
								);
							}
							return null;
						})}
					</div>
				)}
			</div>
			{isDisabled && <div className="EventCard-disabledOverlay" />}
		</div>
	);
};

EventCardCRMBack.propTypes = {
	isDisabled: PropTypes.bool.isRequired,
	languageId: PropTypes.string.isRequired,
	gameType: PropTypes.string.isRequired,
	gameScenario: PropTypes.string.isRequired,
	card: PropTypes.object.isRequired,
	cardData: PropTypes.object.isRequired,
	selectedCrew: PropTypes.array.isRequired
};

export default EventCardCRMBack;
