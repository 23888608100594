import React from 'react';
import PropTypes from 'prop-types';
import './dirty-dozen.scss';

const DirtyDozen = ({title, value, isHighlighted, isColored}) => {
	return (
		<div className={`DirtyDozen ${isHighlighted && 'highlight'}`}>
			<div className={`DirtyDozen-value ${value > 0 ? 'active' : ''}`}>{value}</div>
			<div className={'DirtyDozen-title' + (isColored ? ' color' : '')}>{title}</div>
		</div>
	);
};

DirtyDozen.defaultProps = {
	isHighlighted: false,
	isColored: false
};

DirtyDozen.propTypes = {
	title: PropTypes.string.isRequired,
	value: PropTypes.number.isRequired,
	isHighlighted: PropTypes.bool,
	isColored: PropTypes.bool
};

export default DirtyDozen;