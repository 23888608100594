import React from 'react';
import PropTypes from 'prop-types';
import { generalUiTexts } from 'data/ui-texts';
import {renderMarkdown} from 'helpers/text-helper';
import {getText} from 'helpers/language-helper';
import './best-practice-card-back.scss';

const BestPracticeCardBack = ({languageId, cardData}) => {

	return (
		<div className="BestPracticeCardBack">
			<div className="BestPracticeCardBack-inner">
				<div className="BestPracticeCardBack-header">
					<div className="BestPracticeCardBack-title">
						{(cardData && cardData.title) && getText(cardData.title, languageId)}
					</div>
				</div>
				<div className="BestPracticeCardBack-body">
					<div className="BestPracticeCardBack-text">
						{(cardData && cardData.textBack) && renderMarkdown(getText(cardData.textBack, languageId))}
					</div>
					{(cardData.effects && cardData.effects.length > 0) && <div className="BestPracticeCardBack-effects">
						{cardData.effects.map((effect, index) => {
							return (
								<div key={index} className="BestPracticeCardBack-effect">
									<div className={`BestPracticeCardBack-effectValue 
										${effect.value > 0 ? effect.type : 'green'}`}>
										{effect.value}
									</div>
									<div className="BestPracticeCardBack-effectTitle">
										{getText(generalUiTexts.extra, languageId)} <span>{effect.type}</span>
									</div>
								</div>
							);
						})}
					</div>}
				</div>
			</div>
		</div>
	);
};

BestPracticeCardBack.propTypes = {
	languageId: PropTypes.string.isRequired,
	cardData: PropTypes.object.isRequired,
};

export default BestPracticeCardBack;
