import React from 'react';
import {Link} from 'react-router-dom';
import Logo from 'components/logo/logo';
import {loginUiTexts} from 'data/ui-texts';
import {gamesArray, gamesData} from 'data/games-data';
import {getText} from 'helpers/language-helper';
import aeroteam from 'assets/images/aeroteam.png';
import sagroup from 'assets/images/sagroup.png';
import './landing-page.scss';
// TODO: 'en'- > languageId
const LandingPage = () => {
	return (
		<div className="LandingPage">
			<img src={aeroteam} className="aeroteam" alt="aeroteam logo" />
			<img src={sagroup} className="sagroup" alt="sa-group logo" />
			<Logo classes={['landingpage']}/>
			<div className="LandingPage-games">
				{gamesArray.map((gameId, index) => {
					if (!gamesData.hasOwnProperty(gameId)) return null;
					return (
						<Link key={index} to={gamesData[gameId].urlPath} className={'LandingPage-game ' + gameId}>
							<div className="LandingPage-gameTitle">
								{getText(gamesData[gameId].titleLandingPage, 'en')}
							</div>
						</Link>
					);
				})}
			</div>
			<Link to="/admin" className="LandingPage-adminBtn">{getText(loginUiTexts.adminBtn, 'en')}</Link>
			<div className='LandingPage-logo' onClick={() => {window.open('https://cphgamelab.dk/', '_blank', 'noopener,noreferrer');}} ></div>
		</div>
	);
};


export default LandingPage;