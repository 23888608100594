import React from 'react';
import PropTypes from 'prop-types';
import {getText} from 'helpers/language-helper';
import {adminUiTexts} from 'data/ui-texts';
import {gamesData} from 'data/games-data';
import {sortArrayByProperty} from 'helpers/array-helper';
import './companies.scss';
// TODO: 'en' -> languageId
const Companies = ({companies, facilitators, selectCompany}) => {
	let sortedCompanies = sortArrayByProperty(companies, 'created', 'DESC');
	return (
		<div className="Companies">
			<div className="Companies-header">
				<div className="Companies-companySection name">{adminUiTexts.name}</div>
				<div className="Companies-companySection created">{adminUiTexts.created}</div>
				<div className="Companies-companySection facilitators">{adminUiTexts.facilitators}</div>
				<div className="Companies-companySection games">{adminUiTexts.games}</div>
			</div>
			<div className="Companies-body">
				{sortedCompanies.map((company, index) => {
					let numberOfFacilitators = facilitators.filter((f) => {
						return (f.companyId === company.id && !f.isAdmin);
					}).length;

					let enabledGames = '';
					if (company.hasOwnProperty('games')) {
						company.games.forEach((game) => {
							let gameData = (gamesData.hasOwnProperty(game.id) ? gamesData[game.id] : null);
							if (gameData && game.enabled === true) {
								if (enabledGames.length === 0) {
									enabledGames = getText(gameData.titleShort, 'en');
								} else {
									enabledGames += ', ' + getText(gameData.titleShort, 'en');
								}
							}
						});
					}
					if (enabledGames.length === 0) enabledGames = '-';
					return (
						<div key={index} className="Companies-company" onClick={() => {selectCompany(company.id);}}>
							<div className="Companies-companySection name">{company.title}</div>
							<div className="Companies-companySection created">{company.created}</div>
							<div className="Companies-companySection facilitators">{numberOfFacilitators}</div>
							<div className="Companies-companySection games">{enabledGames}</div>
						</div>
					);
				})}
			</div>
		</div>
	);
};

Companies.propTypes = {
	companies: PropTypes.array.isRequired,
	facilitators: PropTypes.array.isRequired,
	selectCompany: PropTypes.func.isRequired,
};

export default Companies;