import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { gamesData } from 'data/games-data';
import Reflection from './reflection';
import InfoPopup from 'components/info-popup/info-popup';

class ReflectionController extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showInfoPopup: false
		};
	}

	componentDidMount = () => {
		if (!this.props.isFacilitator) {
			/* Show info popup */
			const gameStepData = gamesData[this.props.game.type].gameSteps.find((step) => {
				return step.id === this.props.group.gameStep;
			});
			const reflectionData = gamesData[this.props.game.type].reflectionsData.find((reflection) => {
				return reflection.gameStep === this.props.group.gameStep;
			});
			let showPopup = true;
			if (reflectionData && reflectionData.questions && this.props.group.reflectionAnswers) {
				let reflectionQuestionIds = reflectionData.questions.map((q) => {return q.id;});
				let reflectionQuestionsAnswered = this.props.group.reflectionAnswers.filter((a) => {
					return (
						a.gameStep === reflectionData.gameStep &&
						reflectionQuestionIds.indexOf(a.id) >= 0
					);
				});
				if (reflectionQuestionsAnswered.length === reflectionQuestionIds.length) {
					showPopup = false;
				}
			}
			if (gameStepData.infoPopup && gameStepData.infoPopup.autoShow === true && showPopup === true) {
				this.timeout = setTimeout(() => {
					this.setState({ showInfoPopup: true });
				}, 500);
			}
		}
	};

	/**
	 * Hide / show info popup
	 */
	toggleInfoPopup = (show = null) => {
		let showInfoPopup = !this.state.showInfoPopup;
		if (show !== null) showInfoPopup = show;
		this.setState({ showInfoPopup, delayDealingCards: false });
	};


	/**
	 * Select answer to question
	 * @param {number} questionIndex 
	 * @param {number} value 
	 */
	handleSelectOption = (id, value) => {
		let reflectionAnswers = [];
		if (this.props.group.reflectionAnswers) {
			reflectionAnswers = JSON.parse(JSON.stringify(this.props.group.reflectionAnswers));
		}
		let answerIndex = reflectionAnswers.findIndex((answer) => {
			return (answer.gameStep === this.props.group.gameStep && answer.id === id);
		});
		this.handleUpdateReflectionAnswers(answerIndex, id, value);
	};

	/**
	 * Type answer to questions
	 * @param {object} event 
	 */
	// TODO: It might make sense to also save input locally in case of slow internet
	handleInputText = (event) => {
		let answerId = parseInt(event.target.name);
		let answerValue = event.target.value; 
		let reflectionAnswers = [];
		if (this.props.group.reflectionAnswers) {
			reflectionAnswers = JSON.parse(JSON.stringify(this.props.group.reflectionAnswers));
		}
		let answerIndex = reflectionAnswers.findIndex((answer) => {
			return (answer.gameStep === this.props.group.gameStep && answer.id === answerId);
		});
		this.handleUpdateReflectionAnswers(answerIndex, answerId, answerValue);
	};

	/**
	 * Update reflection answers
	 * @param {number} answerIndex 
	 * @param {number} id 
	 * @param {any} value 
	 */
	handleUpdateReflectionAnswers = (answerIndex, id, value) => {
		let reflectionAnswers = [];
		if (this.props.group.reflectionAnswers) {
			reflectionAnswers = JSON.parse(JSON.stringify(this.props.group.reflectionAnswers));
		}
		
		if (answerIndex >= 0) {
			/* Question already answered, update answer */
			reflectionAnswers[answerIndex].value = value;
		} else {
			/* First time question is answered*/
			reflectionAnswers.push({gameStep: this.props.group.gameStep, id: id, value: value});
		}
		this.props.updateGroup({reflectionAnswers: reflectionAnswers});
	};

	handleUpdateDirtyDozenOrder = (dirtyDozenIds) => {
		this.props.updateGroup({dirtyDozenIds: dirtyDozenIds});
	};

	handleUpdateFocusPointsOrder = (focusPointIds) => {
		this.props.updateGroup({focusPointIds: focusPointIds});
	};

	handleUpdateSelectedTopics = (selectedTopics) => {
		this.props.updateGroup({selectedTopics: selectedTopics});
	};

	/**
	 * Render component 
	 */
	render() {
		return (
			<>
				<Reflection
					isFacilitator={this.props.isFacilitator}	
					page={this.props.page}
					game={this.props.game}				
					group={this.props.group}
					handleGoToPage={this.props.handleGoToPage}
					handleSelectOption={this.handleSelectOption}
					handleInputText={this.handleInputText}
					handleUpdateDirtyDozenOrder={this.handleUpdateDirtyDozenOrder}
					handleUpdateFocusPointsOrder={this.handleUpdateFocusPointsOrder}
					handleUpdateSelectedTopics={this.handleUpdateSelectedTopics}
					confirmAndContinue={this.props.confirmAndContinue}
					toggleInfoPopup={this.toggleInfoPopup}
				/>
				{this.state.showInfoPopup && <InfoPopup
					canToggle={true}
					game={this.props.game}
					gameStep={this.props.group.gameStep}
					toggleInfoPopup={this.toggleInfoPopup}
					handleLogout={null}
				/>}
			</>
		);
	}
}

ReflectionController.propTypes = {
	isFacilitator: PropTypes.bool.isRequired,
	page: PropTypes.string.isRequired,
	game: PropTypes.object.isRequired,
	group: PropTypes.object.isRequired,
	handleGoToPage: PropTypes.func.isRequired,
	updateGroup: PropTypes.func.isRequired,
	confirmAndContinue: PropTypes.func.isRequired,
};

export default ReflectionController;
