import React from 'react';
import './loading.scss';

const Loading = () => {
	return (
		<div className="Loading">
			<div className="Loading-wrapper" />
		</div>
	);
};


export default Loading;