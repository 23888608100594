import React from 'react';
import PropTypes from 'prop-types';
import {getTriggeredCrewMemberConditional} from 'helpers/group-crew-helper';
import {dirtyDozenData} from 'data/dirty-dozen-data';
import { generalUiTexts } from 'data/ui-texts';
import {getText} from 'helpers/language-helper';
import {renderMarkdown} from 'helpers/text-helper';
import './crew-card-back.scss';

const CrewCardBack = ({languageId, cardData, layout, selectedCrew, onClick}) => {
	/* Check if conditional effects should be displayed */
	const triggeredConditional = getTriggeredCrewMemberConditional(cardData, selectedCrew);
	// let showConditionalEffects = false;
	// if (cardData.back.conditionals && selectedCrew) {
	// 	if (
	// 		cardData.back.conditional.conditionType === 'crewId' && 
	// 		selectedCrew.some((crew) => {return crew.id === cardData.back.conditional.crewId;})
	// 	) {
	// 		showConditionalEffects = true;
	// 	}
	// 	if (
	// 		cardData.back.conditional.conditionType === 'crewPositions'
	// 	) {
	// 		showConditionalEffects = true;
	// 		cardData.back.conditional.crewPositions.forEach((position) => {
	// 			if (showConditionalEffects === false) return;
	// 			const crewMemberInPosition = selectedCrew.find((c) => {return c.slotId === position.slotId;});
	// 			if (crewMemberInPosition && crewMemberInPosition.id !== position.crewId) showConditionalEffects = false;
	// 		});
	// 	}
	// }
	const text = (triggeredConditional 
		? getText(triggeredConditional.text, languageId) 
		: getText(cardData.back.text, languageId)
	);
	const effects = (triggeredConditional ? triggeredConditional.effects : cardData.back.effects);
	// let text = (showConditionalEffects 
	// 	? getText(cardData.back.conditional.text, languageId)
	// 	: getText(cardData.back.text, languageId)
	// );
	// let effects = (showConditionalEffects ? cardData.back.conditional.effects : cardData.back.effects);

	/* Render card info */
	function renderInfo() {
		return (
			<div className="CrewCardBack-info">
				<div className="CrewCardBack-name"><span>{getText(cardData.name, languageId)}</span></div>
				<div className="CrewCardBack-title">
					<span>{getText(generalUiTexts.title, languageId)}: </span>{getText(cardData.title, languageId)}
				</div>
				{(layout !== 'crewFeedback' && layout !== 'crewFeedbackWhite') && 
					<div className="CrewCardBack-age">
						<span>{getText(generalUiTexts.age, languageId)}: </span>{cardData.age}
					</div>
				}
			</div>
		);
	}

	return (
		<div className={'CrewCardBack ' + layout} onClick={onClick}>
			{layout === 'crewFeedback' || layout === 'crewFeedbackWhite'
				? <React.Fragment>
					<div className={'CrewCardBack-top ' + cardData.type} />
					<div className={'CrewCardBack-bottom ' + cardData.type}>
						{layout === 'crewFeedback' && renderInfo()}
					</div>
				</React.Fragment>
				: <React.Fragment>
					<div className="CrewCardBack-top">
						<div className="CrewCardBack-topWrapper">
							<div className="CrewCardBack-description">
								{renderMarkdown(text)}
								{(!effects || effects.length === 0) && 
									<p><br />{getText(generalUiTexts.noDirtyDozen, languageId)}.</p>}
							</div>
							{(effects && effects.length > 0) && <div className="CrewCardBack-effects">
								{effects.map((effect, index) => {
									if (effect.type === 'dirtyDozen' && effect.dirtyDozenId) {
										let ddData = dirtyDozenData.find(
											(dirtyDozen) => {return (dirtyDozen.id === effect.dirtyDozenId);}
										);
										return (
											<div key={index} className="CrewCardBack-dirtyDozen">
												<div className={'CrewCardBack-dirtyDozenValue'}>+{effect.value}</div>
												<div className="CrewCardBack-dirtyDozenTitle">
													{getText(ddData.title, languageId)}
												</div>
											</div>
										);
									} else if (effect.type === 'risk') {
										return (
											<div key={index} className="CrewCardBack-risk">
												<div className={'CrewCardBack-riskValue'}>+{effect.value}</div>
												<div className="CrewCardBack-riskTitle">{effect.type}</div>
											</div>
										);
									}
									return null;
								})}
							</div>}
						</div>
					</div>
					{layout === 'crewFeedbackSelected' && 
						<div className={'CrewCardBack-bottom ' + cardData.type}>{renderInfo()}</div>}
				</React.Fragment>}
		</div>
	);
};

CrewCardBack.defaultProps = {
	layout: 'default',
	onClick: null
};

CrewCardBack.propTypes = {
	languageId: PropTypes.string.isRequired,
	cardData: PropTypes.object.isRequired,
	layout: PropTypes.string,
	selectedCrew: PropTypes.array,
	onClick: PropTypes.func
};

export default CrewCardBack;