import React, {Component} from 'react';
import PropTypes from 'prop-types';
import DecisionMaking from './decision-making';
import InfoPopup from 'components/info-popup/info-popup';

class DecisionMakingController extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showInfoPopup: false
		};
	}


	/**
	 * Type answer to questions
	 * @param {object} event 
	 */
	// TODO: It might make sense to also save input locally in case of slow internet
	handleInputText = (event) => {
		if (this.props.isFacilitator) return;
		
		let answerId = event.target.name;
		let answerValue = event.target.value; 
		let decisionAnswers = [];
		if (this.props.group.decisionAnswers) {
			decisionAnswers = JSON.parse(JSON.stringify(this.props.group.decisionAnswers));
		}
		let answerIndex = decisionAnswers.findIndex((answer) => {return (answer.id === answerId);});

		/* Update answer */
		if (answerIndex >= 0) {
			/* Question already answered, update answer */
			decisionAnswers[answerIndex].value = answerValue;
		} else {
			/* First time question is answered */
			decisionAnswers.push({id: answerId, value: answerValue});
		}

		/* Update database */
		this.props.updateGroup({decisionAnswers: decisionAnswers});
	};

	/**
	 * Hide / show info popup
	 */
	toggleInfoPopup = (show = null) => {
		let showInfoPopup = !this.state.showInfoPopup;
		if (show !== null) showInfoPopup = show;
		this.setState({ showInfoPopup, delayDealingCards: false });
	};

	/**
	 * Render component 
	 */
	render() {
		return (
			<>
				<DecisionMaking
					gameIsPaused={this.props.game.isPaused}
					group={this.props.group}
					game={this.props.game}
					decisionTool={this.props.game.decisionTool}
					handleGoToPage={this.props.handleGoToPage}
					handleInputText={this.handleInputText}
					confirmAndContinue={this.props.confirmAndContinue}
					toggleInfoPopup={this.toggleInfoPopup}
				/>
				{this.state.showInfoPopup && <InfoPopup
					canToggle={true}
					game={this.props.game}
					gameStep={this.props.group.gameStep}
					toggleInfoPopup={this.toggleInfoPopup}
					handleLogout={null}
				/>}	
			</>
		);
	}
}

DecisionMakingController.propTypes = {
	isFacilitator: PropTypes.bool.isRequired,
	game: PropTypes.object.isRequired,
	group: PropTypes.object.isRequired,
	handleGoToPage: PropTypes.func.isRequired,
	updateGroup: PropTypes.func.isRequired,
	confirmAndContinue: PropTypes.func.isRequired,
};

export default DecisionMakingController;
