import React from 'react';
import PropTypes from 'prop-types';
import appConfig from 'config/app.config';
import {settingsUiTexts } from 'data/ui-texts';
import {gamesData} from 'data/games-data';
import {languagesData} from 'data/languages-data';
import {getText} from 'helpers/language-helper';
import Select from 'components/select/select';
import './settings-header.scss';

const SettingsHeader = (props) => {
	/* Props */
	const {
		children, 
		languageId, 
		page, title, 
		gameType, 
		gameUrlPath, 
		code, email, 
		handleChangeUserLanguage, 
		handleLogout
	} = props;
	
	return (
		<div className="SettingsHeader">
			<div className="SettingsHeader-left"><div className="SettingsHeader-content">{children}</div></div>
			{ code ? (
				<div className='SettingsHeader-center double'>
					<div className='SettingsHeader-gametitle'>
						<strong>{getText(settingsUiTexts.gameTitle, languageId)}: </strong>
						<span>{title}</span>
					</div>
					<div className='SettingsHeader-gameurl'>
						<strong>{getText(settingsUiTexts.gameUrl, languageId)}: </strong>
						<span>{appConfig.gameUrl + '/' + gameUrlPath}</span>
					</div>
					<div className='SettingsHeader-gamecode'>
						<strong>{getText(settingsUiTexts.gameCode, languageId)}: </strong>
						<span>{code}</span>
					</div>
				</div>
			) : (
				<div className='SettingsHeader-center'>
					<div className='SettingsHeader-title'>
						{(page === 'settings' || page === 'create-game') && 
							<span>{getText(gamesData[gameType].titleOverview, languageId)}</span>}
						{(page === 'settings' || page === 'create-game') && ' - '}
						{title}
					</div>
				</div>
			)}
			<div className={'SettingsHeader-right' + (handleChangeUserLanguage ? ' language' : '')}>
				{page === 'settings' && <div className="SettingsHeader-emailAndLanguage">
					<div className="SettingsHeader-email">{email}</div>
					{handleChangeUserLanguage &&
						<Select 
							isDisabled={false}
							type="small"
							options={languagesData}
							defaultId={languageId}
							selectedId={languageId}
							onSelect={handleChangeUserLanguage}
						/>
					}
				</div>}
				
				<div className="SettingsHeader-logoutBtn" onClick={() => {handleLogout();}} />
			</div>
		</div>
	);
};

SettingsHeader.propTypes = {
	children: PropTypes.any,
	languageId: PropTypes.string.isRequired,
	page: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	gameType: PropTypes.string,
	gameUrlPath: PropTypes.string,
	code: PropTypes.string,
	email: PropTypes.string.isRequired,
	handleChangeUserLanguage: PropTypes.func,
	handleLogout: PropTypes.func.isRequired
};

export default SettingsHeader;