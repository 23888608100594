import React from 'react';
import PropTypes from 'prop-types';
import {adminUiTexts} from 'data/ui-texts';
import Button from 'components/button/button';
import './facilitators.scss';

const Facilitators = (props) => {
	const {
		isResettingFacilitatorPw, 
		companyId, 
		facilitators, 
		games, 
		selectFacilitator, 
		resetFacilitatorPassword
	} = props;


	return (
		<div className="Facilitators">
			<div className="Facilitators-header">
				<div className="Facilitators-section name">{adminUiTexts.name}</div>
				<div className="Facilitators-section email">{adminUiTexts.email}</div>
				<div className="Facilitators-section login">{adminUiTexts.lastLogin}</div>
				<div className="Facilitators-section games">{adminUiTexts.games}</div>
				<div className="Facilitators-section status">{adminUiTexts.status}</div>
				<div className="Facilitators-section reset">{adminUiTexts.sendInvite}</div>
				
			</div>
			<div className="Facilitators-body">
				{facilitators.filter((f) => {return (f.companyId === companyId && !f.isAdmin);})
					.map((facilitatorData) => {

						let numberOfGames = games.filter((g) => {
							return (g.facilitatorId === facilitatorData.id && g.companyId === companyId);
						}).length;
						let status = adminUiTexts.notInvited;
						if (facilitatorData.invitationSent === true) {
							status = (facilitatorData.isEnabled === true 
								? adminUiTexts.enabled : adminUiTexts.disabled);
						}
					
						return (
							<div 
								key={facilitatorData.id} 
								className="Facilitators-facilitator" 
								onClick={() => {selectFacilitator(facilitatorData.id);}}
							>
								<div className="Facilitators-section name">{facilitatorData.name}</div>
								<div className="Facilitators-section email">{facilitatorData.email}</div>
								<div className="Facilitators-section login">
									{facilitatorData.lastLogin ? facilitatorData.lastLogin : '-'}
								</div>
								<div className="Facilitators-section games">{numberOfGames}</div>
								<div className={'Facilitators-section status' + 
									(facilitatorData.isEnabled === true ? ' enabled' : '') +
									(facilitatorData.invitationSent === true ? '' : ' notInvited')
								}
								>{status}</div>
								<div className="Facilitators-section reset">
									<Button 
										text={adminUiTexts.sendEmail} 
										classes={['createCompany']} 
										isLoading={(isResettingFacilitatorPw === facilitatorData.id ? true : false) }
										onClick={(e) => {
											e.stopPropagation();
											resetFacilitatorPassword(
												facilitatorData.email, 
												facilitatorData.id, 
												facilitatorData.invitationSent
											);
										}} 
									/>
								</div>
							</div>
						);
					})}
			</div>
		</div>
	);
};

Facilitators.propTypes = {
	isResettingFacilitatorPw: PropTypes.any,
	companyId: PropTypes.string.isRequired,
	facilitators: PropTypes.array.isRequired,
	games: PropTypes.array.isRequired,
	selectFacilitator: PropTypes.func.isRequired,
	resetFacilitatorPassword: PropTypes.func.isRequired,
};

export default Facilitators;