import React, { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import PropTypes from 'prop-types';
import './reflection-dnd-item.scss';

const ReflectionDnDItem = ({ id, index, text, moveItem, updateOrder}) => {
	const ref = useRef(null);
	const [, drop] = useDrop({
		accept: 'item',
		hover(item, monitor) {
			if (!ref.current) {return;}
			const dragIndex = item.index;
			const hoverIndex = index;
				
			// Don't replace items with themselves
			if (dragIndex === hoverIndex) {return;}
					
			// Determine rectangle on screen, get vertical middle, mouse position, pixels to top
			const hoverBoundingRect = ref.current?.getBoundingClientRect();
			const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
			const clientOffset = monitor.getClientOffset();
			const hoverClientY = clientOffset.y - hoverBoundingRect.top;
				
			// Only perform the move when the mouse has crossed half of the items height
			if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {return;} // Dragging downwards
			if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {return;} // Dragging upwards
			moveItem(dragIndex, hoverIndex);
			item.index = hoverIndex;
		},
		drop() {
			updateOrder();
		},
	});
	const [{ isDragging }, drag] = useDrag({
		type: 'item',
		item: {type: 'item', id, index},
		collect: (monitor) => {return {isDragging: monitor.isDragging()};},
	});
	const opacity = isDragging ? 0 : 1;
	drag(drop(ref));
	return <div ref={ref} className="ReflectionDnDItem" style={{opacity}}><span>{text}</span></div>;
};

ReflectionDnDItem.propTypes = {
	id: PropTypes.any,
	index: PropTypes.number.isRequired,
	text: PropTypes.string.isRequired,
	moveItem: PropTypes.func.isRequired,
	updateOrder: PropTypes.func.isRequired
};

export default ReflectionDnDItem;