import React from 'react';
import PropTypes from 'prop-types';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import {DndProvider} from 'react-dnd-multi-backend';
import {HTML5toTouch} from 'rdndmb-html5-to-touch';
// import { DndProvider } from 'react-dnd';
// import MultiBackend from 'react-dnd-multi-backend';
// import HTML5toTouch from 'react-dnd-multi-backend/dist/esm/HTML5toTouch';
import {getBrowserLanguage} from 'helpers/language-helper';
import Loading from 'components/loading/loading';
import Login from './login';
import FacilitatorController from 'components/users/facilitator-controller';
import GroupController from 'components/users/group-controller';
import './login.scss';

class LoginController extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoggedIn: false,
			isLoading: true,
			activeLoginBox: 'facilitator',
			userId: null,
			isFacilitator: false,
			isAdmin: false,
			email: null,
			languageId: getBrowserLanguage()
		};
		this.unsubscribeOnAuthStateChanged = null;
	}

	/**
	 * Component did mount
	 */
	componentDidMount = () => {
		this.checkIfLoggedIn();
	};

	/**
	 * Component will unmount
	 */
	componentWillUnmount = () => {
		if (this.unsubscribeOnAuthStateChanged !== null) this.unsubscribeOnAuthStateChanged();
	};

	/**
	 * Check if user is logged in
	 */
	checkIfLoggedIn = () => {
		// Unsubscribe previous onAuthStateChanged
		if (this.unsubscribeOnAuthStateChanged !== null) this.unsubscribeOnAuthStateChanged();

		// Subscribe to onAuthStateChanged
		this.unsubscribeOnAuthStateChanged = firebase.auth().onAuthStateChanged((user) => {
			if (user) {
				const isFacilitator = user.email !== null;
				/* Logged in as facilitator */
				if (isFacilitator) {
					this.setState({
						isLoggedIn: true,
						isLoading: false,
						userId: user.uid,
						isFacilitator: isFacilitator,
						email: user.email
					});
				} else {
					/* Logged in as group */
					this.setState({isLoggedIn: true, isLoading: false, userId: user.uid});
				}
			} else {
				this.setState({isLoading: false});
			}
		});
	};

	/**
	 * Update language
	 * @param {string} languageId 
	 */
	updateLanguage = (languageId) => {
		this.setState({languageId});
	};

	/**
	 * Switch between facilitator / group login box
	 * @param {string} activeLoginBox 
	 */
	setActiveLoginBox = (activeLoginBox) => {
		this.setState({activeLoginBox});
	};	

	/**
	 * Logout
	 */
	handleLogout = () => {
		firebase.auth().signOut();
		this.setState({
			isLoggedIn: false,
			userId: null,
			isFacilitator: false,
			isAdmin: false,
			email: null,
		});
	};



	/**
	 * Render component
	 */
	render = () => {
		// Loading
		if (this.state.isLoading && !this.state.isLoggedIn) return <Loading />;

		// User is not loggged in
		if (!this.state.isLoggedIn) {
			return (
				<Login
					languageId={this.state.languageId}
					gameType={this.props.gameType} 
					activeLoginBox={this.state.activeLoginBox}
					updateLanguage={this.updateLanguage}
					setActiveLoginBox={this.setActiveLoginBox}
				/>
			);
		}

		// User is logged in
		const authProps = {
			userId: this.state.userId,
			isFacilitator: this.state.isFacilitator,
			email: this.state.email,
			handleLogout: this.handleLogout
		};

		/* Get controller component for facilitator / group */
		let UserComponent = (authProps.isFacilitator ? FacilitatorController : GroupController);
			
		return (
			<DndProvider options={HTML5toTouch}>
				<UserComponent 
					{...authProps} 
					languageId={this.state.languageId}
					gameType={this.props.gameType} 
					isFullscreen={this.props.isFullscreen} 
					handleToggleFullscreen={this.props.handleToggleFullscreen}
				/>
			</DndProvider>
		);
	};
}

LoginController.propTypes = {
	isFullscreen: PropTypes.bool.isRequired,
	gameType: PropTypes.string.isRequired,
	handleToggleFullscreen: PropTypes.func.isRequired
};

export default LoginController;
