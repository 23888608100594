import React, {Component} from 'react';
import PropTypes from 'prop-types';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import {loginUiTexts} from 'data/ui-texts';
import {getText} from 'helpers/language-helper';
import LoginFacilitator from './login-facilitator';

class LoginFacilitatorController extends Component {
	constructor(props) {
		super(props);
		this.state = {
			email: null,
			password: null,
			errMsg: null,
			isLoading: false,
			invalidInputEmail: false,
			invalidInputPassword: false,
			showResetPasswordBox: false,
			passwordIsReset: false
		};
	}

	/**
	 * Update input field
	 * @param {obj} event 
	 */
	handleInput = (event) => {
		let value = event.target.value;
		let name = event.target.name;
		this.setState({
			[name]: value,
			errMsg: null,
			['invalidInput' + name.charAt(0).toUpperCase() + name.slice(1)]: false
		});
	};
	
	/**
	 * Handle login
	 * @param {obj} event 
	 */
	handleLogin = (event) => {
		event.preventDefault();
		this.setState({isLoading: true}, () => {

			/* Error: missing fields */
			let email = this.state.email;
			let password = this.state.password;
			if (!email || !password) {
				this.setState({
					isLoading: false,
					errMsg: getText(loginUiTexts.someFieldsMissing, this.props.languageId),
					invalidInputEmail: email ? false : true,
					invalidInputPassword: password ? false : true
				});
				return;
			}

			/* Sign in */
			firebase.auth().signInWithEmailAndPassword(email, password)
				.catch((error) => {
					let errorMsg = loginUiTexts.unknownError;
					if (error.code === 'auth/invalid-email') {
						errorMsg = getText(loginUiTexts.invalidEmail, this.props.languageId);
					}
					if (error.code === 'auth/user-not-found') {
						errorMsg = getText(loginUiTexts.userNotFound, this.props.languageId);
					}
					if (error.code === 'auth/wrong-password') {
						errorMsg = getText(loginUiTexts.invalidPassword, this.props.languageId);
					}
					this.setState({errMsg: errorMsg, isLoading: false});
				});
		});
	};

	/**
	 * Switch between 'login' and 'reset password' box
	 */
	toggleResetPasswordBox = () => {
		let showResetPasswordBox = !this.state.showResetPasswordBox;
		this.setState({showResetPasswordBox, passwordIsReset: false, errorMsg: null});
	};

	/** 
	 * Handle reset password request 
	 */
	handleResetPassword = () => {
		this.setState({isLoading: true}, () => {
			if (!this.state.email) {
				this.setState({
					isLoading: false,
					errMsg: getText(loginUiTexts.someFieldsMissing, this.props.languageId),
					invalidInputEmail: true
				});
				return;
			}

			const auth = firebase.auth();
			auth.sendPasswordResetEmail(this.state.email)
				.then(()=>{this.setState({isLoading: false, passwordIsReset: true});})
				.catch((error) =>{
					let errorMsg = getText(loginUiTexts.unknownError, this.props.languageId);
					if (error.code === 'auth/invalid-email') {
						errorMsg = getText(loginUiTexts.invalidEmail, this.props.languageId);
					}
					if (error.code === 'auth/user-not-found') {
						errorMsg = getText(loginUiTexts.userNotFound, this.props.languageId);
					}
					this.setState({
						errMsg: errorMsg,
						isLoading: false,
						invalidInputEmail: true
					});
				});
		});
	};

	/**
	 * Render component
	 */
	render() {
		return (
			<LoginFacilitator
				isLoading={this.state.isLoading}
				showResetPasswordBox={this.state.showResetPasswordBox}
				passwordIsReset={this.state.passwordIsReset}
				invalidInputEmail={this.state.invalidInputEmail}
				invalidInputPassword={this.state.invalidInputPassword}
				languageId={this.props.languageId}
				email={this.state.email}
				password={this.state.password}
				errMsg={this.state.errMsg}
				handleLogin={this.handleLogin}
				handleInput={this.handleInput}
				toggleResetPasswordBox={this.toggleResetPasswordBox}
				handleResetPassword={this.handleResetPassword}
			/>
		);
	}
}

LoginFacilitatorController.propTypes = {
	languageId: PropTypes.string.isRequired
};


export default LoginFacilitatorController;