import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { gamesData } from 'data/games-data';
import Welcome from 'components/welcome/welcome';
import CrewSelect from 'components/crew-select/crew-select-controller';
import CrewFeedback from 'components/crew-feedback/crew-feedback-controller';
import GameResultController from 'components/game-result/game-result-controller';
import Reflection from 'components/reflection/reflection-controller';
import ReflectionResult from 'components/reflection-result/reflection-result-controller';
import BestPracticesController from 'components/best-practices/best-practices-controller';
import BestPracticesBenefitsController from 'components/best-practices-benefits/best-practices-benefits-controller';
import GameBoardIntro from 'components/game-board-intro/game-board-intro';
import GameBoardCRMController from 'components/game-board-crm/game-board-crm-controller';
import GameBoardSTController from 'components/game-board-st/game-board-st-controller';
import DecisionMakingController from 'components/decision-making/decision-making-controller';
import DecisionMakingResultController from 'components/decision-making-result/decision-making-result-controller';
import CommunicationController from 'components/communication/communication-controller';
import CommunicationResultController from 'components/communication-result/communication-result-controller';
import ExerciseController from 'components/exercise/exercise-controller';
import ExerciseResultController from 'components/exercise-result/exercise-result-controller';
import Gameover from 'components/gameover/gameover';
import './group-popup.scss';

const GroupPopup = (props) => {
	const {
		isFullscreen, 
		game, 
		companyData, 
		groupId, 
		groups, 
		toggleGroupPopup, 
		handleToggleFullscreen
	} = props;

	/* Find current group game step */
	const gameSteps = gamesData[game.type].gameSteps;
	let group = groups.find((group) => {return group.id === groupId;});
	let gameStepData = gameSteps[0];
	if (group && gameSteps.some((step) => {return step.id === group.gameStep;})) {
		gameStepData = gameSteps.find((step) => {return step.id === group.gameStep;});
	}

	/* Navigate to page of current game step */
	const [page, setPage] = useState(gameStepData.page ? gameStepData.page : '');
	useEffect(() => {setPage(gameStepData.page);}, [gameStepData.page]);

	function renderPage() {
		switch (gameStepData.page) {
		case 'welcome':
			return (
				<Welcome
					isFacilitator={true}
					game={game}
					gameStep={gameStepData.id}
					confirmAndContinue={() => {return null;}}
					handleGoToPage={() => {return null;}}
					handleLogout={() => {return null;}}
				/>
			);
		case 'crew-select':
			return (
				<CrewSelect
					isFacilitator={true}
					game={game}			
					selectedCrew={group.selectedCrew ? group.selectedCrew : []}
					handleGoToPage={() => {return null;}}
					updateGroup={() => {return null;}}
					confirmAndContinue={() => {return null;}}
				/>
			);
		case 'crew-feedback':
			return (
				<CrewFeedback
					isFacilitator={true}
					game={game}
					group={group}
					handleGoToPage={() => {return null;}}
					updateGroup={() => {return null;}}
					confirmAndContinue={() => {return null;}}
				/>
			);
		case 'crew-feedback-result':
		case 'ground-result':
		case 'flights-result':
		case 'game-result':
			return (
				<GameResultController
					isFacilitator={true}
					game={game}
					companyData={companyData}
					gameStep={group.gameStep}
					handleGoToPage={() => {return null;}}
					confirmAndContinue={() => {return null;}}	
				/>
			);
		case 'reflection':
		case 'crew-reflection':
		case 'ground-reflection':
		case 'dd-reflection':
		case 'final-reflection':
			return (
				<Reflection
					isFacilitator={true}
					page={gameStepData.page}
					game={game}
					group={group}
					handleGoToPage={() => {return null;}}
					updateGroup={() => {return null;}}
					confirmAndContinue={() => {return null;}}
				/>
			);
		case 'reflection-result':
		case 'crew-reflection-result':
		case 'ground-reflection-result':
		case 'best-practices-result':
		case 'dd-reflection-result':
		case 'final-reflection-result':
			return (
				<ReflectionResult
					isFacilitator={true}
					game={game}
					companyData={companyData}
					gameStep={group.gameStep}
					handleGoToPage={() => {return null;}}
					confirmAndContinue={() => {return null;}}
					goToPrevGameStep={() => {return null;}}
				/>
			);
		case 'game-board-intro':
			return (
				<GameBoardIntro 
					isFacilitator={true}
					game={game}
					gameStep={group.gameStep}
					handleGoToPage={() => {return null;}}
					confirmAndContinue={() => {return null;}}
				/>
			);
		case 'game-board':
			let GameBoardComponent = GameBoardCRMController;
			if (game.type === 'safetytraining') GameBoardComponent = GameBoardSTController;
			return (
				<GameBoardComponent
					isFacilitator={true}
					game={game}
					group={group}
					handleGoToPage={() => {return null;}}
					updateGroup={() => {return null;}}
					confirmSelectedOptions={() => {return null;}}
					confirmResourcePlacements={() => {return null;}}
					confirmAndContinue={() => {return null;}}
				/>
			);
		case 'best-practices':
			return (
				<BestPracticesController
					isFacilitator={true}
					game={game}
					group={group}
					handleGoToPage={() => {return null;}}
					updateGroup={() => {return null;}}
					confirmAndContinue={() => {return null;}}
				/>
			);
		case 'best-practices-benefits':
			return (
				<BestPracticesBenefitsController
					isFacilitator={true}
					game={game}
					group={group}
					handleGoToPage={() => {return null;}}
					updateGroup={() => {return null;}}
					confirmAndContinue={() => {return null;}}
				/>
			);
		case 'decision-making':
			return (
				<DecisionMakingController 
					isFacilitator={true}
					game={game}
					group={group}
					handleGoToPage={() => {return null;}}
					updateGroup={() => {return null;}}
					confirmAndContinue={() => {return null;}}
				/>
			);
		case 'decision-making-result':
			return (
				<DecisionMakingResultController 
					isFacilitator={true}
					game={game}
					companyData={companyData}
					gameStep={group.gameStep}
					goToPrevGameStep={() => {return null;}}
					handleGoToPage={() => {return null;}}
					confirmAndContinue={() => {return null;}}
				/>
			);
		case 'communication':
			return (
				<CommunicationController 
					isFacilitator={true}
					game={game}
					group={group}
					handleGoToPage={() => {return null;}}
					updateGroup={() => {return null;}}
					confirmAndContinue={() => {return null;}}
				/>
			);
		case 'communication-result':
			return (
				<CommunicationResultController 
					isFacilitator={true}
					game={game}
					companyData={companyData}
					gameStep={group.gameStep}
					goToPrevGameStep={() => {return null;}}
					handleGoToPage={() => {return null;}}
					confirmAndContinue={() => {return null;}}
				/>
			);
		case 'exercise':
			return (
				<ExerciseController 
					isFacilitator={true}
					game={game}
					group={group}
					handleGoToPage={() => {return null;}}
					updateGroup={() => {return null;}}
					confirmAndContinue={() => {return null;}}
				/>
			);
		case 'exercise-result':
			return (
				<ExerciseResultController 
					isFacilitator={true}
					game={game}
					companyData={companyData}
					gameStep={group.gameStep}
					goToPrevGameStep={() => {return null;}}
					handleGoToPage={() => {return null;}}
					confirmAndContinue={() => {return null;}}
				/>
			);
		case 'gameover':
			return (
				<Gameover
					languageId={game.languageId}
					gameType={game.type}
					handleLogout={() => {return null;}}
					handleGoToPage={() => {return null;}}
				/>
			);
		default:
			return <div>Unknown page: {page}</div>;
		}
	}

	return (
		<div className={'GroupPopup' + (isFullscreen ? ' fullscreen' : '')} onClick={() => {toggleGroupPopup(null);}}>
			<div className="GroupPopup-content" onClick={(e) => {e.stopPropagation();}}>
				<div className="GroupPopup-header">
					<div 
						className={'GroupPopup-fullscreenBtn' + (isFullscreen ? ' fullscreen' : '')} 
						onClick={() => {handleToggleFullscreen();}}
					/>
					<div className="GroupPopup-title">{group.title}</div>
					<div className="GroupPopup-closeBtn" onClick={() => {toggleGroupPopup(null);}}/>
				</div>
				<div className="GroupPopup-page">{renderPage()}</div>
			</div>
		</div>
	);
};

GroupPopup.propTypes = {
	isFullscreen: PropTypes.bool.isRequired,
	game: PropTypes.object.isRequired,
	companyData: PropTypes.object,
	groupId: PropTypes.string.isRequired,
	groups: PropTypes.array.isRequired,
	toggleGroupPopup: PropTypes.func.isRequired,
	handleToggleFullscreen: PropTypes.func.isRequired,
};

export default GroupPopup;
