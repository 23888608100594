import React from 'react';
import { useDragLayer } from 'react-dnd';
import PropTypes from 'prop-types';
import GameBoardStDnDItem from 'components/game-board-st/game-board-st-dnd-item';
import './game-board-st-dnd-drag-layer.scss';

const GameBoardSTDnDDragLayer = ({crewData, selectedCrew, resetDraggingInactivity}) => {
	const { isDragging, item, currentOffset} = useDragLayer((monitor) => {
		if (monitor.isDragging()) {
			resetDraggingInactivity();
		}
		return {
			isDragging: monitor.isDragging(),
			item: monitor.getItem(),
			currentOffset: monitor.getSourceClientOffset(),
		};
	});




	if (isDragging && item  && currentOffset) {
		
		let slotId = selectedCrew.find((person) => {return person.id === item.id;}).slotId;
		let slotData = crewData.crewSlots.find((slot) => {return slot.id === slotId;});
		let cardData = crewData.availableCrew.find((person) => {return person.id === item.id;});
		cardData.color = slotData.color;

		let {x, y} = currentOffset;
		let transform = `translate(${x}px, ${y}px)`;

		return (
			<div 
				className={'GameBoardSTDnDDragLayer ' + cardData.color} 
				style={{transform, WebkitTransform: transform}}
			>
				<GameBoardStDnDItem 
					type="big"
					isDraggable={false} 
					isSelected={false}
					isDraglayer={true}
					cardData={cardData} 
					
				/>
			</div>
		);

	}

	return null;
};


GameBoardSTDnDDragLayer.propTypes = {
	crewData: PropTypes.object.isRequired,
	selectedCrew: PropTypes.array.isRequired,
	resetDraggingInactivity: PropTypes.func.isRequired
};


export default GameBoardSTDnDDragLayer;