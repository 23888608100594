import React, { Component } from 'react';
import PropTypes from 'prop-types';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import appConfig from 'config/app.config';
import {languagesData} from 'data/languages-data';
import apiHelper from 'helpers/api-helper';
import {checkIfGameIsAvailable} from 'helpers/game-access-helper';
import { sortArrayByProperty } from 'helpers/array-helper';
import {gamesData} from 'data/games-data';
import Loading from 'components/loading/loading';
import Welcome from 'components/welcome/welcome';
import CrewSelect from 'components/crew-select/crew-select-controller';
import CrewFeedback from 'components/crew-feedback/crew-feedback-controller';
import GameResultController from 'components/game-result/game-result-controller';
import Reflection from 'components/reflection/reflection-controller';
import ReflectionResult from 'components/reflection-result/reflection-result-controller';
import BestPracticesController from 'components/best-practices/best-practices-controller';
import BestPracticesBenefitsController from 'components/best-practices-benefits/best-practices-benefits-controller';
import GameBoardIntro from 'components/game-board-intro/game-board-intro';
import GameBoardCRMController from 'components/game-board-crm/game-board-crm-controller';
import GameBoardSTController from 'components/game-board-st/game-board-st-controller';
import DecisionMakingController from 'components/decision-making/decision-making-controller';
import DecisionMakingResultController from 'components/decision-making-result/decision-making-result-controller';
import CommunicationController from 'components/communication/communication-controller';
import CommunicationResultController from 'components/communication-result/communication-result-controller';
import ExerciseController from 'components/exercise/exercise-controller';
import ExerciseResultController from 'components/exercise-result/exercise-result-controller';
import Gameover from 'components/gameover/gameover';

class GroupController extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			page: 'welcome',
			game: null,
			group: null,
			companyData: null
		};
		this.unsubscribeGame = null;
		this.unsubscribeGroup = null;
	}

	/**
	 * Component mounted
	 */
	componentDidMount = () => {
		/* Subscribe to game and group */
		let groupId = this.props.userId;
		let gameId = groupId.substr(0, groupId.indexOf('-'));
		Promise.all([
			this.subscribeToGame(gameId), 
			this.subscribeToGroup(groupId)
		]).then((responses) => {
			if (responses[0].status === 'ok' && responses[1].status === 'ok') {
				/* Check that game is still available */
				this.getCompanyData().then((response) => {
					let gameIsAvailable = false;
					if (response.status === 'ok') {
						/* Check if company has access to game and scenario */
						gameIsAvailable = checkIfGameIsAvailable(this.state.game, this.state.companyData);
						/* Check if url matches game type */
						if (this.props.gameType !==  this.state.game.type) gameIsAvailable = false;
					}
					if (!gameIsAvailable) {
						/* Not available, log out */
						this.props.handleLogout();
					} else {
						/* Go to page of current game step */
						let page = this.getPageOfCurrentGameStep();
						this.setState({ isLoading: false, page: page });
					}
				});
			} else {
				console.error(responses);
			}
		});
	};

	/**
	 * Component will unmount
	 */
	componentWillUnmount = () => {
		/* Unsubscribe from game / group */
		if (this.unsubscribeGame !== null) this.unsubscribeGame();
		if (this.unsubscribeGroup !== null) this.unsubscribeGroup();
	};

	/**
	 * Get company data
	 */
	getCompanyData = () => {
		return new Promise((resolve) => {
			const db = firebase.firestore();
			db.collection('companies').doc(this.state.game.companyId).get().then((companyDoc) => {
				if (companyDoc.exists) {
					let companyData = {...companyDoc.data(), id: companyDoc.id};
					this.setState({companyData}, () => {resolve({status: 'ok'});});
				} else {
					console.error('Company does not exist: ', this.state.game.companyId);
					resolve({status: 'error'});
				}
			}).catch(() => {
				console.error('Company does not exist: ', this.state.game.companyId);
				resolve({status: 'error'});
			});
		});
	};

	/**
	 * Subscribe to game
	 * @param {string} gameId
	 */
	subscribeToGame = (gameId) => {
		if (this.unsubscribeGame !== null) this.unsubscribeGame();
		const db = firebase.firestore();
		return new Promise((resolve) => {
			this.unsubscribeGame = db.collection(appConfig.gamesDbName).doc(gameId).onSnapshot((doc) => {
				if (doc.exists) {
					let data = doc.data();
					data.id = doc.id;
					if (
						!data.languageId || 
						!languagesData.some((ld) => {return ld.id === data.languageId;})
					) data.languageId = appConfig.defaultLanguage;
					let prevGameData = this.state.game;
					this.setState({ game: data }, () => {
						this.checkGameUpdates(prevGameData);
						resolve({ status: 'ok' });
					});
				} else {
					/* No game, probably deleted so log out group */
					this.props.handleLogout();
				}
			},
			(error) => {
				console.error('could not get game: ', error);
				resolve({ status: 'error', error: error });
			}
			);
		});
	};

	/**
	 * Subscribe to own group
	 * @param {string} groupId
	 */
	subscribeToGroup = (groupId) => {
		if (this.unsubscribeGroup !== null) this.unsubscribeGroup();
		const db = firebase.firestore();
		return new Promise((resolve) => {
			this.unsubscribeGroup = db
				.collection(appConfig.groupsDbName)
				.doc(groupId)
				.onSnapshot(
					(doc) => {
						if (doc.exists) {
							let data = doc.data();
							data.id = doc.id;
							let prevGroupData = this.state.group;
							this.setState({ group: data }, () => {
								this.checkGroupUpdates(prevGroupData);
								resolve({ status: 'ok' });
							});
						}
					},
					(error) => {
						console.error('could not get group: ', error);
						resolve({ status: 'error', error: error });
					}
				);
		});
	};

	/**
	 * Check game updates
	 * @param {object} prevGameData
	 */
	checkGameUpdates = (prevGameData) => {
		/* Facilitator reset game */
		if (prevGameData && prevGameData.gamePhase && prevGameData.gamePhase > this.state.game.gamePhase) {
			let page = this.getPageOfCurrentGameStep();
			this.handleGoToPage(page);
		}
	};

	/**
	 * Check group updates
	 * @param {object} prevGroupData
	 */
	checkGroupUpdates = (prevGroupData) => {
		/* Game progressed, go to new game step */
		if (prevGroupData && prevGroupData.gameStep !== this.state.group.gameStep) {
			let page = this.getPageOfCurrentGameStep();
			this.handleGoToPage(page);
		}
	};

	/**
	 * Get page of current game step
	 */
	getPageOfCurrentGameStep = () => {
		let page = 'welcome';
		let gamePhase = this.state.game.gamePhase ? this.state.game.gamePhase : 1;
		let gameStep = this.state.group.gameStep ? this.state.group.gameStep : 'welcome';
		let gameSteps = gamesData[this.state.game.type].gameSteps;
		let gameStepData = gameSteps.find((step) => {return step.id === gameStep;});

		if (gameStepData) {
			if (gameStepData.phase <= gamePhase) {
				/* Game step belongs to current / past game phase */
				page = gameStepData.page;
			} else {
				/* Game step belongs to future game phase (facilitator did a reset)
						Get first game step of game phase */
				let gameStepsInGamePhase = gameSteps.filter((step) => {
					return step.phase === gamePhase;
				});
				if (gameStepsInGamePhase.length > 0) {
					let firstGameStepInPhase = sortArrayByProperty(gameStepsInGamePhase, 'index', 'ASC')[0].id;
					page = firstGameStepInPhase.page;
					this.updateGroup({ gameStep: firstGameStepInPhase });
				}
			}
		}
		return page;
	};


	goToPrevGameStep = (gameStepId) => {
		let gameStep = this.state.group.gameStep;
		if (gameStep === gameStepId) {
			let gameSteps = gamesData[this.state.game.type].gameSteps;
			let gameStepIndex = gameSteps.findIndex((step) => {return step.id === gameStep;});
			let prevGameStepData = gameSteps[gameStepIndex - 1];
			this.updateGroup({gameStep: prevGameStepData.id}).then(() => {
				this.handleGoToPage(prevGameStepData.page);
			});
		}
	};

	/**
	 * Go to page
	 * @param {string} page
	 */
	handleGoToPage = (page) => {
		this.setState({ page: page });
	};

	/**
	 * Confirm current page and move to next
	 * @param {string} gameStep
	 * @param {obj} updates
	 */
	confirmAndContinue = (gameStep, updates = null) => {
		const gameSteps = gamesData[this.state.game.type].gameSteps;
		let gameStepIndex = gameSteps.findIndex((step) => {return step.id === gameStep;});
		if (gameStepIndex >= 0 && gameStepIndex + 1 < gameSteps.length) {
			let nextGameStepData = gameSteps[gameStepIndex + 1];
			if (nextGameStepData.phase <= this.state.game.gamePhase) {
				if (gameStep === this.state.group.gameStep) {
					if (updates && typeof updates === 'object') {
						this.updateGroup({ gameStep: nextGameStepData.id, ...updates });	
					} else {
						this.updateGroup({ gameStep: nextGameStepData.id });
					}
				} else {
					this.handleGoToPage(nextGameStepData.page);
				}
			}
		}
	};

	/**
	 * Update group
	 * @param {object} updates
	 * @returns {promise}
	 */
	updateGroup = (updates) => {
		/* Nothing to update */
		if (Object.keys(updates).length === 0 && updates.constructor === Object) {
			return new Promise((resolve)=>{resolve();});
		}

		/* Update group */
		let groupId = this.props.userId;
		let db = firebase.firestore();
		let groupRef = db.collection(appConfig.groupsDbName).doc(groupId);
		return groupRef.update(updates);
	};


	/**
	 * CRM Gameboard: Confirm resources placements in current round
	 * @param {string} gameStep
	 */
	confirmResourcePlacements = (gameStep) => {
		return new Promise((resolve, reject) => {
			apiHelper('group/confirm-resource-placements', {
				groupId: this.state.group.id,
				gameStep: gameStep,
			}).then(
				(response) => {resolve(response);},
				(rejection) => {reject(rejection);}
			);
		});
	};

	/**
	 * ST Gameboard: Confirm selected options in current round
	 * @param {string} gameStep
	 */
	confirmSelectedOptions = (gameStep) => {
		return new Promise((resolve, reject) => {
			apiHelper('group/confirm-selected-options', {
				groupId: this.state.group.id,
				gameStep: gameStep,
			}).then(
				(response) => {resolve(response);},
				(rejection) => {reject(rejection);}
			);
		});
	};

	/**
	 * Render component
	 */
	render() {
		/* Loading */
		if (this.state.isLoading) {
			return <Loading />;
		}

		/* Error: Unknown game id*/
		if (!gamesData.hasOwnProperty(this.state.game.type)) {
			return <div>Unknown game type: {this.state.game.type}</div>;
		}

		/* Ready */
		switch (this.state.page) {
		case 'welcome':
			return (
				<>{this.state.game.isPaused && <div className="GameBoard-Disabled"></div>}
					<Welcome
						isFacilitator={false}
						game={this.state.game}
						gameStep={this.state.group.gameStep}
						handleGoToPage={this.handleGoToPage}
						confirmAndContinue={this.confirmAndContinue}
						handleLogout={this.props.handleLogout}
					/>
				</>
			);
		case 'crew-select':
			return (
				<>{this.state.game.isPaused && <div className="GameBoard-Disabled"></div>}
					<CrewSelect
						isFacilitator={false}	
						game={this.state.game}
						selectedCrew={this.state.group.selectedCrew ? this.state.group.selectedCrew : []}
						handleGoToPage={this.handleGoToPage}
						updateGroup={this.updateGroup}
						confirmAndContinue={this.confirmAndContinue}
						handleLogout={this.props.handleLogout}
					/>
				</>
			);
		case 'crew-feedback':
			return (
				<>{this.state.game.isPaused && <div className="GameBoard-Disabled"></div>}
					<CrewFeedback
						isFacilitator={false}
						game={this.state.game}
						group={this.state.group}
						handleGoToPage={this.handleGoToPage}
						updateGroup={this.updateGroup}
						confirmAndContinue={this.confirmAndContinue}
					/>
				</>
			);
		case 'crew-feedback-result':
		case 'ground-result':
		case 'flights-result':
		case 'game-result':
			return (
				<>{this.state.game.isPaused && <div className="GameBoard-Disabled"></div>}
					<GameResultController
						isFacilitator={false}
						game={this.state.game}
						companyData={this.state.companyData}
						gameStep={this.state.group.gameStep}
						handleGoToPage={this.handleGoToPage}
						confirmAndContinue={this.confirmAndContinue}						
					/>
				</>
			);
		case 'reflection':
		case 'crew-reflection':
		case 'ground-reflection':
		case 'dd-reflection':
		case 'final-reflection':
			return (
				<>{this.state.game.isPaused && <div className="GameBoard-Disabled"></div>}
					<Reflection
						isFacilitator={false}
						page={this.state.page}
						game={this.state.game}
						group={this.state.group}
						handleGoToPage={this.handleGoToPage}
						updateGroup={this.updateGroup}
						confirmAndContinue={this.confirmAndContinue}
					/>
				</>
			);
		case 'reflection-result':
		case 'crew-reflection-result':
		case 'ground-reflection-result':
		case 'best-practices-result':
		case 'dd-reflection-result':
		case 'final-reflection-result':
			return (
				<>{this.state.game.isPaused && <div className="GameBoard-Disabled"></div>}
					<ReflectionResult
						isFacilitator={false}
						game={this.state.game}
						companyData={this.state.companyData}
						gameStep={this.state.group.gameStep}
						handleGoToPage={this.handleGoToPage}
						goToPrevGameStep={this.goToPrevGameStep}
						confirmAndContinue={this.confirmAndContinue}
					/>
				</>
			);
		case 'game-board-intro':
			return (
				<>{this.state.game.isPaused && <div className="GameBoard-Disabled"></div>}
					<GameBoardIntro 
						isFacilitator={false}
						game={this.state.game}
						gameStep={this.state.group.gameStep}
						handleGoToPage={this.handleGoToPage}
						confirmAndContinue={this.confirmAndContinue}
					/>
				</>
			);
		case 'game-board':
			let GameBoardComponent = GameBoardCRMController;
			if (this.state.game.type === 'safetytraining') GameBoardComponent = GameBoardSTController;
			return (
				<>{this.state.game.isPaused && <div className="GameBoard-Disabled"></div>}
					<GameBoardComponent
						isFacilitator={false}
						game={this.state.game}
						group={this.state.group}
						handleGoToPage={this.handleGoToPage}
						updateGroup={this.updateGroup}
						confirmResourcePlacements={this.confirmResourcePlacements}
						confirmSelectedOptions={this.confirmSelectedOptions}
						confirmAndContinue={this.confirmAndContinue}
					/>
				</>
			);
		case 'best-practices':
			return (
				<>{this.state.game.isPaused && <div className="GameBoard-Disabled"></div>}
					<BestPracticesController
						isFacilitator={false}
						game={this.state.game}
						group={this.state.group}
						handleGoToPage={this.handleGoToPage}
						updateGroup={this.updateGroup}
						confirmAndContinue={this.confirmAndContinue}
					/>
				</>
			);
		case 'best-practices-benefits':
			return (
				<>{this.state.game.isPaused && <div className="GameBoard-Disabled"></div>}
					<BestPracticesBenefitsController 
						isFacilitator={false}
						game={this.state.game}
						group={this.state.group}
						handleGoToPage={this.handleGoToPage}
						updateGroup={this.updateGroup}
						confirmAndContinue={this.confirmAndContinue}
					/>
				</>
			);
		case 'decision-making':
			return (
				<>{this.state.game.isPaused && <div className="GameBoard-Disabled"></div>}
					<DecisionMakingController 
						isFacilitator={false}
						game={this.state.game}
						group={this.state.group}
						handleGoToPage={this.handleGoToPage}
						updateGroup={this.updateGroup}
						confirmAndContinue={this.confirmAndContinue}
					/>
				</>
			);
		case 'decision-making-result':
			return (
				<>{this.state.game.isPaused && <div className="GameBoard-Disabled"></div>}
					<DecisionMakingResultController 
						isFacilitator={false}
						game={this.state.game}
						companyData={this.state.companyData}
						gameStep={this.state.group.gameStep}
						handleGoToPage={this.handleGoToPage}
						goToPrevGameStep={this.goToPrevGameStep}
						confirmAndContinue={this.confirmAndContinue}
					/>
				</>
			);
		case 'communication':
			return (
				<>{this.state.game.isPaused && <div className="GameBoard-Disabled"></div>}
					<CommunicationController 
						isFacilitator={false}
						game={this.state.game}
						group={this.state.group}
						handleGoToPage={this.handleGoToPage}
						updateGroup={this.updateGroup}
						confirmAndContinue={this.confirmAndContinue}
					/>
				</>
			);
		case 'communication-result':
			return (
				<>{this.state.game.isPaused && <div className="GameBoard-Disabled"></div>}
					<CommunicationResultController 
						isFacilitator={false}
						game={this.state.game}
						companyData={this.state.companyData}
						gameStep={this.state.group.gameStep}
						handleGoToPage={this.handleGoToPage}
						goToPrevGameStep={this.goToPrevGameStep}
						confirmAndContinue={this.confirmAndContinue}
					/>
				</>
			);
		case 'exercise':
			return (
				<>{this.state.game.isPaused && <div className="GameBoard-Disabled"></div>}
					<ExerciseController 
						isFacilitator={false}
						game={this.state.game}
						group={this.state.group}
						handleGoToPage={this.handleGoToPage}
						updateGroup={this.updateGroup}
						confirmAndContinue={this.confirmAndContinue}
					/>
				</>
			);
		case 'exercise-result':
			return (
				<>{this.state.game.isPaused && <div className="GameBoard-Disabled"></div>}
					<ExerciseResultController 
						isFacilitator={false}
						game={this.state.game}
						companyData={this.state.companyData}
						gameStep={this.state.group.gameStep}
						handleGoToPage={this.handleGoToPage}
						goToPrevGameStep={this.goToPrevGameStep}
						confirmAndContinue={this.confirmAndContinue}
					/>
				</>
			);
		case 'gameover':
			return (
				<>{this.state.game.isPaused && <div className="GameBoard-Disabled"></div>}
					<Gameover
						languageId={this.state.game.languageId}
						gameType={this.state.game.type}
						handleLogout={this.props.handleLogout}
						handleGoToPage={this.handleGoToPage}
					/>
				</>
			);
		default:
			return <div>Unknown page: {this.state.page}</div>;
		}
	}
}

GroupController.propTypes = {
	gameType: PropTypes.string.isRequired,
	userId: PropTypes.string.isRequired,
	handleLogout: PropTypes.func.isRequired
};

export default GroupController;
