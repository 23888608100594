import React from 'react';
import PropTypes from 'prop-types';
import {loginUiTexts} from 'data/ui-texts';
import {getText} from 'helpers/language-helper';
import Logo from 'components/logo/logo';
import './admin-login.scss';
// TODO: 'en' -> languageId
const AdminLogin = ({
	isCheckingLogin,
	isLoggingIn,
	invalidInputEmail,
	invalidInputPassword,
	email,
	password,
	feedback,
	handleInput,
	handleLogin,	
}) => {

	return (
		<div className="AdminLogin">
			<div className="AdminLogin-container">
				<Logo classes={['login']} />
				<div className="AdminLogin-gameTitle">Admin</div>
				<div className="AdminLogin-formContainer">
					{isCheckingLogin && <div className="AdminLogin-loadingWrapper" />}
					{!isCheckingLogin && <form className="AdminLogin-form" onSubmit={handleLogin}>
						<input
							className={invalidInputEmail ? ' invalid' : ''}
							name="email"
							type="text"
							placeholder={getText(loginUiTexts.email, 'en')} 
							autoComplete="section-adminlogin email"
							value={(email ? email : '')} 
							onChange={(event)=>{handleInput(event);}}
						/>
						<input
							className={invalidInputPassword ? ' invalid' : ''}
							name="password"
							type="password"
							placeholder={getText(loginUiTexts.password, 'en')}
							autoComplete="section-adminlogin password"
							value={(password ? password : '')} 
							onChange={(event)=>{handleInput(event);}}
						/>
						<p className={'AdminLogin-errorMessage'}>{feedback}</p> 
						<div 
							className={'AdminLogin-button' + (isLoggingIn ? ' loading' : '')} 
							onClick={(event)=>{handleLogin(event);}}
						>{getText(loginUiTexts.login, 'en')}</div>
					</form>}
				</div>
			</div>
		</div>
	);
};

AdminLogin.propTypes = {
	isCheckingLogin: PropTypes.bool.isRequired,
	isLoggingIn: PropTypes.bool.isRequired,
	invalidInputEmail: PropTypes.bool.isRequired,
	invalidInputPassword: PropTypes.bool.isRequired,
	email: PropTypes.string,
	password: PropTypes.string,
	feedback: PropTypes.string,
	handleLogin: PropTypes.func.isRequired,
	handleInput: PropTypes.func.isRequired	
};

export default AdminLogin;