import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {calculateCardEffects} from 'helpers/group-game-helper-st';
import {gamesData} from 'data/games-data';
import GameBoardST from './game-board-st';
import InfoPopup from 'components/info-popup/info-popup';
import DirtyDozenInfoPopup from 'components/dirty-dozen-info-popup/dirty-dozen-info-popup';

class GameBoardSTController extends Component {
	constructor(props) {
		super(props);
		this.state = {
			delayDealingCards: true,
			showInfoPopup: false,
			dirtyDozenId: null,
			isConfirmingSelectedOptions: false,
			resolvedEventCardIndex: -1
		};
		this.timeout = null;
	}

	componentDidMount = () => {
		if (!this.props.isFacilitator) {
			/* Show info popup */
			const gameStepData = gamesData[this.props.game.type].gameSteps.find((step) => {
				return step.id === this.props.group.gameStep;
			});
			if (gameStepData.infoPopup && gameStepData.infoPopup.autoShow === true) {
				this.setState({ showInfoPopup: true });
			} else {
				this.setState({delayDealingCards: false});
			}

			/* Reset dirty dozen of crew staff that did nothing the previous round */
			if (this.props.group.gameStepCards.hasOwnProperty(this.props.group.gameStep)) {
				if (
					this.props.group.gameStepCards[this.props.group.gameStep].prevDayGameStepId &&
				!this.props.group.gameStepCards[this.props.group.gameStep].cardsAdjusted
				) {
					this.resetDirtyDozen();
				}
			}
		} else {
			this.setState({delayDealingCards: false});
		}
	};

	/**
	 * Component did update
	 * @param {object} prevProps
	 */
	componentDidUpdate = (prevProps) => {
		if (!this.props.isFacilitator) {
			const gameStep = this.props.group.gameStep;
			/* Same game step */
			if (prevProps.group.gameStep === this.props.group.gameStep) {
				/* Selected options have just been confirmed */
				if (
					prevProps.group.hasOwnProperty('gameStepCards') &&
					prevProps.group.gameStepCards.hasOwnProperty(gameStep) &&
					!prevProps.group.gameStepCards[gameStep].selectedOptionsConfirmed &&
					this.props.group.gameStepCards[gameStep].selectedOptionsConfirmed
				) {
					/* Automatically show effects of first card */
					this.timeout = setTimeout(() => {this.handleApplyEventCardEffects(true, -1);}, 500);
				}

				/* Check if a new event card has been resolved */
				if (
					prevProps.group.hasOwnProperty('gameStepCards') &&
					this.props.group.hasOwnProperty('gameStepCards') &&
					prevProps.group.gameStepCards.hasOwnProperty(gameStep) &&
					this.props.group.gameStepCards.hasOwnProperty(gameStep) &&
					this.props.group.gameStepCards[gameStep].selectedOptionsConfirmed
				) {
					let resolvedEventCardIndex = -1;
					this.props.group.gameStepCards[gameStep].cards.forEach((card, index) => {
						if (card.effectsApplied === true) {
							if (prevProps.group.gameStepCards[gameStep].cards[index].effectsApplied !== true) {
								resolvedEventCardIndex = index;
							}
						}
					});
					if (resolvedEventCardIndex >= 0) {
						this.timeout = setTimeout(() => {this.setState({resolvedEventCardIndex});}, 1000);
						this.timeout = setTimeout(() => {this.setState({resolvedEventCardIndex: -1});}, 4000);
					}
				}
			/* New game step */
			} else {
				/* Show briefing popup */
				const gameStepData = gamesData[this.props.game.type].gameSteps.find((step) => {
					return step.id === this.props.group.gameStep;
				});
				if (
					gameStepData.page === 'game-board' && 
					gameStepData.infoPopup && 
					gameStepData.infoPopup.autoShow === true
				) {
					this.setState({ showInfoPopup: true, delayDealingCards: true });
				}
		
				/* Reset dirty dozen of crew staff that did nothing the previous round */
				if (
					prevProps.group.gameStep !== gameStep &&
					this.props.group.hasOwnProperty('gameStepCards') &&
					this.props.group.gameStepCards.hasOwnProperty(gameStep) &&
					this.props.group.gameStepCards[gameStep].prevDayGameStepId &&
					!this.props.group.gameStepCards[gameStep].cardsAdjusted
				) {
					this.resetDirtyDozen();
				}
			}
		}
	};

	/**
	 * Hide / show briefing popup
	 */
	toggleInfoPopup = (show = null) => {
		let showInfoPopup = !this.state.showInfoPopup;
		if (show !== null) showInfoPopup = show;
		this.setState({showInfoPopup, delayDealingCards: false});
	};

	/**
	 * Hide / show dirty dozen info popup
	 * @param {number} dirtyDozenId 
	 */
	toggleDirtyDozenPopup = (dirtyDozenId) => {
		this.setState({dirtyDozenId});
	};

	/**
	 * Reset dirty dozen of inactive staff member of previous round
	 */
	resetDirtyDozen = () => {
		let prevDayGameStepId = this.props.group.gameStepCards[this.props.group.gameStep].prevDayGameStepId;
		if (prevDayGameStepId && this.props.group.gameStepCards.hasOwnProperty(prevDayGameStepId)) {
			let prevDayGameStep = this.props.group.gameStepCards[prevDayGameStepId];
			if (prevDayGameStep.inactiveCrewMemberId) {
				let selectedCrew = [...this.props.group.selectedCrew];
				let indexOfInactiveCrewMember = selectedCrew.findIndex((c) => {
					return c.id === prevDayGameStep.inactiveCrewMemberId;
				});
				if (indexOfInactiveCrewMember >= 0) {
					const crewMemberData = gamesData[this.props.game.type].crewData.availableCrew.find((c) => {
						return c.id === prevDayGameStep.inactiveCrewMemberId;
					});
					selectedCrew[indexOfInactiveCrewMember].dirtyDozenIds = crewMemberData.dirtyDozenIds;

					let gameStepCards = JSON.parse(JSON.stringify(this.props.group.gameStepCards));
					gameStepCards[this.props.group.gameStep].cardsAdjusted = true;
					this.props.updateGroup({selectedCrew: selectedCrew, gameStepCards: gameStepCards});
				}
			}
		} 
	};

	/**
	 * Move crew member to / from event card option
	 * @param {array} newGameStepCards
	 */
	handleMoveCrew = (newGameStepCards) => {
		if (this.props.isFacilitator) return;
		this.props.updateGroup({gameStepCards: newGameStepCards});
	};

	/**
	 * Confirm selected opresolvedEventCardIndextions
	 */
	handleConfirmSelectedOptions = () => {
		if (this.props.isFacilitator) return;

		this.setState({isConfirmingSelectedOptions: true}, () => {
			this.props.confirmSelectedOptions(this.props.group.gameStep).then(() => {
				/* componentDidUpdate() reacts to resource placements being confirmed */
				this.setState({isConfirmingSelectedOptions: false});
			}, (error) => {
				console.error(error);
				this.setState({isConfirmingSelectedOptions: false});
			});
		});
	};

	/**
	 * Calculate and apply event card effects
	 * @param {bool} firstCard
	 * @param {number} index
	 */
	handleApplyEventCardEffects = (firstCard = false, index = -1) => {
		if (this.props.isFacilitator) return;

		let groupUpdates = calculateCardEffects(
			firstCard, 
			index, 
			this.props.group, 
			this.props.game.type, 
			this.props.game.scenario
		);
		this.props.updateGroup(groupUpdates);
	};
	
	/**
	 * Render component
	 */
	render() {
		return (
			<React.Fragment>
				<GameBoardST
					languageId={this.props.game.languageId}
					gameType={this.props.game.type}
					gameScenario={this.props.game.scenario}
					isFacilitator={this.props.isFacilitator}
					delayDealingCards={this.state.delayDealingCards}
					isConfirmingSelectedOptions={this.state.isConfirmingSelectedOptions}
					gameIsPaused={this.props.game.isPaused}
					gamePhase={this.props.game.gamePhase}
					group={this.props.group}
					resolvedEventCardIndex={this.state.resolvedEventCardIndex}
					toggleInfoPopup={this.toggleInfoPopup}
					toggleDirtyDozenPopup={this.toggleDirtyDozenPopup}
					handleGoToPage={this.props.handleGoToPage}
					handleMoveCrew={this.handleMoveCrew}
					handleConfirmSelectedOptions={this.handleConfirmSelectedOptions}
					handleApplyEventCardEffects={this.handleApplyEventCardEffects}
					confirmAndContinue={this.props.confirmAndContinue}
				/>
				{this.state.showInfoPopup && <InfoPopup
					canToggle={true}
					game={this.props.game}
					gameStep={this.props.group.gameStep}
					toggleInfoPopup={this.toggleInfoPopup}
					handleLogout={null}
				/>}
				{this.state.dirtyDozenId && 
					<DirtyDozenInfoPopup 
						dirtyDozenId={this.state.dirtyDozenId} 
						languageId={this.props.game.languageId}
						togglePopup={this.toggleDirtyDozenPopup}
					/>
				}
			</React.Fragment>

		);
	}
}

GameBoardSTController.propTypes = {
	isFacilitator: PropTypes.bool.isRequired,
	game: PropTypes.object.isRequired,
	group: PropTypes.object.isRequired,
	handleGoToPage: PropTypes.func.isRequired,
	updateGroup: PropTypes.func,
	confirmSelectedOptions: PropTypes.func.isRequired,
	confirmAndContinue: PropTypes.func.isRequired,
};

export default GameBoardSTController;
