import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {renderMarkdown} from 'helpers/text-helper';
import {generalUiTexts} from 'data/ui-texts';
import {gamesData} from 'data/games-data';
import {getText} from 'helpers/language-helper';
import {getConfirmButtonData} from 'helpers/confirm-button-helper';
import Logo from 'components/logo/logo';
import Button from 'components/button/button';
import ProgressBar from 'components/progress-bar/progress-bar';
import ReflectionScale from './reflection-scale';
import ReflectionDD from './reflection-dd';
import ReflectionFP from './reflection-fp';
import ReflectionTopics from './reflection-topics';
import ThreatPopup from 'components/threat-popup/threat-popup';
import './reflection.scss';

const Reflection = (props) => {
	const {
		isFacilitator,
		page,
		game,
		group,
		handleGoToPage,
		handleSelectOption,
		handleInputText,
		handleUpdateDirtyDozenOrder,
		handleUpdateFocusPointsOrder,
		handleUpdateSelectedTopics,
		confirmAndContinue,
		toggleInfoPopup
	} = props;

	/* Get data */
	const gameStepData = gamesData[game.type].gameSteps.find((g) => {return g.id === group.gameStep;});
	const scenarioData = gamesData[game.type].scenarios.find((sc) => {return sc.id === game.scenario;});
	const threatsData = scenarioData.threatsData;
	const reflectionsData = gamesData[game.type].reflectionsData;

	/* Get data for this game step's reflection  */
	let [reflectionData] = useState(
		reflectionsData.find((reflection) => {return reflection.gameStep === group.gameStep;})
	);

	/* Get event cards that have resulted in threats */
	let threatCards = null;
	if (reflectionData && reflectionData.showThreats && group.threats) {
		threatCards = [];
		group.threats.forEach((threat) => {
			let threatData = threatsData.find((t) => {return t.id === threat;});
			if (threatData) threatCards.push({ id: threatData.id, title: getText(threatData.title, game.languageId)});
		});
	}

	/* Threat popup */
	let [threatId, setThreatId] = useState(null);
	const toggleThreatPopup = (threatId) => {
		setThreatId(threatId);
	};

	
	/* Check if all required questions have been answered */
	let allRequiredFieldsFilledOut = true;
	if (reflectionData && reflectionData.questions) {
		reflectionData.questions.forEach((question) => {
			if (question.required) {
				if (
					!group.reflectionAnswers.some((answer) => {
						return (
							answer.gameStep === group.gameStep &&
							answer.id === question.id &&
							answer.value &&
							(
								(typeof answer.value === 'number' && answer.value > 0) ||
								(typeof answer.value === 'string' && answer.value.length > 0)
							)
						);
					})
					/* Not all required fields filled out */
				) { allRequiredFieldsFilledOut = false; }
			}
		});
	}

	/* Check if required number of topics have been selected */
	if (reflectionData.numberOfTopicsToSelect && reflectionData.topics && reflectionData.topics.length > 0) {
		let numberOfSelectedTopics = (group.selectedTopics 
			? group.selectedTopics.length
			: 0
		);
		if (numberOfSelectedTopics < reflectionData.numberOfTopicsToSelect) allRequiredFieldsFilledOut = false;
	}

	/* Confirmation button data */
	let confirmBtnData = getConfirmButtonData(allRequiredFieldsFilledOut, game, group.gameStep);
	if (game.type === 'safetytraining') confirmBtnData.classes.push('st');
	
	/* Load and store answer values locally */
	const [answers, setAnswers] = useState([]);
	useEffect(() => {
		if (reflectionData && reflectionData.questions) {
			let initAnswersArray = [];
			reflectionData.questions.forEach((question) => {
				/* Answer template  */
				let initAnswer = {
					gameStep: group.gameStep,
					id: question.id,
					value: ''
				};
				/* Group answer */		
				const groupAnswer = group.reflectionAnswers.find((a) => {
					return (a.gameStep === group.gameStep && a.id === question.id);
				});
				if (groupAnswer && groupAnswer.value) initAnswer.value = groupAnswer.value;
				initAnswersArray.push(initAnswer);
			});
			setAnswers(initAnswersArray);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [group.gameStep, reflectionData]);


	/* Update locally stored answers when props are updated */
	useEffect(()=>{
		if (reflectionData) {
			setAnswers((answers) => {
				let newAnswers = JSON.parse(JSON.stringify(answers));
				reflectionData.questions.forEach((question) => {
					const newAnswerIndex = newAnswers.findIndex((a) => {return a.id === question.id;});
					const groupAnswer = group.reflectionAnswers.find((a) => {
						return (a.gameStep === group.gameStep && a.id === question.id);
					});		
					if (newAnswerIndex >= 0 && groupAnswer && question.answerType) {
					/* Answer type: scale, always overwrite local version */
						if (question.answerType === 'scale') newAnswers[newAnswerIndex].value = groupAnswer.value;
						/* Answer type: text, only overwrite if local version is different */
						if (question.answerType === 'text') {
							if (newAnswers[newAnswerIndex].value !== groupAnswer.value) {
								newAnswers[newAnswerIndex].value = groupAnswer.value;
							}
						}
					}
				});
				return newAnswers;
			});
		}
	}, [group.gameStep, group.reflectionAnswers, reflectionData]);


	/* Update locally stored answers & database when user is typing */
	const handleTextChange = (e, id, gameStep) => {
		setAnswers((answers) => {
			let newAnswers = JSON.parse(JSON.stringify(answers));
			answers.forEach((answer, i) => {
				if (answer.id === id && answer.gameStep === gameStep) newAnswers[i].value = e.target.value;
			});
			return newAnswers;
		});
		handleInputText(e);
	};	

	/* Unknown reflection */
	if (!reflectionData) {
		return (
			<div className="ReflectionResult">
				{game.type !== 'safetytraining' && <Logo onClick={() => {handleGoToPage('welcome');}}/>}
				<ProgressBar 
					gameType={game.type}
					title={getText(gameStepData.title, game.languageId)} 
					subtitle={getText(gameStepData.subtitle, game.languageId)} 
					linkText={null}
					toggleInfo={null}
					onClick={() => {handleGoToPage('welcome');}}
				/>
				<Button
					isDisabled={confirmBtnData.isDisabled}
					text={confirmBtnData.text}
					classes={confirmBtnData.classes}
					onClick={() => {confirmAndContinue(group.gameStep);}}
				/>
			</div>
		);
	}

	/* Known reflection */
	return (
		<div className="Reflection">
			{game.type !== 'safetytraining' && <Logo onClick={() => {handleGoToPage('welcome');}} />}
			<ProgressBar 
				gameType={game.type}
				title={getText(reflectionData.title, game.languageId)} 
				subtitle={getText(reflectionData.subtitle, game.languageId)} 
				linkText={(game.type !== 'safetytraining' && gameStepData.infoPopup 
					? getText(generalUiTexts.flightInfo, game.languageId) : null)}
				toggleInfo={(game.type !== 'safetytraining' && gameStepData.infoPopup 
					? toggleInfoPopup : null)}
				onClick={(game.type === 'safetytraining' ? () => {handleGoToPage('welcome');} : null)}
			/>
			{(game.type === 'safetytraining') &&
				<Button
					text={''}
					classes={['st', 'info']}
					onClick={() => {toggleInfoPopup();}}
				/>}
			<Button
				isDisabled={confirmBtnData.isDisabled}
				text={confirmBtnData.text}
				classes={confirmBtnData.classes}
				onClick={() => {confirmAndContinue(group.gameStep);}}
			/>

			{/* Reflection questions */}
			<div className={'Reflection-questions ' + page + ' ' + game.type + 
				(reflectionData.showFocusPoints ? ' focus-points' : '') +
				(reflectionData.topics && reflectionData.topics.length > 0 ? ' topics' : '') +
				(threatCards ? ' small' : '')}>
				{reflectionData.introText && 
					<div className="Reflection-introText">
						{renderMarkdown(getText(reflectionData.introText, game.languageId))}
					</div>
				}
				{(reflectionData && reflectionData.questions) && reflectionData.questions.map((question) => {
					let answer = null;
					let reflectionAnswer = answers.find((answer) => {
						return answer.gameStep === group.gameStep && answer.id === question.id;
					});
					if (reflectionAnswer) answer = reflectionAnswer.value;
					return (
						<div 
							key={question.id} 
							className={`Reflection-question ${question.answerType === 'scale' 
								? 'Reflection-question--scale' : ''} ${question.answerType === 'intro' 
								? 'Reflection-question--intro' : ''}`}
						>
							<div className="Reflection-questionText">
								{renderMarkdown(getText(question.text, game.languageId))}
							</div>
							{question.answerType !== 'intro' && <div className="Reflection-answer">
								{/* Question type: scale */}
								{question.answerType === 'scale' && (
									<ReflectionScale
										languageId={game.languageId}
										questionId={question.id}
										selectedValue={answer ? answer : 0}
										handleSelectOption={handleSelectOption}
									/>
								)}
								{/* Question type: text input */}
								{question.answerType === 'text' && (
									<textarea
										id={question.id}
										className="Reflection-textarea"
										name={question.id}
										placeholder=
											{getText(generalUiTexts.reflection.textPlaceholder, game.languageId)}
										rows={question.rows ? question.rows : 5}
										value={answer ? answer : ''}
										onChange={(event) => {handleTextChange(event, question.id, group.gameStep);}}
									></textarea>
								)}
							</div>}
						</div>
					);
				})}
				{!reflectionData && <p>{getText(generalUiTexts.reflection.unknownReflection, game.languageId)}</p>}
			</div>

			{/* Threat cards */}
			{threatCards && (
				<div className="Reflection-threatCards">
					<div className="Reflection-threatsTitle">{getText(generalUiTexts.threats, game.languageId)}</div>
					{threatCards.map((card) => {
						let isTriggered = (group.triggeredThreats && group.triggeredThreats.indexOf(card.id) >= 0);
						return (
							<div
								key={card.id}
								className={'Reflection-threatCard' + (isTriggered ? ' triggered' : '')}
								onClick={() => {toggleThreatPopup(card.id);}}
							>
								<span>{card.title}</span>
							</div>
						);
					})}
				</div>
			)}

			{/* Dirty Dozen sortable */}
			{reflectionData.showDirtyDozens === true &&
				<ReflectionDD 
					languageId={game.languageId} 
					group={group} 
					handleUpdateDirtyDozenOrder={handleUpdateDirtyDozenOrder} 
				/>
			}

			{/* Focus points sortable */}
			{reflectionData.showFocusPoints === true &&
				<ReflectionFP 
					languageId={game.languageId} 
					group={group} 
					focusPoints={reflectionData.focusPoints}
					handleUpdateFocusPointsOrder={handleUpdateFocusPointsOrder} 
				/>
			}

			{/* Topics select */}
			{reflectionData.topics && reflectionData.topics.length > 0 && 
				<ReflectionTopics 
					languageId={game.languageId}	
					group={group} 
					reflectionData={reflectionData}
					handleUpdateSelectedTopics={handleUpdateSelectedTopics}
				/>
			}

			{/* Threat popup */}
			{(threatId && !isFacilitator) && 
				<ThreatPopup 
					languageId={game.languageId}
					gameType={game.type}
					gameScenario={game.scenario}
					threatId={threatId} 
					group={group} 
					handleTogglePopUpThreat={toggleThreatPopup} 
				/>
			}
		</div>
	);
};

Reflection.propTypes = {
	isFacilitator: PropTypes.bool.isRequired,
	page: PropTypes.string.isRequired,
	game: PropTypes.object.isRequired,
	group: PropTypes.object.isRequired,
	handleGoToPage: PropTypes.func.isRequired,
	handleSelectOption: PropTypes.func.isRequired,
	handleInputText: PropTypes.func.isRequired,
	handleUpdateDirtyDozenOrder: PropTypes.func.isRequired,
	handleUpdateFocusPointsOrder: PropTypes.func.isRequired,
	handleUpdateSelectedTopics: PropTypes.func.isRequired,
	confirmAndContinue: PropTypes.func.isRequired,
	toggleInfoPopup: PropTypes.func.isRequired,
};

export default Reflection;
