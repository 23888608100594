import { gamesData } from 'data/games-data';

function replaceTextPlaceholders(text, name = null, gender = null) {
	let newText = JSON.parse(JSON.stringify(text));
	
	/* Name */
	if (name) newText = newText.replace(/%name%/g, name);

	/* Gender (pronouns) */
	if (gender) {
		const pronounSubj = (gender === 'm' ? 'he' : 'she');
		const pronounObj = (gender === 'm' ? 'him' : 'her');
		const pronounPoss = (gender === 'm' ? 'his' : 'her');
		newText = newText.replace(/%pronounSubj%/g, pronounSubj);
		newText = newText.replace(/%PronounSubj%/g, pronounSubj.charAt(0).toUpperCase() + pronounSubj.slice(1));
		newText = newText.replace(/%pronounObj%/g, pronounObj);
		newText = newText.replace(/%PronounObj%/g, pronounObj.charAt(0).toUpperCase() + pronounObj.slice(1));
		newText = newText.replace(/%pronounPoss%/g, pronounPoss);
		newText = newText.replace(/%PronounPoss%/g, pronounPoss.charAt(0).toUpperCase() + pronounPoss.slice(1));
	}	

	return newText;
};

/**
 * Get the effects of a crew member
 * @param {object} crewMemberData 
 * @param {array} selectedCrew 
 * @param {bool} conditionalOnly 
 */
function getCrewMemberEffects(crewMemberData, selectedCrew, conditionalOnly = false) {
	let effects = [];

	/* Default effects */
	if (crewMemberData.back.effects && !conditionalOnly) effects = crewMemberData.back.effects;

	/* Conditional effects */
	if (crewMemberData.back.conditionals && crewMemberData.back.conditionals.length > 0) {
		/* Find first conditional effect that is fulfilled */
		let fulfilledConditionalFound = false;
		crewMemberData.back.conditionals.forEach((conditional) => {
			if (fulfilledConditionalFound) return;

			/* CrewId condition */
			if (
				conditional.conditionType === 'crewId' &&
				selectedCrew.some((eCrew)=> {
					return (eCrew.id === conditional.crewId);
				})
			) {
				fulfilledConditionalFound = true;
				effects = conditional.effects;
			}

			/* Crew position(s) condition */
			if (conditional.conditionType === 'crewPositions') {
				let conditionsAreMet = true;
				conditional.crewPositions.forEach((position) => {
					if (conditionsAreMet === false) return;
					const crewMemberInPosition = selectedCrew.find((c) => {return c.slotId === position.slotId;});
					if (crewMemberInPosition && crewMemberInPosition.id !== position.crewId) conditionsAreMet = false;
				});
				if (conditionsAreMet) {
					fulfilledConditionalFound = true;
					effects = conditional.effects;
				}
			}
			
			/* CrewId AND crew position(s) condition */
			if (conditional.conditionType === 'crewIdAndCrewPositions') {
				if (selectedCrew.some((eCrew)=> {return (eCrew.id === conditional.crewId);})) {
					let conditionsAreMet = true;
					conditional.crewPositions.forEach((position) => {
						if (conditionsAreMet === false) return;
						const crewMemberInPosition = selectedCrew.find((c) => {return c.slotId === position.slotId;});
						if (crewMemberInPosition && crewMemberInPosition.id !== position.crewId) {
							conditionsAreMet = false;
						}
					});
					if (conditionsAreMet) {
						fulfilledConditionalFound = true;
						effects = conditional.effects;
					}
				}
			}
		});
	}

	return effects;
}

/**
 * Get triggered crew member conditional (if any)
 * @param {object} crewMemberData 
 * @param {array} selectedCrew 
 */
function getTriggeredCrewMemberConditional(crewMemberData, selectedCrew) {
	let triggeredConditional = null;


	/* Conditional effects */
	if (crewMemberData.back.conditionals && crewMemberData.back.conditionals.length > 0) {
		/* Find first conditional effect that is fulfilled */
		crewMemberData.back.conditionals.forEach((conditional) => {
			if (triggeredConditional !== null) return;

			/* CrewId condition */
			if (
				conditional.conditionType === 'crewId' &&
				selectedCrew.some((eCrew)=> {
					return (eCrew.id === conditional.crewId);
				})
			) {
				triggeredConditional = conditional;
			}

			/* Crew position(s) condition */
			if (conditional.conditionType === 'crewPositions') {
				let conditionsAreMet = true;
				conditional.crewPositions.forEach((position) => {
					if (conditionsAreMet === false) return;
					const crewMemberInPosition = selectedCrew.find((c) => {return c.slotId === position.slotId;});
					if (crewMemberInPosition && crewMemberInPosition.id !== position.crewId) conditionsAreMet = false;
				});
				if (conditionsAreMet) triggeredConditional = conditional;
			}
			
			/* CrewId AND crew position(s) condition */
			if (conditional.conditionType === 'crewIdAndCrewPositions') {
				if (selectedCrew.some((eCrew)=> {return (eCrew.id === conditional.crewId);})) {
					let conditionsAreMet = true;
					conditional.crewPositions.forEach((position) => {
						if (conditionsAreMet === false) return;
						const crewMemberInPosition = selectedCrew.find((c) => {return c.slotId === position.slotId;});
						if (crewMemberInPosition && crewMemberInPosition.id !== position.crewId) {
							conditionsAreMet = false;
						}
					});
					if (conditionsAreMet) triggeredConditional = conditional;
				}
			}
		});
	}

	return triggeredConditional;
}

/**
 * Apply / remove a crew member's effects
 * @param {string} type 
 * @param {number} crewId 
 * @param {array} selectedCrew 
 * @param {array} dirtyDozenValues 
 * @param {number} riskValue 
 */
function applyOrRemoveCrewMemberEffects(type, crewId, selectedCrew, dirtyDozenValues, riskValue, gameType) {
	if (type !== 'apply' && type !== 'remove') {
		console.error('unknown parameter: ', type);
		return {dirtyDozenValues, riskValue};
	}

	const crewData = gamesData[gameType].crewData;

	const crewMemberData = crewData.availableCrew.find((c) => {return c.id === crewId;});
	let newDirtyDozenValues = [...dirtyDozenValues];
	let newRiskValue = riskValue;
	if (crewMemberData) {
		/* Get direct crew member effects */
		let effects = getCrewMemberEffects(crewMemberData, selectedCrew);
		/* Get indirect crew member effects */
		selectedCrew.forEach((crew) => {
			if (!crew) return;
			const crewMemberData2 = crewData.availableCrew.find((c) => {return c.id === crew.id;});
			const conditionalEffects = 
					getCrewMemberEffects(crewMemberData2, [{id: crewId}], true);
			if (conditionalEffects.length > 0) {effects.push(...conditionalEffects);}
		});

		/* Apply / Remove effects */
		if (effects.length > 0) {
			effects.forEach((effect) => {
				if (effect.type === 'dirtyDozen') {
					let dirtyDozenIndex = newDirtyDozenValues.findIndex((dd) => {
						return dd.id === effect.dirtyDozenId;
					});
					if (type === 'apply') {
						newDirtyDozenValues[dirtyDozenIndex] = newDirtyDozenValues[dirtyDozenIndex] + effect.value;	
					} else {
						newDirtyDozenValues[dirtyDozenIndex] = newDirtyDozenValues[dirtyDozenIndex] - effect.value;
					}
				}
				if (effect.type === 'risk') {
					if (type === 'apply') {
						newRiskValue = riskValue + effect.value;
					} else {
						newRiskValue = riskValue - effect.value;
					}
				}
			});
		}
	}	
	return {newDirtyDozenValues, newRiskValue};
}

/**
 * Find new crew member (best option)
 * @param {number} slotIndex 
 * @param {array} selectedCrew 
 */

function findNewCrewMember(slotIndex, selectedCrew, gameType, notAllowedCrewemberIds) {
	const crewData = gamesData[gameType].crewData;

	const oldCrewMemberId = selectedCrew[slotIndex].id;
	const crewCardTypes = crewData.crewSlots[slotIndex].cardTypes;

	/* Get candidates */
	let crewCandidates = crewData.availableCrew.filter((crew) => {
		return (
			crewCardTypes.indexOf(crew.cardType) >= 0   && 
			// crew.type === crewType && // TODO: handle SCCM?
			crew.id !== oldCrewMemberId && 
			(notAllowedCrewemberIds.indexOf(crew.id) < 0) &&
			!selectedCrew.some((c) => {return (c && c.id === crew.id);})
		);
	});	
	
	/* CRM Helicopters: find random crew member */
	if (gameType === 'crm-helicopters') {
		let newCrewIndex = Math.floor(Math.random() * crewCandidates.length);
		return crewCandidates[newCrewIndex].id;
	}


	/* CRM Aeroplanes: find best crew member */
	let newSelectedCrew = [...selectedCrew];
	newSelectedCrew[slotIndex].id = null;
	let bestCandidate = {id: null, dirtyDozenTotal: 0, risk: 0};

	/* Loop over candidates */
	crewCandidates.forEach((candidate) => {
		let ddTotal = 0;
		let risk = 0;

		/* Get direct effects */
		let effects = getCrewMemberEffects(candidate, newSelectedCrew);

		/* Get indirect crew member effects */
		newSelectedCrew.forEach((crew) => {
			if (!crew || !crew.id) return;
			let crewMemberData2 = crewData.availableCrew.find((c) => {return c.id === crew.id;});
			let conditionalEffects = 
					getCrewMemberEffects(crewMemberData2, [{id: candidate.id}], true);
			if (conditionalEffects.length > 0) {effects.push(...conditionalEffects);}
		});

		/* Apply effects */
		if (effects.length > 0) {
			effects.forEach((effect) => {
				if (effect.type === 'dirtyDozen') {
					ddTotal = ddTotal + effect.value;
				}
				if (effect.type === 'risk') {
					risk = risk + effect.value;
				}
			});
		}

		/* Update best candidate */
		if (
			bestCandidate.id === null || 
			(bestCandidate.dirtyDozenTotal + bestCandidate.risk) > (ddTotal + risk)
		) {
			bestCandidate = {id: candidate.id, dirtyDozenTotal: ddTotal, risk: risk};
		}
	});
	return bestCandidate.id;
};


export {
	replaceTextPlaceholders,
	getCrewMemberEffects,
	getTriggeredCrewMemberConditional,
	applyOrRemoveCrewMemberEffects,
	findNewCrewMember
};