import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import CrewCardFront from './crew-card-front';
import CrewCardBack from './crew-card-back';
import CrewCardBackHeli from './crew-card-back-heli';
import './crew-card-flippable.scss';

const CrewCardFlippable = ({
	isFlipped,
	canFlip,
	languageId,
	gameType,
	cardData,
	cardTitle,
	layout,
	selectedCrew,
	onClick,
	addedClass,
	
}) => {
	let [showBack, setShowBack] = useState((isFlipped ? isFlipped : false));

	/* Auto flip if card is selected in crew feedback */
	useEffect(() => {
		if (layout === 'crewFeedbackSelected') setTimeout(() => {setShowBack(true);}, 500);
	}, [layout]);

	let CrewCardBackComponent = CrewCardBack;
	if (gameType === 'crm-helicopters') CrewCardBackComponent = CrewCardBackHeli;

	return (
		<div
			className={`CrewCardFlippable ${layout} ${addedClass ? addedClass : ''}`}
			onClick={(e) => {
				e.stopPropagation(); 
				if (canFlip) {setShowBack(!showBack);} else {if (onClick) onClick();}
			}}
		>
			<div className={'CrewCardFlippable-container' + (showBack ? ' back' : '')}>
				<div className="CrewCardFlippable-front">
					<CrewCardFront 
						languageId={languageId} 
						gameType={gameType} 
						layout={layout} 
						cardData={cardData} 
						cardTitle={cardTitle} 
					/>
				</div>
				<div className="CrewCardFlippable-back">
					<CrewCardBackComponent 
						languageId={languageId} 
						layout={layout} 
						cardData={cardData} 
						selectedCrew={selectedCrew} 
					/>
				</div>
			</div>
		</div>
	);
};

CrewCardFlippable.propTypes = {
	isFlipped: PropTypes.bool,
	canFlip: PropTypes.bool.isRequired,
	languageId: PropTypes.string.isRequired,
	gameType: PropTypes.string.isRequired,
	cardData: PropTypes.object.isRequired,
	cardTitle: PropTypes.string,
	layout: PropTypes.string,
	selectedCrew: PropTypes.array,
	onClick: PropTypes.func,
	addedClass: PropTypes.string,
};

export default CrewCardFlippable;
