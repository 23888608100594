import React from 'react';
import PropTypes from 'prop-types';
import {dirtyDozenData} from 'data/dirty-dozen-data';
import {getText} from 'helpers/language-helper';
import {renderMarkdown} from 'helpers/text-helper';
import './dirty-dozen-info-popup.scss';

const DirtyDozenInfoPopup = ({dirtyDozenId, languageId, togglePopup}) => {
	let ddData = dirtyDozenData.find((dd) => {return dd.id === dirtyDozenId;});
	if (ddData) {
		return (
			<div className="DirtyDozenInfoPopup-popup" onClick={() => {togglePopup(null);}}>
				<div className="DirtyDozenInfoPopup-content" onClick={(e) => {e.stopPropagation();}}>
					<div className="DirtyDozenInfoPopup-closeBtn" onClick={() => {togglePopup(null);}} />
					<div className="DirtyDozenInfoPopup-title">{getText(ddData.title, languageId)}</div>
					<div className="DirtyDozenInfoPopup-body">
						<div className="DirtyDozenInfoPopup-text">
							{renderMarkdown(getText(ddData.text, languageId))}
						</div>
						<div className="DirtyDozenInfoPopup-subtitle">{getText(ddData.subtitle, languageId)}</div>
						<div className="DirtyDozenInfoPopup-list">
							{ddData.bulletpoints.map((b, index) => {
								return (
									<div key={index} className="DirtyDozenInfoPopup-bullet">
										{getText(b, languageId)}
									</div>
								);
							})}
						</div>
					</div>
				</div>
			</div>
		);
	} 
	return null;
};

DirtyDozenInfoPopup.propTypes = {
	dirtyDozenId: PropTypes.number.isRequired,
	languageId: PropTypes.string.isRequired,
	togglePopup: PropTypes.func.isRequired
};

export default DirtyDozenInfoPopup;