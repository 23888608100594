import React, {Component} from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import {loginUiTexts} from 'data/ui-texts';
import {getText} from 'helpers/language-helper';
import AdminLogin from './admin-login';
import AdminController from './admin-controller';
// TODO: 'en' -> languageId
class AdminLoginController extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isCheckingLogin: true,
			isLoggingIn: false,
			isLoggedIn: false,
			isAdmin: false,
			email: null,
			password: null,
			feedback: null,
			invalidInputEmail: false,
			invalidInputPassword: false,
			page: 'admin',
			authSessionData: {
				userId: null,
				email: null,
			}
		};
		this.unsubscribeOnAuthStateChanged = null;
	};

	/**
	 * Component did mount
	 */
	componentDidMount = () => {
		this.checkIfLoggedIn();
	};

	/**
	 * Component will unmount
	 */
	componentWillUnmount = () => {
		if (this.unsubscribeOnAuthStateChanged !== null) this.unsubscribeOnAuthStateChanged();
	};

	/**
	 * Subscribe to login status
	 */
	checkIfLoggedIn = () => {
		this.setState({isCheckingLogin: true}, () => {

			/* Unsubscribe previous onAuthStateChanged */
			if (this.unsubscribeOnAuthStateChanged !== null) this.unsubscribeOnAuthStateChanged();
		
			/* Subscribe to onAuthStateChanged */
			this.unsubscribeOnAuthStateChanged = firebase.auth().onAuthStateChanged((user)=>{
				if (user && user.email) {
					/* Logged in as facilitator, check if they have admin access */
					const db = firebase.firestore();
					db.collection('users').doc(user.uid).get().then((doc) => {
						let isAdmin = false; 
						if (doc.exists && doc.data().isAdmin === true) isAdmin = true;
						this.setState({
							isCheckingLogin: false,
							isLoggedIn: true,
							isAdmin: isAdmin,
							authSessionData: {
								userId: user.uid,
								email: user.email
							}
						});
					}).catch((error) => {
						console.error(error);
						this.setState({isCheckingLogin: false, isLoggedIn: false, isAdmin: false});
					});
				} else if (user) {
					/* Logged in as player */
					this.setState({isCheckingLogin: false, isLoggedIn: true, isAdmin: false});
				} else {
					/* Not logged in */
					this.setState({isCheckingLogin: false, isLoggedIn: false, isAdmin: false});
				}
			});
		});
	};


	/**
	 * Update input field
	 * @param {obj} event 
	 */
	handleInput = (event) => {
		let value = event.target.value;
		let name = event.target.name;
		this.setState({
			[name]: value,
			feedback: null
		});
	};
	
	/**
	 * Handle login
	 * @param {obj} event 
	 */
	handleLogin = (event) => {
		event.preventDefault();
		this.setState({isLoggingIn: true}, () => {

			/* Error: missing fields */
			let email = this.state.email;
			let password = this.state.password;
			if (!email || !password) {
				this.setState({
					isLoggingIn: false,
					feedback: getText(loginUiTexts.someFieldsMissing, 'en'),
					invalidInputEmail: email ? false : true,
					invalidInputPassword: password ? false : true
				});
				return;
			}

			/* Sign in */
			firebase.auth().signInWithEmailAndPassword(email, password).catch((error) => {
				let errorMsg = getText(loginUiTexts.unknownError, 'en');
				if (error.code === 'auth/invalid-email') errorMsg = getText(loginUiTexts.invalidEmail, 'en');
				if (error.code === 'auth/user-not-found') errorMsg = getText(loginUiTexts.userNotFound, 'en');
				if (error.code === 'auth/wrong-password') errorMsg = getText(loginUiTexts.invalidPassword, 'en');
				this.setState({feedback: errorMsg, isLoggingIn: false});
			});
		});
	};	

	/**
	 * Log out
	 */
	handleLogout = () => {
		firebase.auth().signOut();
		this.setState({
			isLoggedIn: false
		});
	};

	/**
	 * Render component
	 */
	render = () => {
		if (!this.state.isLoggedIn || !this.state.isAdmin) {
			let feedback = this.state.feedback;
			if (this.state.isLoggedIn && !this.state.isAdmin) {
				feedback = getText(loginUiTexts.notAuthorized, 'en');
			}
			return (
				<AdminLogin 
					isCheckingLogin={this.state.isCheckingLogin}
					isLoggingIn={this.state.isLoggingIn}
					invalidInputEmail={this.state.invalidInputEmail}
					invalidInputPassword={this.state.invalidInputPassword}
					email={this.state.email}
					password={this.state.password}
					feedback={feedback}
					handleInput={this.handleInput}
					handleLogin={this.handleLogin}
				/>
			);
		}


		return (
			<AdminController
				authSessionData={this.state.authSessionData}
				handleLogout={this.handleLogout}
			/>
		);
	};
};

export default AdminLoginController;