import React from 'react';
import PropTypes from 'prop-types';
import {generalUiTexts} from 'data/ui-texts';
import {getText} from 'helpers/language-helper';
import './reflection-scale.scss';

const ReflectionScale = ({languageId, questionId, selectedValue, handleSelectOption}) => {
	return (
		<div className="ReflectionScale">
			<div className="ReflectionScale-content">
				<div className="ReflectionScale-header">
					<div className="ReflectionScale-leftText">
						{getText(generalUiTexts.reflection.notAtAll, languageId)}</div>
					<div className="ReflectionScale-rightText">
						{getText(generalUiTexts.reflection.extremely, languageId)}</div>
				</div>
				<div className="ReflectionScale-body">
					{[...Array(5)].map((_, index) =>  {
						let value = index + 1;
						let className = 'ReflectionScale-option';
						if (selectedValue > value) className = className + ' ReflectionScale-option--included';
						if (selectedValue === value) className = className + ' ReflectionScale-option--selected';
						return (
							<div 
								key={index} 
								className={className}
								onClick={() => {handleSelectOption(questionId, value);}}
							>{(value)}</div>
						);
					})}
				</div>
			</div>
		</div>
	);
};

ReflectionScale.propTypes = {
	languageId: PropTypes.string.isRequired,
	questionId: PropTypes.number.isRequired,
	selectedValue: PropTypes.number.isRequired,
	handleSelectOption: PropTypes.func.isRequired
};

export default ReflectionScale;