import React from 'react';
import PropTypes from 'prop-types';
import {gamesData} from 'data/games-data';
import {generalUiTexts} from 'data/ui-texts';
import {getText} from 'helpers/language-helper';
import {getConfirmButtonData} from 'helpers/confirm-button-helper';
import Button from 'components/button/button';
import ProgressBar from 'components/progress-bar/progress-bar';
import InfoBox from 'components/info-box/info-box';
import './game-board-intro.scss';

const GameBoardIntro = ({game, gameStep, handleGoToPage, confirmAndContinue}) => {
	/* Game step data */
	let gameStepData = gamesData[game.type].gameSteps.find((step) => {return step.id === gameStep;});

	/* Info text */
	let title = getText(gameStepData.title, game.languageId);
	let text = getText(gamesData[game.type].scenarios.find((s) => {
		return s.id === game.scenario;
	}).day1Text, game.languageId);
	// let day1Data = gamesData[game.type].scenarios.find((s) => {return s.id === game.scenario;}).daysData[0];
	
	/* Confirm button data */
	let confirmBtnData = getConfirmButtonData(true, game, gameStep, getText(generalUiTexts.start, game.languageId));

	return (
		<div className="GameBoardIntro">
			<ProgressBar 
				gameType={game.type}
				title={getText(gameStepData.title, game.languageId)} 
				subtitle={getText(gameStepData.subtitle, game.languageId)} 
				onClick={() => {handleGoToPage('welcome');}}
			/>
			<Button 
				isDisabled={confirmBtnData.isDisabled}
				text={confirmBtnData.text}
				classes={confirmBtnData.classes}
				onClick={() => {confirmAndContinue(gameStep);}}
			/>
			<InfoBox 
				title={title} 
				text={text}
				buttons={[{
					isDisabled: false,
					text: getText(generalUiTexts.start, game.languageId),
					action: confirmAndContinue,
					params: [gameStep]
				}]}
			/>
		</div>
	);
};

GameBoardIntro.propTypes = {
	game: PropTypes.object.isRequired,
	gameStep: PropTypes.string.isRequired,
	handleGoToPage: PropTypes.func.isRequired,
	confirmAndContinue: PropTypes.func.isRequired,
};

export default GameBoardIntro;