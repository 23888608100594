import React from 'react';
import { useDragLayer } from 'react-dnd';
import { gamesData } from 'data/games-data';
import PropTypes from 'prop-types';
import CrewDndCard from './crew-dnd-card';


const CrewDndCustomDragLayer = ({languageId, gameType, handleFindCard}) => {
	const { isDragging, item, currentOffset} = useDragLayer((monitor) => {
		return {
			isDragging: monitor.isDragging(),
			item: monitor.getItem(),
			currentOffset: monitor.getSourceClientOffset(),

		};
	});

	if (
		isDragging && 
		item && 
		currentOffset && 
		gamesData[gameType].crewData.availableCrew.some((card) => {return card.id === item.id;})
	) {
		let cardData = gamesData[gameType].crewData.availableCrew.find((card) => {return card.id === item.id;});
		let {x, y} = currentOffset;
		let transform = `translate(${x}px, ${y}px)`;

		let cardContainer = handleFindCard(cardData.id);

		return (
			<div className={'CrewSelect-dragLayer' + (cardContainer === 'available' 
				? ' CrewSelect-dragLayer--big' : '')}>
				<CrewDndCard 
					isInFocus={false} 
					isDraggable={false} 
					isDraglayer={true}
					languageId={languageId}
					gameType={gameType}
					cardData={cardData} 
					style={{transform, WebkitTransform: transform}}
				/>
			</div>
		);

	}

	return null;
};


CrewDndCustomDragLayer.propTypes = {
	languageId: PropTypes.string.isRequired,
	gameType: PropTypes.string.isRequired,
	handleFindCard: PropTypes.func.isRequired,
};


export default CrewDndCustomDragLayer;