import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {gamesData} from 'data/games-data';
import CrewSelect from './crew-select';
import InfoPopup from 'components/info-popup/info-popup';

class CrewSelectController extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showInfoPopup: false,
			interactionEnabled: false,
			showCarousel: true,
			panCards: false,
			availableCrew: [],
		};
		this.timeout = null;
	}

	/**
	 * Component did mount
	 */
	componentDidMount = () => {
		let availableCrew = this.getAvailableCrew(this.props.selectedCrew);
		this.setState({ availableCrew }, () => {
			const gameStepData = gamesData[this.props.game.type].gameSteps.find((step) => {
				return step.id === 'crew-select';
			});
			const showInfoPopup = (gameStepData.infoPopup && gameStepData.infoPopup.autoShow === true ? true : false);
			const crewSelectionIsOpen = (this.props.game.hasOwnProperty('crewSelectionIsOpen') 
				? this.props.game.crewSelectionIsOpen
				: false
			);

			if (showInfoPopup === true && (!this.props.isFacilitator || !crewSelectionIsOpen)) {
				this.timeout = setTimeout(() => {
					this.setState({ showInfoPopup: true });
				}, 500);
			} else {
				this.setState({ panCards: true });
			}
		});
	};

	/**
	 * Component will unmount
	 */
	componentWillUnmount = () => {
		if (this.timeout) clearTimeout(this.timeout);
	};

	/**
	 * Component did update
	 * @param {object} prevProps
	 */
	componentDidUpdate(prevProps) {
		/* Check if there are changes in the selected crew, if so: update the available crew */
		let updateAvailableCrew = prevProps.selectedCrew.length !== this.props.selectedCrew.length;
		if (!updateAvailableCrew) {
			for (let i = 0; i < prevProps.selectedCrew.length; i++) {
				if (
					prevProps.selectedCrew[i].id !== this.props.selectedCrew[i].id ||
					prevProps.selectedCrew[i].slotId !== this.props.selectedCrew[i].slotId
				) {
					updateAvailableCrew = true;
				}
			}
		}
		if (updateAvailableCrew) {
			let availableCrew = this.getAvailableCrew(this.props.selectedCrew);
			this.setState({ availableCrew });
		}
	}

	/**
	 * Get available crew
	 * @param {array} selectedCrew
	 */
	getAvailableCrew = (selectedCrew) => {
		let availableCrew = JSON.parse(JSON.stringify(gamesData[this.props.game.type].crewData.availableCrew));
		availableCrew.forEach((crew) => {
			if (
				selectedCrew.some((crewMember) => {
					return crewMember.id === crew.id;
				})
			) {
				crew.slotId = selectedCrew.filter((crewMember) => {
					return crewMember.id === crew.id;
				})[0].slotId;
			} else {
				crew.slotId = null;
			}
		});
		return availableCrew;
	};

	/**
	 * Hide / show flight info
	 */
	toggleInfoPopup = () => {
		let showInfoPopup = !this.state.showInfoPopup;
		if (!this.state.interactionEnabled && !showInfoPopup) {
			/* Player closes popup for the first time after it auto-opened */
			this.setState({ showCarousel: false }, () => {
				this.setState({ showCarousel: true, panCards: true, showInfoPopup, interactionEnabled: true });
			});
		} else {
			this.setState({ showInfoPopup: showInfoPopup });
		}
	};

	/**
	 * Update selected crew
	 * @param {array} selectedCrew 
	 */
	updateSelectedCrew = (selectedCrew) => {
		this.props.updateGroup({selectedCrew: selectedCrew});
	};

	/**
	 * Confirm selected crew
	 */
	confirmSelectedCrew = () => {
		const selectedCrew = this.props.selectedCrew;
		if (gamesData[this.props.game.type].crewData.crewSlots.length === selectedCrew.length) {
			this.props.confirmAndContinue('crew-select', {selectedCrew: selectedCrew});
		}
	};

	/**
	 * Render component
	 */
	render() {
		/* Access */
		let crewSelectionIsOpen = (this.props.game.hasOwnProperty('crewSelectionIsOpen') 
			? this.props.game.crewSelectionIsOpen
			: false
		);

		return (
			<React.Fragment>
				<CrewSelect
					isFacilitator={this.props.isFacilitator}
					showCarousel={this.state.showCarousel}
					panCards={this.state.panCards}
					game={this.props.game}
					availableCrew={this.state.availableCrew}
					handleGoToPage={this.props.handleGoToPage}
					toggleInfoPopup={this.toggleInfoPopup}
					updateSelectedCrew={this.updateSelectedCrew}
					confirmSelectedCrew={this.confirmSelectedCrew}
					
				/>
				{this.state.showInfoPopup && <InfoPopup
					canToggle={crewSelectionIsOpen}
					game={this.props.game}
					gameStep={'crew-select'}
					toggleInfoPopup={this.toggleInfoPopup}
					handleLogout={(crewSelectionIsOpen ?  null : this.props.handleLogout)}
				/>}
			</React.Fragment>
		);
	}
}

CrewSelectController.propTypes = {
	isFacilitator: PropTypes.bool.isRequired,
	game: PropTypes.object.isRequired,
	selectedCrew: PropTypes.array.isRequired,
	handleGoToPage: PropTypes.func.isRequired,
	updateGroup: PropTypes.func.isRequired,
	confirmAndContinue: PropTypes.func.isRequired,
	handleLogout: PropTypes.func,
};

export default CrewSelectController;
