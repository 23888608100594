import React from 'react';
import PropTypes from 'prop-types';
import {settingsUiTexts} from 'data/ui-texts';
import { gamesData } from 'data/games-data';
import {getText} from 'helpers/language-helper';
import {renderMarkdown} from 'helpers/text-helper';
import ReflectionResult from 'components/reflection-result/reflection-result';
import GameResult from 'components/game-result/game-result';
import GameResultST from 'components/game-result/game-result-st';
import GameResultHELI from 'components/game-result/game-result-heli';
import DecisionMakingResult from 'components/decision-making-result/decision-making-result';
import CommunicationResult from 'components/communication-result/communication-result';
import ExerciseResult from 'components/exercise-result/exercise-result';
import './status-popup.scss';

const StatusPopup = (props) => {
	const {
		isFullscreen, 
		languageId,
		game, 
		statusPopupData, 
		companyData, 
		groups, 
		toggleStatusPopup, 
		handleToggleFullscreen
	} = props;

	if (!statusPopupData || statusPopupData.type === 'no-status') {
		return (
			<div 
				className={'StatusPopup' + (statusPopupData ? ' ' + statusPopupData.type : '')} 
				onClick={() => {toggleStatusPopup(false);}}
			>
				<div className="StatusPopup-content" onClick={(e) => {e.stopPropagation();}}>
					<div className="StatusPopup-header">
						<div className="StatusPopup-title">
							{getText(settingsUiTexts.noStatusPopup.title, languageId)}
						</div>
						<div className="StatusPopup-closeBtn" onClick={() => {toggleStatusPopup(false);}}/>
					</div>
					<div className="StatusPopup-body">
						{renderMarkdown(getText(settingsUiTexts.noStatusPopup.text, languageId))}
					</div>
				</div>
			</div>
		);
	}


	/* Default values */
	let title = settingsUiTexts.status;
	let gameStep = null;
	let reflectionData = null;
	let StatusComponent = ReflectionResult;

	/* Setup for reflection status */
	if (statusPopupData.type === 'reflection') {
		reflectionData = gamesData[game.type].reflectionsData.find((reflection) => {
			return reflection.id === statusPopupData.reflectionId;
		});
		title = getText(reflectionData.title, languageId);
		if (reflectionData.subtitle) {
			title = title + ' / ' + getText(reflectionData.subtitle, languageId);
		} 
		gameStep = reflectionData.resultGameStep;
		StatusComponent = ReflectionResult;
	}

	/* Setup for scoreboard */
	if (statusPopupData.type === 'scoreboard') {
		StatusComponent = GameResult;
		if (game.type === 'safetytraining') StatusComponent = GameResultST;
		if (game.type === 'crm-helicopters') StatusComponent = GameResultHELI;
		gameStep = statusPopupData.gameStepId;
		let gameStepData = gamesData[game.type].gameSteps.find((gs) => {return gs.id === gameStep;});
		if (gameStepData) {title = getText(gameStepData.title, game.languageId);}
	}

	if (statusPopupData.type === 'decision-making') {
		StatusComponent = DecisionMakingResult;
		gameStep = 'decision-making-result';
		let gameStepData = gamesData[game.type].gameSteps.find((gs) => {return gs.id === gameStep;});
		if (gameStepData) {title = getText(gameStepData.title, game.languageId);}
	}

	if (statusPopupData.type === 'communication') {
		StatusComponent = CommunicationResult;
		gameStep = 'communication-result';
		let gameStepData = gamesData[game.type].gameSteps.find((gs) => {return gs.id === gameStep;});
		if (gameStepData) {title = getText(gameStepData.title, game.languageId);}
	}

	if (statusPopupData.type === 'exercise') {
		StatusComponent = ExerciseResult;
		gameStep = 'exercise-result';
		let gameStepData = gamesData[game.type].gameSteps.find((gs) => {return gs.id === gameStep;});
		if (gameStepData) {title = getText(gameStepData.title, game.languageId);}
	}


	return (
		<div 
			className={'StatusPopup' + (isFullscreen ? ' fullscreen' : '') + 
				(statusPopupData ? ' ' + statusPopupData.type : '')} 
			onClick={() => {toggleStatusPopup(false);}}
		>
			<div className="StatusPopup-content" onClick={(e) => {e.stopPropagation();}}>
				<div className="StatusPopup-header">
					<div 
						className={'StatusPopup-fullscreenBtn' + (isFullscreen ? ' fullscreen' : '')} 
						onClick={() => {handleToggleFullscreen();}}
					/>
					<div className="StatusPopup-title">{title}</div>
					<div className="StatusPopup-closeBtn" onClick={() => {toggleStatusPopup(false);}}/>
				</div>
				<div className="StatusPopup-body">
					<StatusComponent 
						game={game}
						companyData={companyData}
						gameStep={gameStep}
						groups={groups}
						handleGoToPage={() => {return null;}}
						confirmAndContinue={() => {return null;}}
						goToPrevGameStep={() => {return null;}}
						toggleInfoPopup={() => {return null;}}
					/>
				</div>
			</div>
		</div>
	);
};

StatusPopup.propTypes = {
	isFullscreen: PropTypes.bool.isRequired,
	languageId: PropTypes.string.isRequired,
	game: PropTypes.object.isRequired,
	statusPopupData: PropTypes.object.isRequired,
	companyData: PropTypes.object.isRequired,
	groups: PropTypes.array.isRequired,
	toggleStatusPopup: PropTypes.func.isRequired,
	handleToggleFullscreen: PropTypes.func.isRequired,
};

export default StatusPopup;
