import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Exercise from './exercise';
// import InfoPopup from 'components/info-popup/info-popup';
import { exerciseToolsData } from 'data/tools-data';

class ExerciseController extends Component {
	constructor(props) {
		super(props);
		this.state = {
			// showInfoPopup: false
		};
	}


	/**
	 * Type answer to questions
	 * @param {object} event 
	 */
	handleInputText = (event) => {
		if (this.props.isFacilitator) return;
		
		let answerId = event.target.name;
		let answerValue = event.target.value; 
		let exerciseAnswers = [];
		if (this.props.group.exerciseAnswers) {
			exerciseAnswers = JSON.parse(JSON.stringify(this.props.group.exerciseAnswers));
		}
		let answerIndex = exerciseAnswers.findIndex((answer) => {return (answer.id === answerId);});

		/* Update answer */
		if (answerIndex >= 0) {
			/* Question already answered, update answer */
			exerciseAnswers[answerIndex].value = answerValue;
		} else {
			/* First time question is answered */
			exerciseAnswers.push({id: answerId, value: answerValue});
		}

		/* Update database */
		this.props.updateGroup({exerciseAnswers: exerciseAnswers});
	};

	/**
	 * Hide / show info popup
	 */
	// toggleInfoPopup = (show = null) => {
	// 	let showInfoPopup = !this.state.showInfoPopup;
	// 	if (show !== null) showInfoPopup = show;
	// 	this.setState({showInfoPopup});
	// };

	/**
	 * Render component 
	 */
	render() {
		return (
			<>
				<Exercise
					gameIsPaused={this.props.game.isPaused}
					group={this.props.group}
					game={this.props.game}
					exerciseTool={this.props.game.exerciseTool ? this.props.game.exerciseTool : exerciseToolsData[0].id}
					handleGoToPage={this.props.handleGoToPage}
					handleInputText={this.handleInputText}
					confirmAndContinue={this.props.confirmAndContinue}
					// toggleInfoPopup={this.toggleInfoPopup}
				/>
				{/* {this.state.showInfoPopup && <InfoPopup
					canToggle={true}
					game={this.props.game}
					gameStep={this.props.group.gameStep}
					toggleInfoPopup={this.toggleInfoPopup}
					handleLogout={null}
				/>}	 */}
			</>
		);
	}
}

ExerciseController.propTypes = {
	isFacilitator: PropTypes.bool.isRequired,
	game: PropTypes.object.isRequired,
	group: PropTypes.object.isRequired,
	handleGoToPage: PropTypes.func.isRequired,
	updateGroup: PropTypes.func.isRequired,
	confirmAndContinue: PropTypes.func.isRequired,
};

export default ExerciseController;
