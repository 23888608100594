import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import appConfig from 'config/app.config';
import { gamesData} from 'data/games-data';
import {dirtyDozenData} from 'data/dirty-dozen-data';
import { generalUiTexts } from 'data/ui-texts';
import {getText} from 'helpers/language-helper';
import { renderMarkdown } from 'helpers/text-helper';
import {getConfirmButtonData} from 'helpers/confirm-button-helper';
import Logo from 'components/logo/logo';
import Button from 'components/button/button';
import ProgressBar from 'components/progress-bar/progress-bar';
import CrewCardFlippable from 'components/crew-card/crew-card-flippable';
import GameBoardStat from 'components/game-board-stat/game-board-stat';
import DirtyDozen from 'components/dirty-dozen/dirty-dozen';
import './crew-feedback.scss';

const CrewFeedback = (props) => {
	let {
		riskIsAffected,
		game,
		group,
		affectedDirtyDozens, 
		handleGoToPage,
		handleSelectSlotCRM, 
		confirmAndContinue, 
		
	} = props;

	let [selectedCard, setSelectedCard] = useState(null);
	let [selectedSlotId, setSelectedSlotId] = useState(null);

	const crewData = gamesData[game.type].crewData;

	/* Game step data */
	const gameStepData = gamesData[game.type].gameSteps.find((step) => {return step.id === 'crew-feedback';});


	/* If new card is selected, briefly show no card to reset flip component */
	useEffect(() => {
		let newSelectedCard = null;
		let newSelectedSlotId = null; 
		if (group.activeFeedbackCrewId) {
			if (crewData.availableCrew.some((person) => {return person.id === group.activeFeedbackCrewId;})
			) {
				newSelectedCard = crewData.availableCrew.find((person) => {
					return person.id === group.activeFeedbackCrewId;
				});
				newSelectedCard.effectApplied = group.selectedCrew.filter((crew) => {
					return crew.id === newSelectedCard.id;
				})[0].effectApplied;
			}
			if (group.selectedCrew.some((person) => {return person.id === group.activeFeedbackCrewId;})) {
				newSelectedSlotId = group.selectedCrew.find((person) => {
					return person.id === group.activeFeedbackCrewId;
				}).slotId;
			}
		}
		if (
			newSelectedCard !== null && selectedCard !== null && newSelectedCard !== selectedCard &&
			newSelectedSlotId !== null && selectedSlotId !== null && newSelectedSlotId !== selectedSlotId
		) {
			setSelectedCard(null);
			setSelectedSlotId(null);
		} else {
			setSelectedCard(newSelectedCard);
			setSelectedSlotId(newSelectedSlotId);
		}
	}, [selectedCard, selectedSlotId, crewData.availableCrew, group.activeFeedbackCrewId, group.selectedCrew]);

	/* Find first crewmember for which effects have not been applied */
	let nextCrewmember = group.selectedCrew.find((c) => {
		return (!c.hasOwnProperty('effectApplied') || c.effectApplied === false);
	});

	/* Confirmation button data */
	let allCardsFlipped = (group.selectedCrew.filter((crew) => {
		return (crew.effectApplied && crew.effectApplied === true);
	}).length === group.selectedCrew.length);
	let confirmBtnData = getConfirmButtonData(
		allCardsFlipped, 
		game, 
		'crew-feedback', 
		getText(generalUiTexts.next, game.languageId)
	);
	if (!confirmBtnData.isDisabled) confirmBtnData.classes.push('animate');
	
	return (
		<div className="CrewFeedback" onClick={() => {handleSelectSlotCRM(null);}}>
			<Logo onClick={() => {handleGoToPage('welcome');}} />
			<ProgressBar 
				gameType={game.type}
				title={getText(gameStepData.title, game.languageId)} 
				subtitle={getText(gameStepData.subtitle, game.languageId)} 
			/>
			<Button 
				isDisabled={confirmBtnData.isDisabled}
				text={confirmBtnData.text}
				classes={confirmBtnData.classes}
				onClick={() => {confirmAndContinue('crew-feedback');}}
			/>
			<div className="CrewFeedback-planeBackground" />
			<div className="CrewFeedback-selectedWrapper">
				<div className="CrewFeedback-selectedCockpitCrew">
					{crewData.crewSlots.filter((slot) => {return slot.type === 'cockpit';}).map((slot) => {
						let selectedCrewMember = group.selectedCrew.find((crew) => {return crew.slotId === slot.id;});
						let crewId = selectedCrewMember.id;
						let cardData = crewData.availableCrew.find((person) => {return person.id === crewId;});
						
						return (
							<div 
								key={slot.id} 
								className={'CrewFeedback-slot' + (nextCrewmember && 
									nextCrewmember.id === crewId && slot.id !== selectedSlotId
									? ' shake' : '')}
							>
								<span className="CrewFeedback-slotTitle">{getText(slot.title, game.languageId)}</span>
								{slot.id !== selectedSlotId && 
									<CrewCardFlippable 
										canFlip={false}
										languageId={game.languageId}
										gameType={game.type}
										layout={(selectedCrewMember.effectApplied === true 
											? 'crewFeedbackWhite' : 'crewFeedback'
										)}
										cardData={cardData}
										cardTitle={getText(slot.title, game.languageId)}
										selectedCrew={group.selectedCrew}
										onClick={() => {handleSelectSlotCRM(slot.id);}}
									/>}
							</div>
						);
					})}
				</div>
				<div className="CrewFeedback-selectedCabinCrew">
					{
						crewData.crewSlots.filter((slot) => {return (slot.id === 3 || slot.id === 4);}).map((slot) => {
							let selectedCrewMember = group.selectedCrew.find((crew) => {
								return crew.slotId === slot.id;
							});
							let crewId = selectedCrewMember.id;
							let cardData = crewData.availableCrew.find((person) => {return person.id === crewId;});
							return (
								<div 
									key={slot.id} 
									className={'CrewFeedback-slot' + (nextCrewmember && 
										nextCrewmember.id === crewId && slot.id !== selectedSlotId
										? ' shake' : '')}
								>
									<div className="CrewFeedback-slotTitle">
										<span>{getText(slot.title, game.languageId)}</span>
									</div>
									{slot.id !== selectedSlotId && 
										<CrewCardFlippable 
											canFlip={false}
											languageId={game.languageId}
											gameType={game.type}
											layout={(selectedCrewMember.effectApplied === true 
												? 'crewFeedbackWhite' : 'crewFeedback'
											)}
											cardData={cardData}
											cardTitle={getText(slot.title, game.languageId)}
											effectApplied={(selectedCrewMember.effectApplied === true)}
											selectedCrew={group.selectedCrew}
											onClick={() => {handleSelectSlotCRM(slot.id);}}
										/>}
								</div>
							);
						})
					}
				</div>
				<div className="CrewFeedback-selectedCabinCrew">
					{
						crewData.crewSlots.filter((slot) => {return (slot.id === 5 || slot.id === 6);}).map((slot) => {
							let selectedCrewMember = group.selectedCrew.find((crew) => {
								return crew.slotId === slot.id;
							});
							let crewId = selectedCrewMember.id;
							let cardData = crewData.availableCrew.find((person) => {return person.id === crewId;});
							return (
								<div 
									key={slot.id} 
									className={'CrewFeedback-slot' + (nextCrewmember && 
										nextCrewmember.id === crewId && slot.id !== selectedSlotId
										? ' shake' : '')}
								>
									<div className="CrewFeedback-slotTitle">
										<span>{getText(slot.title, game.languageId)}</span>
									</div>
									{slot.id !== selectedSlotId && 
										<CrewCardFlippable 
											canFlip={false}
											isFlipped={false}
											languageId={game.languageId}
											gameType={game.type}
											layout={(selectedCrewMember.effectApplied === true 
												? 'crewFeedbackWhite' : 'crewFeedback'
											)}
											cardData={cardData}
											cardTitle={getText(slot.title, game.languageId)}
											effectApplied={(selectedCrewMember.effectApplied === true)}
											selectedCrew={group.selectedCrew}
											onClick={() => {handleSelectSlotCRM(slot.id);}}
										/>}
								</div>
							);
						})
					}
				</div>
			</div>

			<div className="CrewFeedback-selectedSlot">
				<GameBoardStat 
					animate={riskIsAffected}
					statDiff={null}
					type="risk-feedback"
					title={getText(generalUiTexts.risk, game.languageId)}
					value={group.riskValue}
					maxValue={appConfig.riskMax}
				/>
				<div className={'CrewFeedback-instructions' + (selectedCard ? ' hide' : '')}>
					<div className="CrewFeedback-instructionsTitle">
						{getText(generalUiTexts.crewFeedbackInstructionsTitle, game.languageId)}
					</div>
					<div className="CrewFeedback-instructionsText">
						{renderMarkdown(getText(generalUiTexts.crewFeedbackInstructionsText, game.languageId))}
					</div>
				</div>
				{selectedCard && 
					<div className="CrewFeedback-selectedCrewContainer">
						<CrewCardFlippable 
							canFlip={true}
							languageId={game.languageId}
							gameType={game.type}
							layout="crewFeedbackSelected"
							cardData={selectedCard}
							selectedCrew={group.selectedCrew}
						/>
					</div>}
			</div>

			<div className="CrewFeedback-dirtyDozens">
				{dirtyDozenData.map((stat, index) => {
					let isHighlighted = (affectedDirtyDozens.indexOf(stat.id) >= 0);
					let isColored = (group.dirtyDozenValues[index] > 0);
					return (
						<DirtyDozen
							key={index}
							title={getText(stat.title, game.languageId)}
							value={group.dirtyDozenValues[index]}
							isColored={isColored}
							isHighlighted={isHighlighted}
						/>
					);
				})}
			</div>
		</div>
	);
};

CrewFeedback.propTypes = {
	riskIsAffected: PropTypes.bool.isRequired,
	game: PropTypes.object.isRequired,
	group: PropTypes.object.isRequired,
	affectedDirtyDozens: PropTypes.array.isRequired,
	handleGoToPage: PropTypes.func.isRequired,
	handleSelectSlotCRM: PropTypes.func.isRequired,
	confirmAndContinue: PropTypes.func.isRequired,
};

export default CrewFeedback;