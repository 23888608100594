import React from 'react';
import PropTypes from 'prop-types';
import { gamesData} from 'data/games-data';
import { generalUiTexts } from 'data/ui-texts';
import {getText} from 'helpers/language-helper';
import './crew-status.scss';

const CrewStatus = ({ languageId, gameType, setShowCrewPopup, selectedCrew }) => {
	const crewData = gamesData[gameType].crewData;

	return (
		<div className={'CrewStatus ' + gameType} onClick={() => {setShowCrewPopup(true);}}>
			<div className="CrewStatus-title">{getText(generalUiTexts.crewPopupTitle, languageId)}</div>
			<div className="CrewStatus-content">
				{crewData.crewSlots.map((slot, i) => {
					if (selectedCrew.length > i) {
						let crewMemberData = crewData.availableCrew.find((c) => {return c.id === selectedCrew[i].id;});
						return (
							<div
								key={slot.id}
								className={'CrewStatus-icon ' + slot.type 
									+ (crewMemberData && crewMemberData.gender ? ' ' + crewMemberData.gender : '') 
									+ (selectedCrew[i].isRemoved ? ' CrewStatus-icon--removed' : '')
								}
							/>
						);
					}
					return null;
				})}
			</div>
		</div>
	);
};

CrewStatus.propTypes = {
	languageId: PropTypes.string.isRequired,
	gameType: PropTypes.string.isRequired,
	selectedCrew: PropTypes.array.isRequired,
	setShowCrewPopup: PropTypes.func.isRequired,
};

export default CrewStatus;
