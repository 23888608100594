import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {adminUiTexts} from 'data/ui-texts';
import {sortArrayByProperty} from 'helpers/array-helper';
import FacilitatorGames from 'components/admin/facilitator-games/facilitator-games';
import DisableFacilitatorPopup from 'components/admin/disable-facilitator-popup/disable-facilitator-popup';
import DeleteFacilitatorPopup from 'components/admin/delete-facilitator-popup/delete-facilitator-popup';
import './facilitator.scss';

const Facilitator = (props) => {
	let {
		isEditing, 
		isSaving, 
		isDeletingFacilitator,
		isTogglingFacilitatorStatus,
		authSessionData,
		errMsgFacilitator,
		companyData,
		games, 
		groups, 
		selectedFacilitator, 
		updateFacilitator, 
		saveFacilitator, 
		toggleFacilitatorStatus,
		deleteFacilitator,
	} = props;

	let [showDeleteFacilitatorPopup, setShowDeleteFacilitatorPopup] = useState(false);
	let [showDisableFacilitatorPopup, setShowDisableFacilitatorPopup] = useState(false);

	let saveBtnClass = 'Facilitator-saveBtn' + (isEditing ? ' active' : '') + (isEditing && isSaving ? ' saving' : '');
	let deleteBtnClass = 'Facilitator-deleteBtn active' + (isDeletingFacilitator ? ' deleting' : '');
	let statusBtnClass = 'Facilitator-statusBtn active' + (selectedFacilitator.isEnabled === true ? ' enabled' : '');

	let facilitatorGames = games.filter((g) => {
		return (
			g.facilitatorId === selectedFacilitator.id &&
			g.companyId === companyData.id
		);
	});
	facilitatorGames = sortArrayByProperty(facilitatorGames, 'playDate', 'DESC');

	return (
		<div className="Facilitator">
			<div className={saveBtnClass} onClick={() => {saveFacilitator();}}>{adminUiTexts.saveChanges}</div>
			{!(selectedFacilitator.isAdmin === true) && <div 
				className={statusBtnClass}
				onClick={() => {
					if (selectedFacilitator.isEnabled) {
						setShowDisableFacilitatorPopup(true);
					} else {
						toggleFacilitatorStatus(true);
					}
				}}
			>
				{(selectedFacilitator.isEnabled === true ? adminUiTexts.disableAccount : adminUiTexts.activateAccount)}
			</div>}
			{!(selectedFacilitator.isAdmin === true) && 
			<div className={deleteBtnClass} onClick={() => {setShowDeleteFacilitatorPopup(true);}}>
				{adminUiTexts.deleteAccount}
			</div>}
			
			<div className="Facilitator-facilitatorInfoContainer">
				<div className="Facilitator-facilitatorInfo">
					<div className="Facilitator-title">{adminUiTexts.name}:</div>
					<div className="Facilitator-facilitatorName">
						<input 							
							type="text"
							name="name"
							placeholder={adminUiTexts.name.toLowerCase()}
							value={selectedFacilitator.name}
							onChange={(event) => {updateFacilitator(event);}}
						/>
					</div>
				</div>
				<div className="Facilitator-facilitatorInfo">
					<div className="Facilitator-title">{adminUiTexts.email}:</div>
					<div className="Facilitator-facilitatorEmail">{selectedFacilitator.email}</div>

				</div>
				<div className="Facilitator-facilitatorInfo">
					<div className="Facilitator-title">{adminUiTexts.status}:</div>
					<div className={'Facilitator-status' + (selectedFacilitator.isEnabled ? ' enabled' : '')}>
						{selectedFacilitator.isEnabled ? adminUiTexts.enabled : adminUiTexts.disabled}
						{(selectedFacilitator.invitationSent !== true 
							? <span> ({adminUiTexts.notInvited.toLowerCase()})</span> : '')}
					</div>
				</div>
			</div>

			<div className="Facilitator-title">{adminUiTexts.games}:</div>
			<FacilitatorGames 
				games={facilitatorGames}
				groups={groups}
				players={companyData.players}
			/>

			{showDeleteFacilitatorPopup && 
				<DeleteFacilitatorPopup 
					isDeletingFacilitator={isDeletingFacilitator}
					authSessionData={authSessionData}
					errMsgFacilitator={errMsgFacilitator}
					selectedFacilitator={selectedFacilitator}
					setShowDeleteFacilitatorPopup={setShowDeleteFacilitatorPopup}
					deleteFacilitator={deleteFacilitator}
				/>
			}

			{showDisableFacilitatorPopup && 
				<DisableFacilitatorPopup 
					isTogglingFacilitatorStatus={isTogglingFacilitatorStatus}
					authSessionData={authSessionData}
					errMsgFacilitator={errMsgFacilitator}
					selectedFacilitator={selectedFacilitator}
					setShowDisableFacilitatorPopup={setShowDisableFacilitatorPopup}
					toggleFacilitatorStatus={toggleFacilitatorStatus}
				/>
			}
		</div>
	);
};

Facilitator.propTypes = {
	isEditing: PropTypes.bool.isRequired,
	isSaving: PropTypes.bool.isRequired,
	isDeletingFacilitator: PropTypes.bool.isRequired,
	isTogglingFacilitatorStatus: PropTypes.bool.isRequired,
	authSessionData: PropTypes.object.isRequired,
	errMsgFacilitator: PropTypes.string,
	companyData: PropTypes.object.isRequired,
	games: PropTypes.array.isRequired,
	groups: PropTypes.array.isRequired,
	selectedFacilitator: PropTypes.object.isRequired,
	updateFacilitator: PropTypes.func.isRequired,
	saveFacilitator: PropTypes.func.isRequired,
	toggleFacilitatorStatus: PropTypes.func.isRequired,
	deleteFacilitator: PropTypes.func.isRequired
};

export default Facilitator;