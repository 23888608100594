import React, {useState} from 'react';
import PropTypes from 'prop-types';
import './select.scss';
// TODO: handle option title
const Select = ({isDisabled, type, options, defaultId, selectedId, onSelect}) => {
	let [dropdownIsOpen, setDropDown] = useState(false);

	const toggleDropdown = () => {
		if (isDisabled) return;
		setDropDown(!dropdownIsOpen);
	};

	const handleSelect = (optionId) =>  {
		if (isDisabled) return;
		onSelect(optionId);
		toggleDropdown();
	};

	let selectedOption = options.find((option) => {return option.id === selectedId;});
	let selectedOptionTitle = selectedOption 
		? selectedOption.title.hasOwnProperty('en') ? selectedOption.title.en : selectedOption.title
		: null;
	let defaultOption = options.find((option) => {return option.id === defaultId;});
	let defaultOptionTitle = 
		defaultOption.title.hasOwnProperty('en') ? defaultOption.title.en : null;

	return (
		<div 
			className={'Select' + (type ? ' ' + type : '') + 
				(dropdownIsOpen ? ' open' : '') + 
				(isDisabled ? ' disabled' : '')
			}>
			<div className={'Select-panel Select-panel--' + selectedId} onClick={()=>{toggleDropdown();}}>
				<div className="Select-selected">
					<span>{(selectedOption ? selectedOptionTitle : defaultOptionTitle)}</span>
				</div>
				<div className="Select-button" />
			</div>
			<div className="Select-dropdown">
				{options.map((option, index) => {
					let optionTitle = option.title.hasOwnProperty('en') ? option.title.en : option.title;
					let isDisabled = (option.hasOwnProperty('isDisabled') && option.isDisabled === true);
					let className = 'Select-option';
					if (selectedId === option.id) className += ' Select-option--selected';
					if (isDisabled) className += ' Select-option--disabled';
					return (
						<div 
							key={index} 
							className={className} 
							onClick={()=>{if (!isDisabled) handleSelect(option.id);}}
						><span>{optionTitle}</span></div>
					);
				})}
			</div>
		</div>
	);
};

Select.defaultProps = {
	isDisabled: false
};


Select.propTypes = {
	isDisabled: PropTypes.bool,
	type: PropTypes.string,
	options: PropTypes.array.isRequired,
	defaultId: PropTypes.string.isRequired,
	selectedId: PropTypes.any,
	onSelect: PropTypes.func.isRequired,
};

export default Select;
