import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {useSprings, animated} from 'react-spring';
import CrewDndCard from './crew-dnd-card';
import './crew-carousel.scss';

const CrewCarousel = ({isFacilitator, panCards, languageId, gameType, crewCards}) => {
	/* Configure layout */
	const centerCardWidth = 19;
	const cardWidth = 12;
	const cardDisplayLimit = 6;
	const cardsOnLeftSide = Math.floor(((crewCards.length - 1) / 2.));
	const cardsOnRightSide = Math.ceil(((crewCards.length - 1) / 2.));
	
	/* References to current card */
	let initialIndex = 0;
	if (!panCards && crewCards.length > 0) initialIndex = crewCards.length - 3;
	const index = React.useRef(initialIndex);
	let [focusIndex, setFocusIndex] = useState(initialIndex);

	/* Auto-pan to next no-selected card */
	useEffect(() => {
		if (crewCards[index.current] && crewCards[index.current].slotId !== null) {
			goToNextOrPrevCard('next');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [crewCards]);

	/* Position cards */
	const position = (iPos) => {
		let x = 0;
		let display = 'none';
		let i = iPos;
		if (i >= crewCards.length) i = i - crewCards.length;

		/* Center card */
		if (i === index.current) {
			x = 0;
			display = 'flex';
		}

		/* Left cards */
		for (let j = 1; j <= cardsOnLeftSide; j++) { 
			if (index.current - j < 0) {
				if (i === crewCards.length + (index.current - j)) {
					x = - j * cardWidth;
					display = (j >= cardDisplayLimit ? 'none' : 'flex');
				}
			} else {
				if (i === index.current - j) {
					x = - j * cardWidth;
					display = (j >= cardDisplayLimit ? 'none' : 'flex');
				}
			}
		}
		/* Right cards */
		for (let j = 1; j <= cardsOnRightSide; j++) { 
			if (index.current + j > crewCards.length - 1) {
				if (i === (index.current + j) - crewCards.length) {
					x = (j - 1) * cardWidth + centerCardWidth;
					display = (j >= cardDisplayLimit ? 'none' : 'flex');
				}
			} else {
				if (i === index.current + j) {
					x = (j - 1) * cardWidth + centerCardWidth;
					display = (j >= cardDisplayLimit ? 'none' : 'flex');
				}
			}
		}

		return { x: x, display: display};
	};



	/* Create springs */
	let from = (i) => {return {...position(i)};};
	const to = from;
	if (panCards) {
		from = (i) => {return {...position(i + 3)};};
	}
	const [springProps, set] = useSprings(crewCards.length, (i) => {return {...to(i), from: from(i) };});

	/* Navigate with prev/next buttons */
	const goToNextOrPrevCard = (direction) => {
		const dir = (direction === 'prev' ? 1 : -1);
		let currentIndex = index.current - dir;
		if (currentIndex < 0) currentIndex = crewCards.length - 1;
		if (currentIndex > (crewCards.length - 1)) currentIndex = 0;
		setFocusIndex(currentIndex);
		index.current = currentIndex;		
		set.start((i) => {
			let positionObj = position(i);
			const x = positionObj.x;
			return { x: x, display: positionObj.display};
		});
	};

	/* Navigate by clicking card */
	const goToCard = (cardIndex) => {
		setFocusIndex(cardIndex);
		index.current = cardIndex;		
		set.start((i) => {
			let positionObj = position(i);
			const x = positionObj.x;
			return { x: x, display: positionObj.display};
		});
	};

	return (
		<div className={'CrewCarousel' + (gameType === 'safetytraining' ? ' st' : '')}>
			{springProps.map(({ x, display}, i) => {
				return (
					<animated.div
						key={i}
						className={'CrewCarousel-cardWrap' + (focusIndex === i ? ' CrewCarousel-cardWrap--focus' : '')}
						style={{
							display,
							transform: x.to((x) => {return `translate3d(${x}em,0,0)`;})
						}}>
						<animated.div 
							className={'CrewCarousel-card' + (focusIndex === i ? ' CrewCarousel-card--focus' : '')} 
						>
							<CrewDndCard 
								isInFocus = {(focusIndex === i ? true : false)}
								isDraggable = {(!isFacilitator && focusIndex === i && 
									crewCards[i].slotId === null ? true : false)}
								isSelected = {(crewCards[i].slotId === null ? false : true)}
								languageId={languageId}
								gameType={gameType}
								cardData={crewCards[i]}
								cardTitle={[i].slot}
								onClick={() => {if (focusIndex !== i) goToCard(i);}}
							/>
						</animated.div>
					</animated.div>
				);
			})}
			<div onClick={() => {goToNextOrPrevCard('prev');}} className="CrewCarousel-prevBtn" />
			<div onClick={() => {goToNextOrPrevCard('next');}} className="CrewCarousel-nextBtn" />


		</div>
	);
};

CrewCarousel.propTypes = {
	isFacilitator: PropTypes.bool.isRequired,
	panCards: PropTypes.bool.isRequired,
	languageId: PropTypes.string.isRequired,
	gameType: PropTypes.string.isRequired,
	crewCards: PropTypes.array.isRequired,
};

export default CrewCarousel;