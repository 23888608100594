import React from 'react';
import PropTypes from 'prop-types';
import {renderMarkdown} from 'helpers/text-helper';
import Button from 'components/button/button';
import './info-box.scss';

const InfoBox = ({isFullscreen, type, title, text, buttons, handleClose, handleLogout}) => {
	return (
		<div 
			className={'InfoBox' + (isFullscreen ? ' fullscreen' : '') + (type ? ' ' + type : '')}
			onClick={() => {if (handleClose) handleClose();}}
		>
			<div className="InfoBox-box" onClick={(e) => {e.stopPropagation();}}>
				{/* Header */}
				<div className="InfoBox-header">
					<div className="InfoBox-title">{title}</div>
					{handleClose && <div className="InfoBox-closeBtn" onClick={handleClose} />}
				</div>
				{/* Body */}
				<div className="InfoBox-body">
					<div className="InfoBox-text">{renderMarkdown(text)}</div>
					{(buttons && buttons.length) > 0 && 
						<div className="InfoBox-buttons">
							{handleLogout && <Button text="" classes={['logout', 'popup']} onClick={handleLogout} />}
							{buttons.map((button, index) => {
								let params = button.params ? button.params : [];
								let classes = ['infoBox'];
								if (button.classes && button.classes.length > 0) {
									classes = classes.concat(button.classes);
								}
								return (
									<Button
										key={index}
										isLoading={button.isLoading === true}
										isDisabled={button.isDisabled}
										text={button.text}
										classes={classes}
										onClick={(e) => {e.stopPropagation(); button.action(...params);}}
									/>
								);
							})}
						</div>
					}
				</div>

			</div>
		</div>
	);
};

InfoBox.defaultProps = {
	isFullscreen: false,
	type: null,
	buttons: [],
	handleClose: null,
	handleLogout: null
};

InfoBox.propTypes = {
	isFullscreen: PropTypes.bool,
	type: PropTypes.string,
	title: PropTypes.string.isRequired,
	text: PropTypes.string.isRequired,
	buttons: PropTypes.array,
	handleClose: PropTypes.func,
	handleLogout: PropTypes.func
};

export default InfoBox;
